import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public get session() { return sessionStorage; }
  public get local() { return localStorage; }

  constructor() { }
}
