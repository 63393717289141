import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'elly-dialog-prompt',
  template: require('./dialog-prompt.component.html'),
  styles: [require('./dialog-prompt.component.a.css')]
})
export class DialogPromptComponent implements OnInit {
  private form: FormGroup;
  private input: FormControl;

  constructor(private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    const { validators, value } = this.data.input;

    this.form = this.fb.group({
      input: [value ? value : '', [...validators]],
    });

    this.input = this.form.controls.input as FormControl;
  }
}
