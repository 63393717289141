import { Component, OnInit, Inject, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatAutocompleteTrigger } from '@angular/material';

import { Subscription } from 'rxjs';
import { first, startWith, map, filter } from 'rxjs/operators';

import { UserService } from 'src/app/core/auth/user.service';

import { autocompleteStrictValidator } from 'src/app/shared/autocomplete-strict.directive';

@Component({
  selector: 'elly-dialog-edit-banner',
  template: require('./dialog-edit-banner.component.html'),
  styles: [require('./dialog-edit-banner.component.a.css')]
})
export class DialogEditBannerComponent implements OnInit, AfterViewInit, OnDestroy {
  private form: FormGroup;
  private name: FormControl;
  private hash: FormControl;
  private previewUrl: FormControl;
  private ownerId: FormControl;

  private bannerType = 'CREATOPY';
  private fullPreviewUrl!: string;

  private previewPrefix = 'https://cdn.bannersnack.com/banners/';
  private previewSuffix = '/images/jpg';

  private storyOwners = [];
  private filteredStoryOwners = [];

  private autoPanelClosed: Subscription;
  private ownerChanged: Subscription;

  @ViewChild('autoTrigger', {
    read: MatAutocompleteTrigger,
    static: true
  }) autoTrigger: MatAutocompleteTrigger;

  constructor(
    private fb: FormBuilder,
    private user: UserService,
    private dialogRef: MatDialogRef<DialogEditBannerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    const { name, hash, type, previewUrl, owner } = this.data;

    this.bannerType = type;
    this.fullPreviewUrl = previewUrl;

    if (owner) {
      owner.displayValue = this.getOwnerDisplayValue(owner);
      this.storyOwners = [owner];
    }

    this.form = this.fb.group({
      name: [
        this.hasValue(name) ? name : '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(200)]
      ],
      hash: [
        this.hasValue(hash) ? hash : '',
        [Validators.required, Validators.maxLength(20), Validators.pattern(/[-_a-zA-Z0-9]+/)]
      ],
      previewUrl: [
        this.hasValue(previewUrl) ? previewUrl.replace(this.previewPrefix, '').replace(this.previewSuffix, '') : '',
        [Validators.maxLength(300)]
      ],
      ownerId: [
        owner ? owner.id : null,
        [autocompleteStrictValidator(this.storyOwners.map(o => o.id))]
      ]
    });

    this.name = this.form.controls.name as FormControl;
    this.hash = this.form.controls.hash as FormControl;
    this.previewUrl = this.form.controls.previewUrl as FormControl;
    this.ownerId = this.form.controls.ownerId as FormControl;

    this.filteredStoryOwners = this.filterUsers(this.form.controls.ownerId.value);
    this.ownerChanged = this.form.controls.ownerId.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterUsers(value))
      ).subscribe(owners => this.filteredStoryOwners = owners);

    this.user.storyOwners()
      .pipe(first())
      .subscribe(response => {
        this.storyOwners = response.users.map(user => {
          user.displayValue = this.getOwnerDisplayValue(user);

          return user;
        });

        this.filteredStoryOwners = this.filterUsers(this.form.controls.ownerId.value);
        this.form.controls.ownerId.setValidators([
          autocompleteStrictValidator(this.storyOwners.map(o => o.id))
        ]);
      });
  }

  ngAfterViewInit() {
    this.autoPanelClosed = this.autoTrigger.panelClosingActions
      .pipe(
        filter(value => value === null)
      )
      .subscribe(() => {
        const owner = this.storyOwners.find(o => o.displayValue === this.ownerId.value);

        if (typeof owner !== 'undefined' && owner !== null) {
          this.ownerId.setValue(owner.id);
        } else if (this.storyOwners.findIndex(o => o.id === this.ownerId.value) < 0) {
          this.ownerId.setValue(null);
          this.ownerId.markAsUntouched();
          this.ownerId.markAsPristine();
        }
      });
  }

  ngOnDestroy() {
    this.ownerChanged.unsubscribe();
    this.autoPanelClosed.unsubscribe();
  }

  hasValue(value) {
    return typeof value === 'string' && value.length > 0;
  }

  getOwnerDisplayValue(owner) {
    return owner ? `${owner.firstName} ${owner.lastName} (${owner.email}) | ${owner.company.name}` : null;
  }

  getOwnerDisplayValueById = (ownerId) => {
    const owner = this.storyOwners.find(user => user.id === ownerId);

    return this.getOwnerDisplayValue(owner);
  }

  filterUsers(value) {
    if (typeof value !== 'string' || value.length === 0) {
      return this.storyOwners;
    }

    // filter in case value is not empty
    const filterValue = value.toLowerCase();

    return this.storyOwners.filter(user => {
      const { displayValue } = user;

      return displayValue.toLowerCase().includes(filterValue);
    });
  }

  async submit(form) {
    const { name, hash, type, previewUrl, ownerId } = form.value;

    this.dialogRef.close({
      name,
      hash,
      previewUrl: this.bannerType === 'CREATOPY'
        ? this.fullPreviewUrl : `https://cdn.bannersnack.com/banners/${previewUrl}/images/jpg`,
      ownerId: ownerId
    });
  }
}
