export function DriverService($http) {
	this.list = function (userId, nextPage) {
		var params = {
			user_id: userId
		};

		if (typeof nextPage !== 'undefined' && nextPage !== null) {
			params.from = nextPage;
		}

		return $http.get(constants.API_PATH + '/api/driver/list', {
			params: params
		});
	};

	this.search = function (query, nextPage) {
		var params = {
			q: query
		};

		if (typeof nextPage !== 'undefined' && nextPage !== null) {
			params.from = nextPage;
		}

		return $http.get(constants.API_PATH + '/api/driver/search', {
			params: params
		});
	};

	this.get = function (id) {
		return $http.get(constants.API_PATH + '/api/driver/get', { params: { id: id } });
	};

	this.create = function (name) {
		var data = { name: name };
		var headers = {
			'Content-Type': 'application/json'
		};

		return $http.post(constants.API_PATH + '/api/driver/create', data, { headers: headers });
	};

	this.edit = function (id, name) {
		var data = {
			id: id,
			name: name
		};

		var headers = {
			'Content-Type': 'application/json'
		};

		return $http.put(constants.API_PATH + '/api/driver/edit', data, { headers: headers });
	};

	this.copy = function (driverId) {
		var data = {
			id: driverId
		};

		var headers = {
			'Content-Type': 'application/json'
		};

		return $http.post(constants.API_PATH + '/api/driver/copy', data, { headers: headers });
	};

	this.save = function (driver) {
		var data = {
			id: driver.id,
			styles: driver.styles,
			panels: driver.panels
		};

		var headers = {
			'Content-Type': 'application/json'
		};

		return $http.put(constants.API_PATH + '/api/driver/save', data, { headers: headers });
	};

	this.delete = function (id) {
		var data = { id: id };
		var headers = {
			'Content-Type': 'application/json'
		};

		return $http.delete(constants.API_PATH + '/api/driver/delete', {
			data: data,
			headers: headers
		});
	};
}







