import template from './login.component.html';
import './login.component.css';

export const LoginComponent = {
	template: template,
	controller: function ($location, $timeout, AuthService, NotifyService) {
		var $ctrl = this;

		$ctrl.$onInit = function () {
			$ctrl.user = {};
			$ctrl.loggedIn = AuthService.isLoggedIn();

			if ($ctrl.loggedIn) {
				$ctrl.user = AuthService.getUser();
				AuthService.go('editor');
			} else {
				var params = $location.search();
				var code = params.code;
				var state = params.state;

				if (state && code) {
					var codeVerifier = AuthService.getCodeVerifier(state);

					AuthService.requestToken(code, codeVerifier).then(function (response) {
						AuthService.setToken(response.data);

						return AuthService.requestUserInfo();
					})
					.then(function (response) {
						AuthService.setUser(response.data.user);
						AuthService.go('editor');
					})
					.catch(function (error) {
						console.log('ERROR', error);
						NotifyService.notify('Failed to login. Please, try again later.', true);

						$timeout(function () {
							AuthService.authorize();
						}, 3000);
					});
				} else {
					AuthService.authorize();
				}
			}
		};
	},
	controllerAs: '$ctrl'
};
