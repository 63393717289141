import template from './assets-manager.component.html';
import './assets-manager.component.css';

import Cropper from 'cropperjs';
import ImageCompressor from 'image-compressor.js';

export const AssetsManagerComponent = {
	template: template,
	bindings: {
		driverId: '<',
		driverKey: '<',
		initialData: '<',
		usedUrls: '<',
		onUnoptimizedUsed: '<',
		onAction: '<',
		onError: '<'
	},
	controller: function ($element, $timeout, $mdDialog, $q, AssetsManagerService, AssetsService) {
		var $ctrl = this;

		$ctrl.maxWidth = 500;
		$ctrl.maxHeight = 1000;
		$ctrl.maxSize = 300 * 1024;

		// Infinite Scroll
		$ctrl.batchSize = 48;
		$ctrl.loadedAssetIndex = 0;
		$ctrl.loadedAssets = [];
		$ctrl.loadingInProgress = false;
		$ctrl.limitReached = false;

		$ctrl.$onInit = function () {
			$ctrl.selected_type = 'driver';
			$ctrl.assets_type = $ctrl.selected_type;

			if (!$ctrl.driverAssets) {
				$ctrl.driverAssets = [];
				$ctrl.assets = $ctrl.driverAssets;
			}

			if (!$ctrl.globalAssets) {
				$ctrl.globalAssets = [];
			}

			if (!$ctrl.blacklistAssets) {
				$ctrl.blacklistAssets = [];
			}

			// Imported assets
			$ctrl.importedAssets = [];

			$ctrl.compressor = new ImageCompressor();
			AssetsManagerService.setCompressor($ctrl.compressor);
			$ctrl.initEvents();

			// check for the upload
			$ctrl.isUploaded = true;
			$ctrl.alreadyImported = false;
		};

		$ctrl.$onChanges = function (changes) {
			var data = changes.initialData;
			var usedUrls = changes.usedUrls;

			if (data && data.currentValue) {
				var globalAssets = data.currentValue.global ? data.currentValue.global : [];
				var blacklistAssets = data.currentValue.blacklist ? data.currentValue.blacklist : [];
				var driverAssets = data.currentValue.driver ? data.currentValue.driver : [];

				if ($ctrl.driverAssets) {
					$ctrl.driverAssets.forEach(function (asset) {
						if (asset.local_url) {
							URL.revokeObjectURL(asset.local_url);
						}
					});

					$ctrl.deselect();
				}

				$ctrl.driverAssets = driverAssets.map(function (asset) {
					asset.type = $ctrl.setAssetType(asset.file_type);
					asset.optimized = asset.type === 'IMAGE' ? $ctrl.checkAssetOptimization(asset) : true;

					return asset;
				});

				// Infinite Scroll
				$ctrl.loadedAssetIndex = 0;
				$ctrl.loadedAssets = [];
				$ctrl.loadingInProgress = false;
				$ctrl.limitReached = false;

				$ctrl.assets = $ctrl.driverAssets;
				$ctrl.globalAssets = globalAssets.map(function (asset) {
					asset.type = $ctrl.setAssetType(asset.file_type);
					asset.locked = true;

					return asset;
				});

				$ctrl.blacklistAssets = blacklistAssets.map(function (asset) {
					asset.type = $ctrl.setAssetType(asset.file_type);
					asset.locked = true;

					return asset;
				});
			}

			if (usedUrls) {
				var urls = usedUrls.currentValue ? usedUrls.currentValue : {};

				for (var i = 0; i < $ctrl.driverAssets.length; i++) {
					var asset_url = $ctrl.driverAssets[i].url;

					if (urls[asset_url]) {
						$ctrl.driverAssets[i].used_in = urls[asset_url];
					} else {
						delete $ctrl.assets[i].used_in;
					}
				}

				$ctrl.checkUnoptimizedUsage($ctrl.driverAssets);
			}
		};

		$ctrl.$onDestroy = function () {
			if ($ctrl.driverAssets) {
				$ctrl.driverAssets.forEach(function (asset) {
					if (asset.local_url) {
						URL.revokeObjectURL(asset.local_url);
					}
				});
			}

			if ($ctrl.cropper) {
				$ctrl.cropper.destroy();
			}

			delete $ctrl.compressor;
		};

		$ctrl.notifyAction = function (message, action) {
			if ($ctrl.onAction) {
				$ctrl.onAction(message, action);
			}
		};

		$ctrl.notifyError = function (message, action) {
			if ($ctrl.onError) {
				$ctrl.onError(message, action);
			}
		};

		$ctrl.initEvents = function () {
			$ctrl.file_input = $element.find('.snap-uploader-input');
			$ctrl.file_input.change(function (event) {
				$ctrl.processFiles(event.target.files);
			});

			$element.on('dragenter', function () {
				$element.children('.snap-uploader').addClass('snap-drop-zone');
			});

			$element.find('.snap-uploader .snap-overlay').on('dragleave', function () {
				$element.children('.snap-uploader').removeClass('snap-drop-zone');
			});

			['drag', 'dragend', 'dragover', 'dragstart', 'dragenter', 'dragleave', 'drop'].forEach(function (event) {
				$element.find('.snap-uploader .snap-overlay').on(event, function (e) {
					e.preventDefault();
					e.stopPropagation();
				});

				$element.on(event, function (e) {
					e.preventDefault();
					e.stopPropagation();
				});
			});

			$element.find('.snap-uploader .snap-overlay').on('drop', function (event) {
				var files = event.originalEvent.dataTransfer.files;

				$ctrl.processFiles(files);
				$element.children('.snap-uploader').removeClass('snap-drop-zone');
			});
		};

		$ctrl.openFileDialog = function () {
			$ctrl.file_input[0].click();
		};

		$ctrl.processFiles = function (files) {
			var new_assets = [];
			var process_promises = [];

			$ctrl.notifyError('Upload started.');
			for (var i = 0; i < files.length; i++) {
				var file = files[i];
				var asset = {
					file: file,
					name: file.name,
					file_type: file.type,
					size: file.size,
					progress_value: 0,
					progress: 'determinate',
					uploading: true,
					created_at: new Date()
				};

				asset.preview_url = asset.local_url;
				asset.updated_at = asset.created_at;
				asset.local_url = URL.createObjectURL(asset.file);
				asset.type = $ctrl.setAssetType(asset.file_type);
				asset.hasPreview = asset.type === 'IMAGE' || asset.type === 'VIDEO';
				new_assets.push(asset);

				var process_promise = $q.resolve({});
				switch (asset.type) {
				case 'IMAGE':
					process_promise = AssetsManagerService.getImageInfo(asset.file, asset.local_url);
					break;
				case 'VIDEO':
					process_promise = AssetsManagerService.getVideoInfo(asset.local_url, asset.file_type);
					break;
				}

				process_promises.push(process_promise);
			}

			$q.all(process_promises).then(function (results) {
				for (var i = 0; i < results.length; i++) {
					var result = results[i];
					var new_asset = new_assets[i];

					if (typeof result.width !== 'undefined') {
						new_asset.width = result.width;
						new_asset.height = result.height;
					}

					new_asset.optimized = new_asset.type === 'IMAGE' ? $ctrl.checkAssetOptimization(new_asset) : true;
					if (result.preview_file) {
						new_asset.preview_file = result.preview_file;
						new_asset.preview_file_type = result.preview_file.type;

						if (new_asset.type === 'VIDEO') {
							URL.revokeObjectURL(new_asset.local_url);
							new_asset.local_url = URL.createObjectURL(new_asset.preview_file);
						}
					}
				}

				return AssetsService.sign({ type: 'DRIVER', driver_id: $ctrl.driverId }, new_assets, true);
			}).then(function (response) {
				var signed_assets = response.data.assets;

				for (var i = 0; i < signed_assets.length; i++) {
					new_assets[i] = $ctrl.prepareForUpload(new_assets[i], signed_assets[i]);
					$ctrl.assets.push(new_assets[i]);
				}
			}).catch(function () {
				$ctrl.notifyError('Failed to upload.');
			});
		};

		$ctrl.initAssets = function (assets) {
			return assets.map(function (asset) {
				asset.type = $ctrl.setAssetType(asset.file_type);
				if (asset.width && asset.height) {
					asset.ratio = asset.width / asset.height;
				}

				return asset;
			});
		};

		$ctrl.setAssetType = function (file_type) {
			var type = /image/g.test(file_type) ? 'IMAGE' : 'FILE';

			return /video/g.test(file_type) ? 'VIDEO' : type;
		};

		$ctrl.copyAssets = function (source, destination) {
			var selected_asset_ids = [];

			$ctrl.assets.forEach(function (asset) {
				if (asset.selected) {
					selected_asset_ids.push(asset.id);
				}
			});

			$ctrl.notifyAction(selected_asset_ids.length > 1 ? 'Making assets copies.' : 'Making a copy.');
			AssetsService.copy(source, destination, selected_asset_ids)
				.then(function (response) {
					var assets = response.data.assets;
					var message = 'The cop' + (selected_asset_ids.length > 1 ? 'ies were' : 'y was') + ' made.';

					assets.forEach(function (asset) {
						asset.type = $ctrl.setAssetType(asset.file_type);
						asset.optimized = asset.type === 'IMAGE' ? $ctrl.checkAssetOptimization(asset) : true;
						$ctrl.driverAssets.push(asset);
					});

					$ctrl.notifyAction(message);
				})
				.catch(function () {
					var message = 'Failed to make ' + (selected_asset_ids.length > 1 ? 'the copies.' : 'a copy.');

					$ctrl.notifyError(message);
				});
		};

		$ctrl.downloadAsset = function (url, name) {
			var link = $('<a style="position: fixed; transform: translateX(-100%)"></a>');
			link.attr('download', name);
			link.attr('href', url);

			document.body.appendChild(link[0]);
			link[0].click();

			link.remove();
		};

		$ctrl.showAssets = function () {
			$ctrl.deselect();

			$timeout(function () {
				$ctrl.selected_type = $ctrl.assets_type;

				// Infinite Scroll
				$ctrl.loadedAssetIndex = 0;
				$ctrl.loadedAssets = [];
				$ctrl.loadingInProgress = false;
				$ctrl.limitReached = false;

				switch ($ctrl.selected_type) {
				case 'global':
					$ctrl.assets = $ctrl.globalAssets;
					break;
				case 'blacklist':
					$ctrl.assets = $ctrl.blacklistAssets;
					break;
				case 'import':
					$ctrl.assets = $ctrl.importedAssets;
					break;
				default:
					$ctrl.assets = $ctrl.driverAssets;
				}
			}, 200);
		};

		// Import
		$ctrl.askForUrl = function (event) {
			var prompt = $mdDialog.prompt()
				.title('Import Assets')
				.textContent('Please, enter the URL for the assets import.')
				.placeholder('Assets URL')
				.ariaLabel('Assets URL')
				.targetEvent(event)
				.required(true)
				.ok('Import')
				.cancel('Cancel');

			$mdDialog.show(prompt).then(function (url) {
				$ctrl.isUploaded = false;
				AssetsService.import(url)
					.then(function (response) {
						var urls = response.data.urls;

						$ctrl.isUploaded = true;
						$ctrl.alreadyImported = true;

						$ctrl.importedAssets = urls.map(function (url) {
							return {
								type: 'IMAGE',
								name: url,
								preview_url: url,
								url: url,
								optimized: true,
								locked: true
							};
						});

						$ctrl.assets = $ctrl.importedAssets;
					})
					.catch(function () {
						var message = 'Failed to import assets.';

						$ctrl.isUploaded = true;
						$ctrl.notifyError(message);
					});
			}, function () {
				// Error handling here
			});
		};

		$ctrl.checkUnoptimizedUsage = function (assets) {
			var used_unoptimized = false;

			for (var i = 0; i < assets.length; i++) {
				if (!assets[i].optimized && assets[i].used_in && assets[i].used_in.length > 0) {
					used_unoptimized = true;
					break;
				}
			}

			if ($ctrl.onUnoptimizedUsed) {
				$ctrl.onUnoptimizedUsed(used_unoptimized);
			}
		};

		//#region Upload logic

		$ctrl.uploadAsset = function (asset) {
			asset.type = $ctrl.setAssetType(asset.file_type);
			asset.optimized = $ctrl.checkAssetOptimization(asset);

			asset.progress_value = 0;
			asset.progress = 'determinate';
			asset.uploading = true;
			asset.updated_at = new Date();

			$ctrl.notifyAction('Upload started.');
			AssetsManagerService.getImageInfo(asset.file, asset.local_url)
				.then(function (result) {
					asset.width = result.width;
					asset.height = result.height;

					asset.preview_file = result.preview_file;
					asset.preview_file_type = result.preview_file.type;

					return AssetsService.sign({ type: 'DRIVER', driver_id: $ctrl.driverId }, [asset]);
				})
				.then(function (response) {
					var signed_asset = response.data.assets[0];

					asset.signed_url = signed_asset.signed_url;
					if (signed_asset.preview_url) {
						asset.preview_signed_url = signed_asset.preview_signed_url;
					}

					var upload = AssetsManagerService.upload(asset, function (event) {
						asset.progress_value = Math.round(event.loaded / event.total * 100);
					});
					asset.cancel = upload.cancel;

					return upload.promise;
				})
				.then(function () {
					asset.uploaded = true;
					asset.saving = true;

					return AssetsService.put({ type: 'DRIVER', driver_id: $ctrl.driverId }, asset);
				})
				.then(function () {
					asset.progress = null;
					asset.uploading = false;
					asset.failed = false;
					asset.uploaded = false;
					asset.saving = false;

					$ctrl.notifyAction('Upload was successful.');
				})
				.catch(function () {
					var action = {
						name: 'retry',
						proceed: function () {
							$ctrl.uploadAsset(asset);
						}
					};

					if (asset.signed_url) {
						asset.failed = true;
					}

					$ctrl.notifyError('Failed to upload.', action);
				});
		};

		$ctrl.prepareForUpload = function (asset, signed_asset) {
			asset.key = signed_asset.key;
			asset.url = signed_asset.url;
			asset.signed_url = signed_asset.signed_url;

			if (signed_asset.preview_url) {
				asset.preview_key = signed_asset.preview_key;
				asset.preview_url = signed_asset.preview_url;
				asset.preview_signed_url = signed_asset.preview_signed_url;
			}

			$ctrl.startUpload(asset);

			return asset;
		};

		$ctrl.startUpload = function (asset) {
			var upload = AssetsManagerService.upload(asset, function (event) {
				asset.progress_value = Math.round(event.loaded / event.total * 100);
			});

			asset.cancel = upload.cancel;
			upload.promise.then(function () {
				asset.uploaded = true;

				$ctrl.checkUploadStatus();
			}).catch(function () {
				$ctrl.notifyError('Failed to upload.');
			});
		};

		$ctrl.checkUploadStatus = function () {
			var uploaded = 0;
			var total = 0;

			for (var i = 0; i < $ctrl.assets.length; i++) {
				var asset = $ctrl.assets[i];

				if (asset.uploaded) {
					uploaded++;
					total++;
				} else if (asset.uploading && !asset.failed) {
					total++;
				}
			}

			if (uploaded === total) {
				$ctrl.save();
			}
		};

		$ctrl.save = function () {
			var save_assets = [];

			for (var i = 0; i < $ctrl.driverAssets.length; i++) {
				if ($ctrl.driverAssets[i].uploaded && !$ctrl.driverAssets[i].saving) {
					$ctrl.driverAssets[i].saving = true;

					save_assets.push($ctrl.driverAssets[i]);
				}
			}

			if (save_assets.length > 0) {
				AssetsService.post({ type: 'DRIVER', driver_id: $ctrl.driverId }, save_assets)
					.then(function (response) {
						var assets = response.data.assets;

						for (var i = 0; i < assets.length; i++) {
							save_assets[i].progress = null;
							save_assets[i].uploading = false;
							save_assets[i].failed = false;
							save_assets[i].uploaded = false;
							save_assets[i].saving = false;
							save_assets[i].id = assets[i].id;
						}

						$ctrl.notifyError('Upload was successful.');
					})
					.catch(function () {
						$ctrl.notifyError('Failed to upload.');
					});
			}
		};

		//#endregion
		//#region Selection logic

		$ctrl.selectAsset = function (event, index) {
			var i = 0;

			event.preventDefault();
			event.stopPropagation();

			$ctrl.selected_asset = $ctrl.assets[index];
			if (event.shiftKey) {
				if (typeof $ctrl.start_index === 'undefined') {
					$ctrl.start_index = index;
				}

				var start_index = index;
				var end_index = index;

				if ($ctrl.start_index < index) {
					start_index = $ctrl.start_index;
				} else if ($ctrl.start_index > index) {
					end_index = $ctrl.start_index;
				}

				for (i = 0; i < start_index; i++) {
					if ($ctrl.assets[i].selected) {
						$ctrl.assets[i].selected = false;
					}
				}

				end_index++;
				for (i = start_index; i < end_index; i++) {
					if (!$ctrl.assets[i].selected) {
						$ctrl.assets[i].selected = true;
					}
				}

				for (i = end_index; i < $ctrl.assets.length; i++) {
					if ($ctrl.assets[i].selected) {
						$ctrl.assets[i].selected = false;
					}
				}

				$ctrl.toggleInfoPanel();
			} else if (event.ctrlKey) {
				$ctrl.start_index = index;
				$ctrl.assets[index].selected = !$ctrl.assets[index].selected;

				$ctrl.toggleInfoPanel();
			} else {
				$ctrl.selectSingleAsset(index);
			}
		};

		$ctrl.selectSingleAsset = function (index) {
			$ctrl.start_index = index;

			$ctrl.assets[index].selected = true;
			for (var i = 0; i < index; i++) {
				if ($ctrl.assets[i].selected) {
					$ctrl.assets[i].selected = false;
				}
			}

			for (i = index + 1; i < $ctrl.assets.length; i++) {
				if ($ctrl.assets[i].selected) {
					$ctrl.assets[i].selected = false;
				}
			}

			$ctrl.toggleInfoPanel();
		};

		$ctrl.toggleInfoPanel = function () {
			if ($ctrl.selected_type === 'driver') {
				$element.find('.driver-asset-info').css('max-height', '500px');
			}
		};

		$ctrl.deselect = function () {
			for (var i = 0; i < $ctrl.assets.length; i++) {
				$ctrl.assets[i].selected = false;
			}

			$element.find('.driver-asset-info').css('max-height', 0);
		};

		//#endregion
		//#region Asset Dialog logic

		$ctrl.openAssetDialog = function (event) {
			if ($ctrl.selected_asset.progress) {
				return;
			}

			$ctrl.editorMode = 'compress';
			$ctrl.opened = true;

			var asset = $ctrl.selected_asset;
			var type = $ctrl.selected_asset.type;

			$ctrl.assetFile = null;
			$ctrl.hasChanges = false;
			switch (type) {
			case 'IMAGE':
				if (asset.file) {
					$ctrl.setEditedImageData(asset.file, asset.width, asset.height);
				} else if (asset.locked) {
					$ctrl.assetFileUrl = $ctrl.selected_asset.url;
				} else {
					AssetsManagerService.download(asset.url).then(function (response) {
						if ($ctrl.opened) {
							$ctrl.selected_asset.file = response.data;
							$ctrl.setEditedImageData($ctrl.selected_asset.file, asset.width, asset.height);
						}
					});
				}

				$timeout(function () {
					$mdDialog.show({
						contentElement: '#assetDialog',
						parent: angular.element(document.body),
						targetEvent: event,
						clickOutsideToClose: true,
						fullscreen: true,
						onRemoving: function () {
							$ctrl.opened = false;

							if ($ctrl.cropper) {
								$ctrl.cropper.destroy();

								delete $ctrl.cropper;
							}

							delete $ctrl.imageWidth;
							delete $ctrl.imageHeight;
							delete $ctrl.imageQuality;
						}
					}).then(function () {
						delete $ctrl.assetFileUrl;
					}).catch(function () {
						URL.revokeObjectURL($ctrl.assetFileUrl);

						delete $ctrl.assetFileUrl;
					});
				});
				break;
			case 'VIDEO':
				$ctrl.assetFileUrl = asset.url;

				$timeout(function () {
					$mdDialog.show({
						contentElement: $element.find('#assetDialog'),
						parent: angular.element(document.body),
						targetEvent: event,
						clickOutsideToClose: true,
						fullscreen: true,
						onRemoving: function () {
							$ctrl.opened = false;
							delete $ctrl.assetFileUrl;
						}
					}).then(function () { }).catch(function () { });
				});

				break;
			}
		};

		$ctrl.downloadFile = function (asset) {
			$ctrl.notifyAction('Download started.');
			if (/^\s*(file|blob):/gi.test($ctrl.assetFileUrl)) {
				$ctrl.downloadAsset($ctrl.assetFileUrl, asset.name);
			} else {
				AssetsManagerService.download($ctrl.assetFileUrl)
					.then(function (response) {
						asset.file = response.data;
						asset.local_url = URL.createObjectURL(asset.file);

						$ctrl.downloadAsset(asset.local_url, asset.name);
					})
					.catch(function () {
						var action = null;

						if (asset) {
							action = {
								name: 'retry',
								proceed: function () {
									$ctrl.downloadFile(asset);
								}
							};
						}

						$ctrl.notifyError('Download failed.', action);
					});
			}
		};

		$ctrl.closeAssetDialog = function () {
			$mdDialog.cancel();
		};

		$ctrl.uploadEditedFile = function () {
			$ctrl.selected_asset.file = $ctrl.assetFile;
			$ctrl.selected_asset.size = $ctrl.assetFile.size;
			$ctrl.selected_asset.width = $ctrl.imageWidth;
			$ctrl.selected_asset.height = $ctrl.imageHeight;
			$ctrl.selected_asset.local_url = $ctrl.assetFileUrl;

			$ctrl.uploadAsset($ctrl.selected_asset);
			$ctrl.checkUnoptimizedUsage($ctrl.driverAssets);

			$mdDialog.hide();
		};

		$ctrl.toggleCrop = function () {
			if ($ctrl.editorMode !== 'crop' && !$ctrl.cropper) {
				$ctrl.editorMode = 'crop';

				$ctrl.initCropper();
			}
		};

		$ctrl.initCropper = function () {
			var image = $('md-dialog.asset-dialog #assetImage')[0];

			$ctrl.cropper = new Cropper (image, {
				autoCropArea: 0.9,
			});

			$timeout(function () {
				$ctrl.cropper.zoom(-0.1);
			}, 100);
		};

		$ctrl.toggleCompress = function () {
			if ($ctrl.editorMode !== 'compress') {
				if ($ctrl.cropper) {
					$ctrl.cropper.destroy();

					delete $ctrl.cropper;
				}

				$ctrl.editorMode = 'compress';
			}
		};

		$ctrl.applySizeChanges = function () {
			if ($ctrl.imageWidth !== $ctrl.previousWidth) {
				$ctrl.imageHeight = Math.floor($ctrl.imageWidth / $ctrl.aspectRatio);

				$ctrl.previousWidth = $ctrl.imageWidth;
				$ctrl.previousHeight = $ctrl.imageHeight;
			} else if ($ctrl.imageHeight !== $ctrl.previousHeight) {
				$ctrl.imageWidth = Math.floor($ctrl.imageHeight * $ctrl.aspectRatio);

				$ctrl.previousWidth = $ctrl.imageWidth;
				$ctrl.previousHeight = $ctrl.imageHeight;
			}
		};

		$ctrl.compress = function () {
			var quality = parseFloat($ctrl.imageQuality);

			$ctrl.compressor.compress($ctrl.assetFile, {
				quality: quality,
				width: $ctrl.imageWidth,
			}).then(function (result) {
				$timeout(function () {
					$ctrl.setEditedImageData(result);
					$ctrl.hasChanges = true;

					$ctrl.notifyAction('Asset was successfully compressed.');
				});
			});
		};

		$ctrl.crop = function () {
			var canvas = $ctrl.cropper.getCroppedCanvas();

			canvas.toBlob(function (blob) {
				$ctrl.cropper.destroy();

				$timeout(function () {
					$ctrl.setEditedImageData(blob);
					$ctrl.hasChanges = true;

					$ctrl.notifyAction('Asset was successfully cropped.');
					$timeout($ctrl.initCropper);
				});
			});
		};

		$ctrl.setEditedImageData = function (file, width, height) {
			$ctrl.assetFile = file;

			if ($ctrl.assetFileUrl) {
				URL.revokeObjectURL($ctrl.assetFileUrl);
			}
			$ctrl.assetFileUrl = URL.createObjectURL($ctrl.assetFile);
			$ctrl.imageQuality = 0.8;
			if (typeof width === 'undefined' || typeof height === 'undefined') {
				AssetsManagerService.getImageSize($ctrl.assetFileUrl).then(function (size) {
					$ctrl.setEditedImageProperties(size.width, size.height);
				});
			} else {
				$ctrl.setEditedImageProperties(width, height);
			}
		};

		$ctrl.setEditedImageProperties = function (width, height) {
			$ctrl.imageWidth = width;
			$ctrl.imageHeight = height;
			$ctrl.previousWidth = width;
			$ctrl.previousHeight = height;
			$ctrl.aspectRatio = width / height;
		};

		//#endregion
		//#region Optimization logic

		$ctrl.checkAssetOptimization = function (asset) {
			return asset.width <= $ctrl.maxWidth && asset.height <= $ctrl.maxHeight && asset.size <= $ctrl.maxSize;
		};

		$ctrl.isWidthNotOptimized = function (asset) {
			return asset.width > $ctrl.maxWidth;
		};

		$ctrl.isHeightNotOptimized = function (asset) {
			return asset.height > $ctrl.maxHeight;
		};

		$ctrl.isSizeNotOptimized = function (asset) {
			return asset.size > $ctrl.maxSize;
		};

		//#endregion
		//#region ContextMenu logic

		$ctrl.openMenu = function ($mdMenu, event, asset, index) {
			$ctrl.selected_asset = asset;

			if (!asset.selected) {
				$ctrl.selectSingleAsset(index);
			}

			if ($ctrl.selected_asset.progress !== 'indeterminate') {
				var elem = event.target;
				var rect = elem.getBoundingClientRect();

				$ctrl.menu_offset = event.clientX - rect.left + ' ' + (event.clientY - rect.top);
				$timeout(function () {
					$mdMenu.open(event);
				});
			}
		};

		$ctrl.cancelUpload = function () {
			$ctrl.selected_asset.canceled = true;

			if ($ctrl.selected_asset.cancel) {
				$ctrl.selected_asset.cancel();
			}

			$timeout(function () {
				$ctrl.selected_asset.uploading = false;
				$ctrl.selected_asset.failed = true;

				$ctrl.notifyAction('The upload was canceled.');
			}, 100);
		};

		$ctrl.copyToClipboard = function () {
			var url = $ctrl.selected_asset.url;
			var input = $('<input style="position: fixed; transform: translateX(-100%)">');

			input.attr('value', url);
			document.body.appendChild(input[0]);
			input.select();

			var success = document.execCommand('copy');
			if (success) {
				$ctrl.notifyAction('Asset link was copied to clipboard.');
			} else {
				$ctrl.notifyError('Failed to copy link to clipboard.');
			}

			input.remove();
		};

		$ctrl.makeAssetsCopies = function () {
			var source = { type: 'DRIVER', driver_id: $ctrl.driverId };

			$ctrl.copyAssets(source, source);
		};

		$ctrl.renameAsset = function (event) {
			var rename = $mdDialog.prompt()
				.title('Rename')
				.textContent('Please, enter a new name for the asset.')
				.placeholder('Asset name')
				.ariaLabel('Asset name')
				.initialValue($ctrl.selected_asset.name)
				.targetEvent(event)
				.required(true)
				.ok('Ok')
				.cancel('Cancel');

			$mdDialog.show(rename).then(function (asset_name) {
				$ctrl.selected_asset.name = asset_name;
				$ctrl.selected_asset.updated_at = new Date();

				AssetsService.put({ type: 'DRIVER', driver_id: $ctrl.driverId }, $ctrl.selected_asset)
					.then(function () {
						$ctrl.notifyAction('The asset was successfully renamed.');
					})
					.catch(function () {
						$ctrl.notifyError('Failed to rename the asset.');
					});
			}).catch(function () { });
		};

		$ctrl.downloadFiles = function () {
			var download_promises = [];
			$ctrl.notifyAction('Download started.');
			$ctrl.assets.forEach(function (asset) {
				if (asset.selected) {
					if (asset.local_url) {
						$ctrl.downloadAsset(asset.local_url, asset.name);
					} else {
						var download_promise = AssetsManagerService.download(asset.url)
							.then(function (response) {
								asset.file = response.data;
								asset.local_url = URL.createObjectURL(asset.file);

								$ctrl.downloadAsset(asset.local_url, asset.name);
							});

						download_promises.push(download_promise);
					}
				}
			});

			$q.all(download_promises).catch(function () {
				var message = 'Failed to download the asset' + (download_promises.length > 1 ? 's' : '') + '.';

				$ctrl.notifyError(message);
			});
		};

		$ctrl.deleteAssets = function (event) {
			var message = '';
			var are_used = false;
			var selected_assets = [];

			$ctrl.assets.forEach(function (asset) {
				if (asset.selected) {
					selected_assets.push(asset);

					if (asset.used_in) {
						are_used = true;
					}
				}
			});

			message = selected_assets.length > 1 ? 'these assets?' : 'this asset?';
			if (are_used) {
				message += (selected_assets.length > 1 ? ' Some are' : " It's") + ' used in this Driver.';
			}

			var remove = $mdDialog.confirm()
				.title('Remove')
				.textContent('Are you sure you want to delete ' + message)
				.ariaLabel('Delete assets')
				.targetEvent(event)
				.ok('Remove')
				.cancel('Cancel');

			$mdDialog.show(remove).then(function () {
				var entity = { type: 'DRIVER', driver_id: $ctrl.driverId };
				var selection_pool = {};
				var selected_asset_ids = selected_assets.map(function (asset) {
					selection_pool[asset.id] = true;
					return asset.id;
				});

				for (var i = 0; i < selected_assets.length; i++) {
					selected_assets[i].progress = 'indeterminate';
				}

				AssetsService.delete(entity, selected_asset_ids)
					.then(function () {
						$ctrl.deselect();

						for (var i = 0; i < $ctrl.driverAssets.length; i++) {
							var asset = $ctrl.driverAssets[i];

							if (selection_pool[asset.id]) {
								if ($ctrl.driverAssets[i].local_url) {
									URL.revokeObjectURL($ctrl.driverAssets[i].local_url);
								}

								$ctrl.driverAssets.splice(i, 1);
								i--;
							}
						}

						var message = 'The asset' + (selected_assets.length > 1 ? 's were' : ' was') + ' successfully deleted.';
						$ctrl.notifyAction(message);
					})
					.catch(function () {
						var message = 'Failed to delete the asset' + (selected_assets.length > 1 ? 's' : '') + '.';

						for (var i = 0; i < selected_assets.length; i++) {
							selected_assets[i].progress = null;
						}

						$ctrl.notifyError(message);
					});
			}).catch(function () { });
		};

		//Adding from global library to a Driver
		$ctrl.addToDriver = function () {
			var source = { type: 'GLOBAL' };
			var destination = { type: 'DRIVER', driver_id: $ctrl.driverId };

			$ctrl.copyAssets(source, destination);
		};

		$ctrl.addToBlacklist = function () {
			var messages = {
				notification: 'Adding assets to blacklist...',
				success: 'Assets added to blacklist.',
				error: 'Failed to add assets to blacklist.'
			};

			$ctrl.moveAssets($ctrl.globalAssets, $ctrl.blacklistAssets, messages, true);
		};

		$ctrl.removeFromBlacklist = function () {
			var messages = {
				notification: 'Removing assets from blacklist...',
				success: 'Assets removed from blacklist.',
				error: 'Failed to remove assets from blacklist.'
			};

			$ctrl.moveAssets($ctrl.blacklistAssets, $ctrl.globalAssets, messages, false);
		};

		$ctrl.moveAssets = function (sourceArray, destinationArray, messages, isBlacklist) {
			var selectedAssetIndexes = [];
			var selectedAssetIds = [];

			for (var i = 0; i < sourceArray.length; i++) {
				if (sourceArray[i].selected) {
					selectedAssetIds.push(sourceArray[i].id);
					selectedAssetIndexes.push(i);
				}
			}

			$ctrl.notifyAction(messages.notification);
			var promise = isBlacklist ? AssetsService.addToBlacklist(selectedAssetIds) : AssetsService.removeFromBlacklist(selectedAssetIds);

			promise.then(function () {
				var index = 0;
				for (i = selectedAssetIndexes.length - 1; i > -1; i--) {
					index = selectedAssetIndexes[i];

					sourceArray[i].selected = false;
					destinationArray.unshift(sourceArray[index]);
					sourceArray.splice(index, 1);
				}

				$ctrl.notifyAction(messages.success);
			}).catch(function () {
				$ctrl.notifyError(messages.error);
			});
		};

		// Infinite scroll --------

		$ctrl.loadAssetsBatch = function () {
			var minIndex = $ctrl.loadedAssetIndex;
			var maxIndex = minIndex + $ctrl.batchSize;

			if (maxIndex >= $ctrl.assets.length) {
				$ctrl.limitReached = true;

				maxIndex = $ctrl.assets.length;
			}

			for (var i = minIndex; i < maxIndex; i++) {
				$ctrl.loadedAssets.push($ctrl.assets[i]);
			}

			$ctrl.loadedAssetIndex = maxIndex;
		};

		//#endregion
	},
	controllerAs: '$ctrl'
};
