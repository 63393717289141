import { AuthService } from './services/auth.service';
import { NotifyService } from './services/notify.service';
import { EditorDriverHeatmapsService } from './services/editor-driver-heatmaps.service';

// AuthService
export function authServiceFactory(i: any) {
  return i.get('AuthService');
}

export const authServiceProvider = {
  provide: AuthService,
  useFactory: authServiceFactory,
  deps: ['$injector']
};

// NotifyService
export function notifyServiceFactory(i: any) {
  return i.get('NotifyService');
}

export const notifyServiceProvider = {
  provide: NotifyService,
  useFactory: notifyServiceFactory,
  deps: ['$injector']
};

// EditorHeatmapsService
export function editorDriverHeatmapsServiceFactory(i: any) {
  return i.get('EditorDriverHeatmapsService');
}

export const editorDriverHeatmapsServiceProvider = {
  provide: EditorDriverHeatmapsService,
  useFactory: editorDriverHeatmapsServiceFactory,
  deps: ['$injector']
};