import angular from 'angular';
import ngSanitize from 'angular-sanitize';

import { PhonePreviewComponent } from './phone-preview.component';
import { PanelPreviewComponent } from './panel-preview/panel-preview.component';

export const PhonePreviewModule = angular
	.module('CreateSnapApp.editor.phone', [
		ngSanitize
	])
    .component('phonePreview', PhonePreviewComponent)
	.component('panelPreview', PanelPreviewComponent)
	.name;
