import { Component, OnInit, Input, HostBinding, HostListener, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'page-story-item',
  template: require('./page-story-item.component.html'),
  styles: [require('./page-story-item.component.a.css')]
})
export class PageStoryItemComponent implements OnInit {
  // TODO: Use elevation example for the snap panels
  // private isHovered = false;

  @HostBinding('class.iml-background-card') imlBackgroundCard = true;
  @HostBinding('class.mat-elevation-z1') matElevation = true;
  // @HostBinding('class.mat-elevation-z4') get hovered() { return this.isHovered; }
  // @HostBinding('class.mat-elevation-z1') get unhovered() { return !this.isHovered; }

  @Input() user: any;
  @Input() inProgress: boolean;
  @Input() name: string;
  @Input() url: string;
  @Input() previewUrl: string;
  @Input() updatedAt: string | Date;
  @Input() highlight: any;

  @Output() clicked = new EventEmitter<any>();

  private get hasPreview() { return typeof this.previewUrl === 'string' && this.previewUrl.length > 0; }
  // private get cssPreviewUrl() { return `url("${this.previewUrl}")`; }

  constructor() { }

  ngOnInit() { }

  // @HostListener('mouseenter', ['true'])
  // @HostListener('mouseleave', ['false'])
  // toggleHover(isHovered) {
  //   // this.isHovered = isHovered;
  // }

  click() {
    this.clicked.emit();
  }

  getCssUrl(url) {
    return `url("${url}")`;
  }
}
