import angular from 'angular';
import ngSanitize from 'angular-sanitize';
import { TopNavbarModule } from './top-navbar/top-navbar.module';
import { PanelsListModule } from './panels-list/panels-list.module';
import { AssetsManagerModule } from './assets-manager/assets-manager.module';
import { PhonePreviewModule } from './phone-preview/phone-preview.module';
import { EditorDriverListModule } from './editor-driver-list/editor-driver-list.module';
import { EditorComponent } from './editor.component';
import { EditorPropertiesComponent } from './editor-properties/editor-properties.component';
import { EditorAnimationComponent } from './editor-animation/editor-animation.component';
// import { CodeEditorComponent } from './code-editor/code-editor.component.ajs';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { CodeEditorComponent } from './code-editor/code-editor.component';
import { PageDriverListComponent } from './editor-dialogs/page-driver-list/page-driver-list.component';
import { EditorStateService } from './shared/editor-state.service';
import { EditorDialogsService } from './editor-dialogs/editor-dialogs.service';
import { EditorElementsComponent } from './editor-elements/editor-elements.component';
import { EditorTextToolbarComponent } from './editor-text-toolbar/editor-text-toolbar.component';
import { EditorDriverDetailsComponent } from './editor-driver-details/editor-driver-details.component';
import { EditorDriverDetailsService } from './editor-driver-details/editor-driver-details.service';
import { NdAssetsExtractorComponent } from './nd-assets-extractor/nd-assets-extractor.component';
import { ExtractorImageComponent } from './nd-assets-extractor/extractor-image/extractor-image.component';
import { NdAssetsExtractorService } from './nd-assets-extractor/nd-assets-extractor.service';
import { EditorDriverHeatmapsComponent } from './editor-dialogs/editor-driver-heatmaps/editor-driver-heatmaps.component';

export const EditorModule = angular
  .module('CreateSnapApp.editor', [
    ngSanitize,
    PanelsListModule,
    AssetsManagerModule,
    TopNavbarModule,
    PhonePreviewModule,
    EditorDriverListModule
  ])
  // .directive('cutCorners', function () {
  // 	return {
  // 		link: function (scope, element, attrs) {
  // 			var shift = 5;
  // 			var that = this;
  // 			this.supportsClipPath = function () {
  // 				var clipPathSupport = 'clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%)';
  // 				return !CSS.supports('(-webkit-' + clipPathSupport + ') or (' + clipPathSupport + ')');
  // 			};
  // 			this.setCorners = function (shift) {
  // 				if (CSS && CSS.supports && that.supportsClipPath()) {
  // 					that.setCutCorners(shift);
  // 				} else {
  // 					that.setRoundCorners();
  // 				}
  // 			};
  // 			this.setCutCorners = function (shift) {
  // 				var dots = '0 ' + shift +'px, ' + shift + 'px 0, ';
  // 				dots += 'calc(100% - ' + shift + 'px) 0, 100% ' + shift + 'px, ';
  // 				dots += '100% calc(100% - ' + shift + 'px), calc(100% - ' + shift + 'px) 100%, ';
  // 				dots += '' + shift + 'px 100%, 0 calc(100% - ' + shift + 'px)';
  // 				element.css('-webkit-clip-path', 'polygon(' + dots + ')');
  // 				element.css('clip-path', 'polygon(' + dots + ')');
  // 			};
  // 			this.setRoundCorners = function () {
  // 				element.css('border-radius', '' + element.height() / 2 + 'px');
  // 			};
  // 			scope.$watch(attrs.cutCorners, function (value) {
  // 				if (value !== null && typeof value !== 'undefined') {
  // 					shift = value;
  // 				}
  // 				that.setCorners(shift);
  // 			});
  // 		}
  // 	};
  // })
  .component('editor', EditorComponent)
  .component('editorProperties', EditorPropertiesComponent)
  .component('editorAnimation', EditorAnimationComponent)
  // .component('codeEditor', CodeEditorComponent)
  .directive(
    'codeEditor',
    downgradeComponent({ component: CodeEditorComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'pageDriverList',
    downgradeComponent({ component: PageDriverListComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'editorDriverHeatmaps',
    downgradeComponent({ component: EditorDriverHeatmapsComponent }) as angular.IDirectiveFactory
  )
  .component('editorElements', EditorElementsComponent)
  .component('editorTextToolbar', EditorTextToolbarComponent)
  .component('ndAssetsExtractor', NdAssetsExtractorComponent)
  .component('editorDriverDetails', EditorDriverDetailsComponent)
  .component('extractorImage', ExtractorImageComponent)
  .factory('editorStateService', downgradeInjectable(EditorStateService))
  .factory('editorDialogsService', downgradeInjectable(EditorDialogsService))
  .service('NdAssetsExtractorService', NdAssetsExtractorService)
  .service('EditorDriverDetailsService', EditorDriverDetailsService)
  .name;