import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TubeLoaderComponent } from './iml-tube-loader/tube-loader.component';
import { IMLPageDialogComponent } from './iml-page-dialog/iml-page-dialog.component';
import { ExtendedDatePipe } from './extended-date.pipe';

@NgModule({
  imports: [CommonModule],
  exports: [
    TubeLoaderComponent,
    IMLPageDialogComponent,
    ExtendedDatePipe
  ],
  declarations: [
    TubeLoaderComponent,
    IMLPageDialogComponent,
    ExtendedDatePipe
  ],
  providers: [],
})
export class SharedModule { }
