import template from './editor-animation.component.html';
import './editor-animation.component.css';

export const EditorAnimationComponent = {
	template: template,
	bindings: {
		initialData: '<',
		onUpdate: '<',
		activeElement: '<'
	},
	controller: function ($element, $timeout, $scope, $rootScope) {
		var $ctrl = this;

		$ctrl.$onInit = function () {

			$ctrl.colorPickerOptions = {
				label: "Choose a color",
				openOnInput: true,
				history: false,
				hex: true,
				rgb: false
			};

			$ctrl.animationNameList = [
				{
					name: 'swing',
					value: 'nd-animation_swing',
					classes: 'animated infinite swing'
				},
				{
					name: 'lightSpeedIn',
					value: 'nd-animation_lightSpeedIn',
					classes: 'animated infinite lightSpeedIn'
				},
				{
					name: 'fadeInDown',
					value: 'nd-animation_fadeInDown',
					classes: 'animated infinite fadeInDown'
				},
				{
					name: 'jello',
					value: 'nd-animation_jello',
					classes: 'animated infinite jello'
				},
				{
					name: 'bounce',
					value: 'nd-animation_bounce',
					classes: 'animated infinite bounce'
				},
				{
					name: 'tada',
					value: 'nd-animation_tada',
					classes: 'animated infinite tada'
				},
				{
					name: 'zoomIn',
					value: 'nd-animation_zoomIn',
					classes: 'animated infinite zoomIn'
				},
				{
					name: 'bounceInLeft',
					value: 'nd-animation_bounceInLeft',
					classes: 'animated infinite bounceInLeft'
				},
				{
					name: 'rollIn',
					value: 'nd-animation_rollIn',
					classes: 'animated infinite rollIn'
				},
				{
					name: 'flipInY',
					value: 'nd-animation_flipInY',
					classes: 'animated infinite flipInY'
				}

			]


		};


		// $rootScope.getObjectAnimations = function (selectedPanels) {

		// 	$ctrl.animationParametres = {};

		// 	// var params = {}

		// 	var i;
		// 	for (i = 0; i < selectedPanels.length; i++) {
		// 		var obj = {}
		// 		var i2;

		// 		for (i2 = 0; i2 < $ctrl.animationNameList.length; i2++) {

		// 			if (selectedPanels[i].html.search($ctrl.animationNameList[i2].value) != -1) {

		// 				var params = {}
		// 				params['classes'] = $ctrl.animationNameList[i2].classes;
		// 				params['infinite'] = false;
		// 				obj[$ctrl.animationNameList[i2].value] = params;

		// 				$ctrl.animationParametres[selectedPanels[i].cell] = obj;

		// 			}
		// 		}

		// 	}

		// };



		$ctrl.$onChanges = function (changes) {
			var initialData = changes.initialData;
			if (initialData && initialData.currentValue) {
				$ctrl.init(initialData.currentValue);
			}

		};

		$ctrl.$onDestroy = function () {

		};

		$ctrl.init = function (data) {
			$ctrl.elementAnimation = data.animation;
			$ctrl.gradientColor1 = data.textGradient1;
			$ctrl.gradientColor2 = data.textGradient2;


		};




		$scope.$watch('$ctrl.elementAnimation', function (newValue, oldValue) {

			if (newValue !== oldValue) {
				$rootScope.selectedRoot.removeClass(oldValue);
				$rootScope.selectedRoot.addClass(newValue);
			}

		});

		$scope.$watch('[$ctrl.gradientColor1, $ctrl.gradientColor2]', function (newValue, oldValue) {
		

			if (!oldValue[0] && !oldValue[1] && newValue[0] || !oldValue[0] && !oldValue[1] && newValue[1]) {
				$rootScope.selectedRoot.addClass('gradient-text-animation');

			}
			else if (newValue[0] && newValue[1]) {
				$rootScope.selectedRoot.css("background", 'linear-gradient(to right, ' + newValue[0] + ' 20%, ' + newValue[1] + ' 40%, ' + newValue[1] + ' 60%, ' + newValue[0] + ' 80%)');
				$rootScope.selectedRoot.css("text-fill-color", 'transparent');
				//    $rootScope.selectedRoot.css("webkit-background-clip", 'text');
				// 	  $rootScope.selectedRoot.css("background-clip", 'text');
				//    $rootScope.selectedRoot.css("webkit-background-size", '200% auto');
			}

		});


		$ctrl.cleareAnimationGradient = function () {
			$ctrl.gradientColor1 = undefined;
			$ctrl.gradientColor2 = undefined;
			$rootScope.selectedRoot.removeClass('gradient-text-animation');
			$rootScope.selectedRoot.css("background", 'inherit');
			$rootScope.selectedRoot.css("text-fill-color", 'inherit');

		};

		var animations = {
			"nd-animation_swing": {
				classes: "animated infinite swing",
				infinite: false
			},
			"nd-animation_lightSpeedIn": {
				classes: "animated infinite lightSpeedIn",
				infinite: false
			},
			"nd-animation_fadeInDown": {
				classes: "animated infinite fadeInDown",
				infinite: false
			},
			"nd-animation_jello": {
				classes: "animated infinite jello",
				infinite: false

			},
			"nd-animation_bounce": {
				classes: "animated infinite bounce",
				infinite: false

			},
			"nd-animation_tada": {
				classes: "animated infinite tada",
				infinite: false

			},
			"nd-animation_zoomIn": {
				classes: "animated infinite zoomIn",
				infinite: false

			},
			"nd-animation_bounceInLeft": {
				classes: "animated infinite bounceInLeft",
				infinite: false

			},
			"nd-animation_rollIn": {
				classes: "animated infinite rollIn",
				infinite: false

			},
			"nd-animation_flipInY": {
				classes: "animated infinite flipInY",
				infinite: false

			}
		};
		$ctrl.watchAnimation = function () {
			$ctrl.btnWatchAnimationDis = true;
			if (animations[$ctrl.elementAnimation]) {
				$('.' + $ctrl.elementAnimation).addClass(animations[$ctrl.elementAnimation].classes)
				// $('.' + $ctrl.elementAnimation).css("animation-name", 'lightSpeedIn');

				$timeout(function () {
					$('.' + $ctrl.elementAnimation).removeClass(animations[$ctrl.elementAnimation].classes);
					// $('.' + $ctrl.elementAnimation).css("animation", 'shine 7s linear infinite');
					$ctrl.btnWatchAnimationDis = false;
				}, 2000);
			}


		};




	},
	controllerAs: '$ctrl'
};
