import * as roosterjs from 'roosterjs';
import { PluginEventType } from 'roosterjs-editor-types';

import { getAllCSSStyleRules } from 'src/js/scripts';

export function TextEditorService(DebouncerService) {
	var that = this;

	// Init
	this.units = ['cm', 'mm', 'in', 'px', 'pt', 'pc', 'em', 'ex', 'ch', 'rem', '%'];
	this.unitsRegExp = new RegExp('\\d(' + this.units.join('|') + ')$', 'i');
	// [
	// 	'cm',
	// 	'mm',
	// 	'in',
	// 	'px',
	// 	'pt',
	// 	'p',
	// 	'em',
	// 	'ex',
	// 	'ch',
	// 	'rem',
	// 	'vw',
	// 	'vh',
	// 	'vmin',
	// 	'vmax',
	// 	'%'
	// ];

	this.editor = null;
	this.previousRange = null;
	this.debouncerInstance = DebouncerService.create();
	this.toolbarObservable = new signals.Signal();
	this.editorObservable = new signals.Signal();
	this.fontsObservable = new signals.Signal();

	this.getToolbarObservable = function () {
		return that.toolbarObservable;
	};

	this.getEditorObservable = function () {
		return that.editorObservable;
	};

	this.getFontsObservable = function () {
		return that.fontsObservable;
	};

	this.getUnits = function () {
		return that.units;
	};

	this.update = function (e, editor) {
		if (editor) {
			var range = editor.getSelectionRange();

			if (range) {
				var formatState = roosterjs.getFormatState(editor);
				var selectionProperties = that.composeProperties(editor, formatState, range);

				that.toolbarObservable.dispatch(editor, selectionProperties);

				// Check if html has change and request update
				that.requestHtmlUpdate(e, range, that.previousRange);
				that.previousRange = range;
			}
		}
	};

	this.enableToolbar = function (editor) {
		that.toolbarObservable.dispatch(editor, null, true);
	};

	this.disableToolbar = function () {
		that.toolbarObservable.dispatch(null, null, false);
	};

	this.getNodesInRange = function (range) {
		var startNode = range.startContainer.childNodes[range.startOffset] || range.startContainer; // check if it's a text node
		var endNode = range.endContainer.childNodes[range.endOffset] || range.endContainer;
		var nodes = [];

		if (startNode === null && typeof startNode === 'undefined') {
			return [];
		}

		if (startNode === endNode && startNode.childNodes.length === 0) {
			nodes.push(startNode);
		} else {
			do {
				nodes.push(startNode);
			} while ((startNode = that.getNextNode(startNode)) && startNode !== endNode);
		}

		return nodes;
	};

	this.composeProperties = function (editor, formatState, range) {
		var properties = formatState ? Object.assign({}, formatState) : {};
		var startElement = roosterjs.Position.getStart(range).element;
		var endElement = roosterjs.Position.getEnd(range).element;

		var startStyles = window.getComputedStyle(startElement);
		var endStyles = window.getComputedStyle(endElement);

		var nodes = that.getNodesInRange(range);
		var containsStartElement = false;
		var containsEndElement = false;
		var nodeStyles = [];
		var elements = [];

		// Composes the styles of child elements and check if containers styles need to be included
		for (var i = 0; i < nodes.length; i++) {
			if (nodes[i].nodeType === Node.ELEMENT_NODE && nodes[i] !== startElement && nodes[i] !== endElement) {
				elements.push({
					node: nodes[i],
					styles: window.getComputedStyle(nodes[i])
				});

				nodeStyles.push(window.getComputedStyle(nodes[i]));
			} else if (nodes[i].nodeType === Node.TEXT_NODE) {
				if (nodes[i].parentNode === startElement) {
					containsStartElement = true;
				} else if (nodes[i].parentNode === endElement) { // if endElement is the startElement, there is no need to include it
					containsEndElement = true;
				}
			}
		}

		// Add start elements if there are no child elements in range
		if (elements.length === 0) {
			containsStartElement = true;

			if (startElement !== endElement) {
				containsEndElement = true;
			}
		}

		if (containsStartElement) {
			elements.unshift({
				node: startElement,
				styles: startStyles
			});

			nodeStyles.push(startStyles);
		}

		if (containsEndElement) {
			elements.unshift({
				node: endElement,
				styles: endStyles
			});

			nodeStyles.push(endStyles);
		}

		properties.originalBackgroundColor = properties.backgroundColor;
		properties.fontSize = that.composeCommonProperty(elements, 'font-size', true);
		properties.fontName = that.composeCommonProperty(elements, 'font-family');
		properties.textAlign = that.composeCommonProperty(elements, 'text-align');
		properties.backgroundColor = that.composeBackgroundColor(editor, elements);
		properties.textColor = that.composeCommonProperty(elements, 'color');
		properties.lineHeight = that.composeCommonProperty(elements, 'line-height');

		if (properties.canUnlink) {
			var linkElement = that.getCommonLinkElement(editor, elements);

			if (linkElement !== null) {
				properties.linkElement = linkElement;
			}
		}

		return properties;
	};

	this.composeCommonProperty = function (elements, property, tryInlineStyles) {
		var propertyValue = '';
		var unitValues = {};

		if (elements.length > 0) {
			propertyValue = elements[0].styles[property];

			if (tryInlineStyles && elements[0].node.style[property]) {
				unitValues[elements[0].node.style[property]] = true;
			}
		}

		for (var i = 1; i < elements.length; i++) {
			if (tryInlineStyles && elements[i].node.style[property]) {
				unitValues[elements[i].node.style[property]] = true;
			}

			if (elements[i].styles[property] !== propertyValue) {
				propertyValue = '';
				break;
			}
		}

		if (propertyValue !== '') {
			var units = Object.keys(unitValues);

			if (units.length === 1 && that.unitsRegExp.test(units[0])) {
				propertyValue = units[0].toLowerCase();
			}
		}

		return propertyValue;
	};

	// this.composeCommonProperty = function (nodeStyles, property, elementProperty) {
	// 	var propertyValue = nodeStyles.length > 0 ? nodeStyles[0][property] : '';

	// 	for (var i = 1; i < nodeStyles.length; i++) {
	// 		if (nodeStyles[i][property] !== propertyValue) {
	// 			propertyValue = '';
	// 			break;
	// 		}
	// 	}

	// 	return propertyValue;
	// };

	this.composeBackgroundColor = function (editor, elements) {
		var backgroundElements = [];
		var backgroundColor = '';
		var ancestorColor = '';
		var isUnique = true;

		for (var i = 0; i < elements.length; i++) {
			if (!that.isTransparent(elements[i].styles['background-color'])) {
				if (backgroundColor !== '' && backgroundColor !== elements[i].styles['background-color']) {
					return '';
				} else {
					backgroundColor = elements[i].styles['background-color'];
					continue;
				}
			}

			isUnique = true;
			for (var j = 0; j < backgroundElements.length; j++) {
				if (backgroundElements[j].contains(elements[i].node)) {
					isUnique = false;
					break;
				}
			}

			if (isUnique) {
				backgroundElements.push(elements[i].node);
			}
		}

		for (i = 0; i < backgroundElements.length; i++) {
			ancestorColor = this.getAncestorBackgroundColor(editor, backgroundElements[i]);

			if (backgroundColor !== '' && backgroundColor !== ancestorColor) {
				return '';
			} else {
				backgroundColor = ancestorColor;
			}
		}

		return backgroundColor;
	};

	this.getAncestorBackgroundColor = function (editor, element) {
		var ancestor = element.parentNode;

		if (ancestor && editor.contains(ancestor)) {
			var styles = window.getComputedStyle(ancestor);

			if (that.isTransparent(styles['background-color'])) {
				return that.getAncestorBackgroundColor(editor, ancestor);
			}

			return styles['background-color'];
		}

		return 'rgba(0, 0, 0, 0)';
	};

	this.isTransparent = function (cssColor) {
		var color = new tinycolor(cssColor);
		return color.isValid() && color.getAlpha() === 0;
	};

	// URL logic
	this.getCommonLinkElement = function (editor, elements) {
		var linkElement = null;
		var hasCommonElement = false;

		var currentLinkElement = null;
		for (var i = 0; i < elements.length; i++) {
			if (/^a$/i.test(elements[i].node.tagName)) {
				currentLinkElement = elements[i].node;
			} else {
				currentLinkElement = that.getLinkAncestor(editor, elements[i].node);
			}

			if (linkElement === null) {
				linkElement = currentLinkElement;
			} else if (currentLinkElement !== null && currentLinkElement !== linkElement) {
				hasCommonElement = false;
				break;
			}

			if (linkElement !== null) {
				hasCommonElement = true;
			}
		}

		return hasCommonElement ? linkElement : null;
	};

	this.getLinkAncestor = function (editor, element) {
		var ancestor = element.parentNode;

		if (ancestor && editor.contains(ancestor)) {
			if (/^a$/i.test(ancestor.tagName)) {
				return ancestor;
			}

			return that.getLinkAncestor(editor, ancestor);
		}

		return null;
	};

	// ----------

	this.extractDriverFonts = function (container) {
		var fonts = {};

		container.find('style').each(function () {
			var cssRules = getAllCSSStyleRules(this.sheet.cssRules);

			var key = null;
			var fontFamily = null;
			for (var i = 0; i < cssRules.length; i++) {
				fontFamily = cssRules[i].style.fontFamily;

				if (fontFamily.length === 0) {
					continue;
				}

				key = fontFamily.replace(/'|"|,.*/g, '');
				if (!fonts[key] || fontFamily.length > fonts[key].length) {
					fonts[key] = fontFamily;
				}
			}
		});

		container.find('*').each(function () {
			var fontFamily = this.style.fontFamily;

			if (fontFamily.length === 0) {
				return;
			}

			var key = fontFamily.replace(/'|"|,.*/g, '');
			if (!fonts[key] || fontFamily.length > fonts[key].length) {
				fonts[key] = fontFamily;
			}
		});

		that.fontsObservable.dispatch(fonts);
	};

	this.requestHtmlUpdate = function (e, range, previousRange) {
		if (e.eventType === PluginEventType.ContentChanged) {
			that.editorObservable.dispatch();
		} else if (e.eventType === PluginEventType.KeyUp) {
			// if selection range was changed
			if (previousRange && previousRange.compareBoundaryPoints(Range.START_TO_END, range) !== 0) {
				that.debouncerInstance.debounce(500).then(function () {
					that.editorObservable.dispatch();
				});
			}
		}
	};

	this.getNextNode = function (node, skipChildren) {
		//if there are child nodes and we didn't come from a child node
		if (node.firstChild && !skipChildren) {
			return node.firstChild;
		}

		if (!node.parentNode) {
			return null;
		}

		return node.nextSibling || that.getNextNode(node.parentNode, true);
	};

	this.getParentTextNode = function (editor) {
		if (editor) {
			var range = editor.getSelectionRange();

			if (range) {
				var nodesInRange = this.getNodesInRange(range);
				var parent = nodesInRange[0].parentNode;

				if (parent.parentNode.tagName !== 'DIV') {
					parent = parent.parentNode;
				}

				return parent;
			}
		}
		return null;
	};
}
