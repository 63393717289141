import angular from 'angular';
import ngAria from 'angular-aria';
import ngAnimate from 'angular-animate';
import ngMaterial from 'angular-material';
import ngMessages from 'angular-messages';
import ngRoute from 'angular-route';
import constants from 'src/js/constants';

import 'md-color-picker';
import 'ng-infinite-scroll';
import '../css/styles.css';

import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { AppComponent } from './app.component';
import { TubeLoaderComponent } from './shared/iml-tube-loader/tube-loader.component';
import { IMLPageDialogComponent } from './shared/iml-page-dialog/iml-page-dialog.component';
import { LoginModule } from './login/login.module';
import { EditorModule } from './editor/editor.module.ajs';
import { NotFoundModule } from './not-found/not-found.module';
import { AuthService } from './services/auth.service';
import { NotifyService } from './services/notify.service';
import { DebouncerService } from './services/debouncer.service';
import { TextEditorService } from './services/text-editor.service';
import { DriverService } from './services/driver.service';
import { TemplateListService } from './services/template-list.service';
import { AssetsService } from './services/assets.service';
import { FileSizeFilter } from './shared/file-size.filter';
import { ExtendedDateFilter } from './shared/extended-date.filter';
import { IMLPageDialogRef } from './shared/iml-page-dialog/iml-page-dialog.service';
import { EditorDriverHeatmapsService } from './services/editor-driver-heatmaps.service';

export const AppModule = angular
  .module('CreateSnapApp', [
    ngAria,
    ngAnimate,
    ngMessages,
    ngMaterial,
    ngRoute,
    LoginModule,
    EditorModule,
    NotFoundModule,
    'mdColorPicker',
    'infinite-scroll'
  ])
  .controller('MainCtrl', function ($element, NotifyService, $timeout) {
    $timeout(function () {
      var parent = $element.find('.snackbar-container');
      NotifyService.setParent(parent[0]);
    }, 1000);
  })
  .directive(
    'ellyApp',
    downgradeComponent({ component: AppComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'imlTubeLoader',
    downgradeComponent({ component: TubeLoaderComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'imlPageDialog',
    downgradeComponent({ component: IMLPageDialogComponent }) as angular.IDirectiveFactory
  )
  .filter('fileSize', FileSizeFilter)
  .filter('extendedDate', ExtendedDateFilter)
  .service('AuthService', AuthService)
  .service('NotifyService', NotifyService)
  .service('EditorDriverHeatmapsService', EditorDriverHeatmapsService)
  .service('DebouncerService', DebouncerService)
  .service('TextEditorService', TextEditorService)
  .service('DriverService', DriverService)
  .service('AssetsService', AssetsService)
  .service('TemplateListService', TemplateListService)
  .factory('imlPageDialogRef', downgradeInjectable(IMLPageDialogRef))
  .directive('ngRightClick', function ($parse) {
    return function (scope, element, attrs) {
      var fn = $parse(attrs.ngRightClick);
      element.on('contextmenu', function (event) {
        scope.$apply(function () {
          event.preventDefault();
          event.stopPropagation();
          fn(scope, { $event: event });
        });
      });
    };
  })
  .directive('mdColorClear', function ($parse, $timeout) {
    return function (scope, element, attrs) {
      var fn = $parse(attrs.mdColorClear);
      $timeout(function () {
        $(element).find('.md-color-picker-clear').click(function (event) {
          fn(scope, { $event: event });
        });
      });
    };
  })
  .run(function (AuthService, $rootScope, $location) {
    AuthService.setState('login', true);
    AuthService.setState('main', false);
    $rootScope.$on('$locationChangeStart', function (event) {
      if (!/^\/login/.test($location.path()) && !/^\/not-found/.test($location.path())) {
        AuthService.setState($location.path(), false);
        if (!AuthService.isLoggedIn()) {
          event.preventDefault();
          $location.path('login');
        }
      }
    });
  })
  .config(['$httpProvider', function ($httpProvider) {
    $httpProvider.interceptors.push(function ($q, $injector) {
      return {
        'request': function (config) {
          var AuthService = $injector.get('AuthService');
          var access_token = AuthService.getAccessToken();
          var auth_required = config.url.search(constants.API_PATH) > -1
            || config.url.search(constants.AUTH_PATH + '/api/userinfo') > -1
            || config.url.search(constants.AUTH_PATH + '/oauth/token/revoke') > -1;
          if (auth_required) {
            if (access_token && !AuthService.hasTokenExpired()) {
              config.headers['Authorization'] = 'Bearer ' + access_token;
            } else {
              AuthService.signout();
            }
          }
          return $q.resolve(config);
        },
        'responseError': function (error) {
          var config = error.config;
          var auth_required = config.url.search(constants.API_PATH) > -1
            || config.url.search(constants.AUTH_PATH + '/api/userinfo') > -1;
          if (auth_required && error.status === 401) {
            var AuthService = $injector.get('AuthService');
            AuthService.signout();
          }
          return $q.reject(error);
        }
      };
    });
  }])
  .config(['$routeProvider', '$locationProvider', function ($routeProvider, $locationProvider) {
    $routeProvider.when('/', {
      redirectTo: '/editor'
    })
      .when('/editor', {
        template: '<editor></editor>'
      })
      .when('/login', {
        template: '<elly-login></elly-login>'
      })
      .when('/not-found', {
        template: '<not-found></not-found>'
      })
      .otherwise({
        redirectTo: '/not-found'
      });
    $locationProvider.html5Mode(true);
  }])
  .config(['$compileProvider', function ($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|file|blob):|data:image/);
  }])
  .config(['$mdThemingProvider', function ($mdThemingProvider) {
    $mdThemingProvider.definePalette('imlLightPalette', {
      '50': 'e8f9f6',
      '100': 'c5f1e9',
      '200': '9fe8db',
      '300': '78dfcc',
      '400': '5bd8c1',
      '500': '3ed1b6',
      '600': '38ccaf',
      '700': '30c6a6',
      '800': '28c09e',
      '900': '1bb58e',
      'A100': 'ebfffa',
      'A200': 'b8ffec',
      'A400': '85ffde',
      'A700': '6bffd7',
      'contrastDefaultColor': 'light', // whether, by default, text (contrast)
      // on this palette should be dark or light
      'contrastDarkColors': ['50', '100', //hues which contrast should be 'dark' by default
        '200', '300', '400', 'A100'],
      'contrastLightColors': undefined // could also specify this if default was 'dark'
    });
    $mdThemingProvider.definePalette('imlOrangePalette', {
      '50': 'fef6eb',
      '100': 'fce9cd',
      '200': 'fadaab',
      '300': 'f8cb89',
      '400': 'f7c070',
      '500': 'f5b557',
      '600': 'f4ae4f',
      '700': 'f2a546',
      '800': 'f09d3c',
      '900': 'ee8d2c',
      'A100': 'ffffff',
      'A200': 'fffcfa',
      'A400': 'ffe2c7',
      'A700': 'ffd5ad',
      'contrastDefaultColor': 'light', // whether, by default, text (contrast)
      // on this palette should be dark or light
      'contrastDarkColors': ['50', '100', //hues which contrast should be 'dark' by default
        '200', '300', '400', 'A100'],
      'contrastLightColors': undefined // could also specify this if default was 'dark'
    });
    $mdThemingProvider.definePalette('imlDarkPalette', {
      '50': 'f8e8ee',
      '100': 'ecc7d4',
      '200': 'e0a1b7',
      '300': 'd47b9a',
      '400': 'ca5f84',
      '500': 'c1436e',
      '600': 'bb3d66',
      '700': 'b3345b',
      '800': 'ab2c51',
      '900': '9e1e3f',
      'A100': 'ffd7e0',
      'A200': 'ffa4b9',
      'A400': 'ff7192',
      'A700': 'ff587e',
      'contrastDefaultColor': 'light', // whether, by default, text (contrast)
      // on this palette should be dark or light
      'contrastDarkColors': ['50', '100', //hues which contrast should be 'dark' by default
        '200', '300', '400', 'A100'],
      'contrastLightColors': undefined // could also specify this if default was 'dark'
    });
    $mdThemingProvider
      .theme('default')
      .primaryPalette('imlLightPalette')
      .accentPalette('imlLightPalette');
    $mdThemingProvider
      .theme('#FFFFFF')
      .primaryPalette('imlLightPalette')
      .accentPalette('imlLightPalette');
    $mdThemingProvider
      .theme('dark')
      .primaryPalette('imlDarkPalette')
      .accentPalette('imlDarkPalette')
      .dark();
  }])
  .name;