import Vibrant from 'node-vibrant';

import template from './editor-elements.component.html';
import './editor-elements.component.css';

export const EditorElementsComponent = {
	template: template,
	bindings: {
		initialData: '<',
		backgroundData: '<',
		elementsData: '<',
		isFirstPanel: '<',
		isLastPanel: '<',
		navigationType: '<',
		onUpdate: '<'
	},
	controller: function ($timeout, NotifyService) {
		var $ctrl = this;

		$ctrl.$onInit = function () {
			$ctrl.colorPickerOptions = {
				// label: "Choose a color",
				// icon: "brush",
				materialPalette: false,
				hsl: false,
				openOnInput: true,
				history: false
			};

			$ctrl.learnColorType = 'manual';
			$ctrl.learnDefault = {
				color: '#3ED1B6',
				text: 'Learn More',
				url: 'https://newsdriver.com'
			};

			$ctrl.arrowDefault = {
				color: '#FFFFFF',
				stroke: '',
				shadow: '#000000'
			};

			var currentElems = $ctrl.elementsData;
			var learnButton = currentElems && currentElems.learnButton ? currentElems.learnButton : null;
			var arrows = currentElems && currentElems.arrows ? currentElems.arrows : null;
			$timeout(function () {
				$ctrl.setLearnButton(learnButton);
				$ctrl.setNavigationArrows(arrows);
			});

			$ctrl.paletteInProgress = false;
		};

		$ctrl.$onChanges = function (changes) {
			var backgroundData = changes.backgroundData;
			var elementsData = changes.elementsData;

			if (backgroundData) {
				$ctrl.palette = [];
			}

			if (elementsData && !elementsData.isFirstChange()) {
				var currentElems = elementsData.currentValue;
				var learnButton = currentElems && currentElems.learnButton ? currentElems.learnButton : null;
				var arrows = currentElems && currentElems.arrows ? currentElems.arrows : null;

				$ctrl.setLearnButton(learnButton);
				$ctrl.setNavigationArrows(arrows);
			}
		};

		$ctrl.update = function () {
			var elements = null;

			if ($ctrl.onUpdate) {
				if ($ctrl.learnButtonEnabled) {
					if (!elements) {
						elements = {};
					}

					elements.learnButton = {
						color: $ctrl.learnButtonColor,
						text: $ctrl.learnButtonText,
						url: $ctrl.learnButtonURL
					};
				} else {
					$ctrl.learnButtonColor = $ctrl.learnDefault.color;
					$ctrl.learnButtonText = $ctrl.learnDefault.text;
					$ctrl.learnButtonURL = $ctrl.learnDefault.url;
				}

				// Arows
				if (!$ctrl.isFirstPanel && $ctrl.leftArrowEnabled) {
					if (!elements) {
						elements = {};
					}

					elements.arrows = {
						left: {
							color: $ctrl.leftArrowColor,
						}
					};

					if ($ctrl.leftArrowStroke.length > 0) {
						elements.arrows.left.stroke = $ctrl.leftArrowStroke;
					}

					if ($ctrl.leftArrowShadow.length > 0) {
						elements.arrows.left.shadow = $ctrl.leftArrowShadow;
					}
				}

				if (!$ctrl.isLastPanel && $ctrl.rightArrowEnabled) {
					if (!elements) {
						elements = {};
					}

					if (!elements.arrows) {
						elements.arrows = {};
					}

					elements.arrows.right = {
						color: $ctrl.rightArrowColor
					};

					if ($ctrl.rightArrowStroke.length > 0) {
						elements.arrows.right.stroke = $ctrl.rightArrowStroke;
					}

					if ($ctrl.rightArrowShadow.length > 0) {
						elements.arrows.right.shadow = $ctrl.rightArrowShadow;
					}
				}

				$ctrl.onUpdate(elements);
			}
		};

		$ctrl.toggleLearnButton = function () {
			$ctrl.learnButtonEnabled = !$ctrl.learnButtonEnabled;
		};

		$ctrl.toggleLeftArrow = function () {
			$ctrl.leftArrowEnabled = !$ctrl.leftArrowEnabled;
			if ($ctrl.leftArrowEnabled) {
				if ($ctrl.leftArrowShadow.length === 0) {
					$ctrl.leftArrowStroke = $ctrl.arrowDefault.stroke;
				}

				if ($ctrl.leftArrowShadow.length === 0) {
					$ctrl.leftArrowShadow = $ctrl.arrowDefault.shadow;
				}
			}
		};

		$ctrl.toggleRightArrow = function () {
			$ctrl.rightArrowEnabled = !$ctrl.rightArrowEnabled;
			if ($ctrl.rightArrowEnabled) {
				if ($ctrl.rightArrowShadow.length === 0) {
					$ctrl.rightArrowStroke = $ctrl.arrowDefault.stroke;
				}

				if ($ctrl.rightArrowShadow.length === 0) {
					$ctrl.rightArrowShadow = $ctrl.arrowDefault.shadow;
				}
			}
		};

		$ctrl.setLearnButton = function (learnButton) {
			if (learnButton) {
				$ctrl.learnButtonEnabled = true;
				$ctrl.learnButtonColor = learnButton.color ? learnButton.color : $ctrl.learnDefault.color;
				$ctrl.learnButtonText = learnButton.text ? learnButton.text : $ctrl.learnDefault.text;
				$ctrl.learnButtonURL = learnButton.url ? learnButton.url : $ctrl.learnDefault.url;
			} else {
				$ctrl.learnButtonEnabled = false;
				$ctrl.learnButtonColor = $ctrl.learnDefault.color;
				$ctrl.learnButtonText = $ctrl.learnDefault.text;
				$ctrl.learnButtonURL = $ctrl.learnDefault.url;
			}
		};

		$ctrl.setNavigationArrows = function (arrows) {
			if (arrows) {
				var leftArrow = arrows.left;
				var rightArrow = arrows.right;

				if (arrows.left) {
					$ctrl.leftArrowEnabled = true;
					$ctrl.leftArrowColor = leftArrow.color ? leftArrow.color : $ctrl.arrowDefault.color;
					$ctrl.leftArrowStroke = leftArrow.stroke ? leftArrow.stroke : '';
					$ctrl.leftArrowShadow = leftArrow.shadow ? leftArrow.shadow : '';
				} else {
					$ctrl.leftArrowEnabled = false;
					$ctrl.leftArrowColor = $ctrl.arrowDefault.color;
					$ctrl.leftArrowStroke = $ctrl.arrowDefault.stroke;
					$ctrl.leftArrowShadow = $ctrl.arrowDefault.shadow;
				}

				if (arrows.right) {
					$ctrl.rightArrowEnabled = true;
					$ctrl.rightArrowColor = rightArrow.color ? rightArrow.color : $ctrl.arrowDefault.color;
					$ctrl.rightArrowStroke = rightArrow.stroke ? rightArrow.stroke : '';
					$ctrl.rightArrowShadow = rightArrow.shadow ? rightArrow.shadow : '';
				} else {
					$ctrl.rightArrowEnabled = false;
					$ctrl.rightArrowColor = $ctrl.arrowDefault.color;
					$ctrl.rightArrowStroke = $ctrl.arrowDefault.stroke;
					$ctrl.rightArrowShadow = $ctrl.arrowDefault.shadow;
				}
			} else {
				$ctrl.leftArrowEnabled = false;
				$ctrl.leftArrowColor = $ctrl.arrowDefault.color;
				$ctrl.leftArrowStroke = $ctrl.arrowDefault.stroke;
				$ctrl.leftArrowShadow = $ctrl.arrowDefault.shadow;

				$ctrl.rightArrowEnabled = false;
				$ctrl.rightArrowColor = $ctrl.arrowDefault.color;
				$ctrl.rightArrowStroke = $ctrl.arrowDefault.stroke;
				$ctrl.rightArrowShadow = $ctrl.arrowDefault.shadow;
			}
		};

		$ctrl.setLearnColor = function (color) {
			$ctrl.learnButtonColor = color.toRgbString();
		};

		$ctrl.getPalette = function () {
			if ($ctrl.backgroundData === null || typeof $ctrl.backgroundData === 'undefined') {
				return;
			}

			var imageUrl = $ctrl.backgroundData.backgroundImage;
			if (imageUrl === null || typeof imegeUrl !== 'undefined') {
				return;
			}

			imageUrl = imageUrl.replace(/^https/, 'http');
			$ctrl.paletteInProgress = true;
			Vibrant.from(imageUrl)
				.useQuantizer(Vibrant.Quantizer.WebWorker)
				.quality(1)
				.getPalette()
				.then(function (palette) {
					$timeout(function () {
						var swatches = Object.keys(palette);
						var swatch = null;

						$ctrl.palette = [];
						$ctrl.paletteInProgress = false;
						for (var i = 0; i < swatches.length; i++) {
							swatch = swatches[i];

							var s = palette[swatch];
							if (s !== null) {
								var color = new tinycolor({ r: s.r, g: s.g, b: s.b });
								if (swatch === 'Vibrant') {
									$ctrl.palette.unshift(color);
								} else {
									$ctrl.palette.push(color);
								}
							}
						}
					});
				})
				.catch(function () {
					$ctrl.paletteInProgress = false;
					NotifyService.notify("Wasn't able to generate a palette.", true);
				});
		};
	},
	controllerAs: '$ctrl'
};
