export function NdAssetsExtractorService($http) {

	this.requestJob = function (url) {
		var headers = {
			'Content-Type': 'application/json'
		};
		var data = {
			url: url
		};
		return $http.post(constants.API_PATH + '/api/jobs/extractors/add', data, { headers: headers });
	};

	this.collectJob = function (data) {
		return $http.get(constants.API_PATH + '/api/jobs/extractors/get', {
			params: { jobId: data.jobId }
		})
	};

}

