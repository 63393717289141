import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DialogCreateComponent } from './dialog-create/dialog-create.component';

@Injectable({
  providedIn: 'root'
})
export class EditorDialogsService {
  constructor(public dialog: MatDialog) { }

  openDialog() {
    const dialogRef = this.dialog.open(DialogCreateComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`, result, typeof result);
    });
  }
}
