import template from './nd-assets-extractor.component.html';
import './nd-assets-extractor.component.css';

var PENDING = 'PENDING';
var IMAGE = 'IMAGE';
var extractorResults = 'extractorResults';
var notify = "Wasn't able to extract the assets. Please, try again later.";
var successNotify = "Assets were successfuly extracted from '";
var dot = "'.";

export const NdAssetsExtractorComponent = {
	template: template,
	bindings: {
		hideCloseButton: '<',
		importedAssets: '<',
		onExtract: '<',
		onAfterClose: '<'
	},
	controller: function ($element, $timeout, NdAssetsExtractorService, NotifyService) {
		var $ctrl = this;

		this.$onInit = function () {
			$ctrl.loadingInProgress = $ctrl.loadingInProgress ? $ctrl.loadingInProgress : false;
			$ctrl.websiteUrl = '';
			$ctrl.extractorState = 'login';

			$timeout(function () {
				$element.children('.nd-driver-list-cont').removeClass('nd-list-hidden');
			}, 500);
		};

		this.$onChanges = function (changes) {
			if (changes.importedAssets && changes.importedAssets.currentValue) {
				$ctrl.loadingInProgress = true;
				$ctrl.hideDescription = true;

				$timeout(function () {
					$ctrl.loadingInProgress = false;
					$ctrl.hideDescription = false;

					$ctrl.extractedAssets = changes.importedAssets.currentValue;
					$ctrl.websiteUrl = $ctrl.extractedAssets.url;
					$ctrl.extractorState = extractorResults;
				}, 1000);
			}
		};

		this.extractAssets = function () {

			$ctrl.loadingInProgress = true;

			NdAssetsExtractorService
				.requestJob($ctrl.websiteUrl).then(function (response) {

					if (response) {
						setTimeout(function getRes() {

							NdAssetsExtractorService
								.collectJob(response.data).then(function (secondRes) {

									if (secondRes.data.status === PENDING) {

										setTimeout(getRes, 5000);

									} else {

										$ctrl.loadingInProgress = false;
										$ctrl.extractorState = extractorResults;
										$ctrl.extractedAssets = secondRes.data;
										$ctrl.extractedAssets.url = $ctrl.websiteUrl;

										$ctrl.extractedAssets.data.images = $ctrl.extractedAssets.data.images
											.map(function (item) {
												return {
													type: IMAGE,
													name: item.url,
													preview_url: item.url,
													url: item.url,
													optimized: true,
													locked: true
												};
											});

										var color = null;

										for (var i = 0; i < $ctrl.extractedAssets.data.palette.length; i++) {
											color = new tinycolor($ctrl.extractedAssets.data.palette[i].rgb);
											$ctrl.extractedAssets.data.palette[i].isDark = color.isDark();
										}

										if ($ctrl.onExtract) {
											$ctrl.onExtract($ctrl.extractedAssets.data);
										}

										NotifyService.notify(successNotify + $ctrl.websiteUrl + dot);

									}
								})
								.catch(function () {
									$ctrl.loadingInProgress = false;
									NotifyService.notify(notify, true);
								})

						}, 20000);

					}
				})
		};



		this.copyToClipboard = function (value, name) {
			var input = $('<input style="position: fixed; transform: translateX(-100%)">');

			input.attr('value', value);
			document.body.appendChild(input[0]);
			input.select();

			var success = document.execCommand('copy');
			if (success) {
				NotifyService.notify('Asset ' + name + ' was copied to clipboard.');
			} else {
				NotifyService.notify('Failed to copy ' + name + ' to clipboard.', true);
			}

			input.remove();
		};

		this.openMenu = function ($mdMenu, event, asset) {
			if (!asset || asset.progress !== 'indeterminate') {
				var elem = event.target;
				var rect = elem.getBoundingClientRect();

				$ctrl.menu_offset = event.clientX - rect.left + ' ' + (event.clientY - rect.top);
				$timeout(function () {
					$mdMenu.open(event);
				});
			}
		};

		this.downloadAsset = function (url, name) {
			var link = $('<a style="position: fixed; transform: translateX(-100%)"></a>');

			link.attr('download', name);
			link.attr('href', url);

			document.body.appendChild(link[0]);
			link[0].click();

			link.remove();
		};

		this.downloadFile = function (asset) {
			NotifyService.notify('Download started.');

			$ctrl.downloadAsset(asset.url, asset.name);
		};

		this.closeDialog = function () {
			$element.children('.nd-driver-list-cont').addClass('nd-list-hidden');

			$timeout(function () {
				if ($ctrl.onAfterClose) {
					$ctrl.onAfterClose();
				}
			}, 200);
		};
	},
	controllerAs: '$ctrl'
};
