import template from './not-found.component.html';
import './not-found.component.css';

export const NotFoundComponent = {
	template: template,
	controller: function () {
		var $ctrl = this;

		$ctrl.$onInit = function () {
			// init
		};
	},
	controllerAs: '$ctrl'
};
