import { AppModule as moduleName } from './app.module.ajs';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UpgradeModule } from '@angular/upgrade/static';

import { MatButtonModule, MatChipsModule, MatProgressSpinnerModule } from '@angular/material';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';

import { OverlayContainer } from '@angular/cdk/overlay';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { CodeEditorComponent } from './editor/code-editor/code-editor.component';
import { EditorStateService } from './editor/shared/editor-state.service';
import { SharedModule } from './shared/shared.module';
import { TubeLoaderComponent } from './shared/iml-tube-loader/tube-loader.component';
import { EditorDialogsModule } from './editor/editor-dialogs/editor-dialogs.module';
import { DialogConfirmComponent } from './editor/editor-dialogs/dialog-confirm/dialog-confirm.component';
import { DialogPromptComponent } from './editor/editor-dialogs/dialog-prompt/dialog-prompt.component';
import { DialogCreateComponent } from './editor/editor-dialogs/dialog-create/dialog-create.component';
import { DialogEditBannerComponent } from './editor/editor-dialogs/dialog-edit-banner/dialog-edit-banner.component';
import { authServiceProvider, notifyServiceProvider, editorDriverHeatmapsServiceProvider } from './ajs-upgraded-providers';
import { TokenInterceptor } from './core/auth/token.interceptor';
import { IMLPageDialogComponent } from './shared/iml-page-dialog/iml-page-dialog.component';
import { PageDriverListComponent } from './editor/editor-dialogs/page-driver-list/page-driver-list.component';
import { EditorDriverHeatmapsComponent } from './editor/editor-dialogs/editor-driver-heatmaps/editor-driver-heatmaps.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    UpgradeModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatTooltipModule,
    MatToolbarModule,
    EditorDialogsModule,
    SharedModule,
    FormsModule
  ],
  declarations: [
    AppComponent,
    CodeEditorComponent
  ],
  entryComponents: [
    AppComponent,
    CodeEditorComponent,
    TubeLoaderComponent,
    IMLPageDialogComponent,
    PageDriverListComponent,
    EditorDriverHeatmapsComponent,
    // Dialogs
    DialogConfirmComponent,
    DialogPromptComponent,
    DialogCreateComponent,
    DialogEditBannerComponent
  ],
  providers: [
    EditorStateService,
    authServiceProvider,
    notifyServiceProvider,
    editorDriverHeatmapsServiceProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: MatDialogRef,
      useValue: {}
    }
  ]
})
export class AppModule {
  constructor(private upgrade: UpgradeModule, private overlayContainer: OverlayContainer) {
    overlayContainer.getContainerElement().classList.add('nd-light-mint-theme');
  }
  ngDoBootstrap() {
    this.upgrade.bootstrap(document.body, [moduleName], { strictDi: false });
  }
}