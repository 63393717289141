import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatDialogModule,
  MatButtonModule,
  MatSelectModule,
  MatInputModule,
  MatRadioModule,
  MatDividerModule,
  MatMenuModule,
  MatIconModule,
  MatButtonToggleModule,
  MatProgressBarModule,
  MatTooltipModule,
  MatAutocompleteModule,
  MatProgressSpinnerModule
} from '@angular/material';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { DialogPromptComponent } from './dialog-prompt/dialog-prompt.component';
import { DialogCreateComponent } from './dialog-create/dialog-create.component';
import { DialogEditBannerComponent } from './dialog-edit-banner/dialog-edit-banner.component';
import { PageDriverListComponent } from './page-driver-list/page-driver-list.component';
import { EditorDriverHeatmapsComponent } from './editor-driver-heatmaps/editor-driver-heatmaps.component';
import { PageStoryItemComponent } from './page-driver-list/page-story-item/page-story-item.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatButtonModule,
    MatMenuModule,
    MatRadioModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatDividerModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    SharedModule,
    FormsModule
  ],
  exports: [],
  declarations: [
    DialogConfirmComponent,
    DialogPromptComponent,
    DialogCreateComponent,
    DialogEditBannerComponent,
    PageDriverListComponent,
    EditorDriverHeatmapsComponent,
    PageStoryItemComponent
  ],
  providers: [],
})
export class EditorDialogsModule { }