import { Component, OnInit } from '@angular/core';
import { LazyService } from './core/lazy.service';

import { EditorStateService } from './editor/shared/editor-state.service';

@Component({
  selector: 'elly-app',
  template: require('./app.component.html'),
  styles: [require('./app.component.a.css')]
})
export class AppComponent implements OnInit {
  constructor(private lazy: LazyService, private stateService: EditorStateService) { }

  ngOnInit() {
    this.lazy.waitForEditor().then(() => {});
  }
}
