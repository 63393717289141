import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {
  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  copy(value: string): boolean {
    const input = this.renderer.createElement('input');

    this.renderer.setStyle(input, 'position', 'fixed');
    this.renderer.setStyle(input, 'transform', 'translateX(-100%)');

    this.renderer.setProperty(input, 'value', value);
    this.renderer.appendChild(document.body, input);
    input.select();

    const success = document.execCommand('copy');
    this.renderer.removeChild(document.body, input);

    return success;
  }
}
