export function AssetsService($http) {
	this.list = function () {
		return $http.get(constants.API_PATH + '/api/assets', { params: { company_id: 1 } });
	};

	this.addToBlacklist = function (asset_ids) {
		var headers = {
			'Content-Type': 'application/json'
		};

		var data = {
			asset_ids: asset_ids
		};

		return $http.put(constants.API_PATH + '/api/assets/blacklist/add', data, { headers: headers });
	};

	this.removeFromBlacklist = function (asset_ids) {
		var headers = {
			'Content-Type': 'application/json'
		};

		var data = {
			asset_ids: asset_ids
		};

		return $http.put(constants.API_PATH + '/api/assets/blacklist/remove', data, { headers: headers });
	};

	this.sign = function (entity, assets, generate_keys) {
		var headers = {
			'Content-Type': 'application/json'
		};

		var data = {
			entity: entity,
			assets: assets,
			generate_keys: generate_keys
		};

		return $http.post(constants.API_PATH + '/api/assets/sign', data, { headers: headers });
	};

	this.post = function (entity, assets) {
		var headers = {
			'Content-Type': 'application/json'
		};

		var data = {
			entity: entity,
			assets: assets
		};

		return $http.post(constants.API_PATH + '/api/assets', data, { headers: headers });
	};

	this.put = function (entity, asset) {
		var headers = {
			'Content-Type': 'application/json'
		};

		var data = {
			entity: entity,
			asset: asset
		};

		return $http.put(constants.API_PATH + '/api/assets', data, { headers: headers });
	};

	this.copy = function (entity, dest_entity, asset_ids) {
		var headers = {
			'Content-Type': 'application/json'
		};

		var data = {
			dest_entity: dest_entity,
			entity: entity,
			asset_ids: asset_ids
		};

		return $http.put(constants.API_PATH + '/api/assets/copy', data, { headers: headers });
	};

	this.delete = function (entity, asset_ids) {
		var headers = {
			'Content-Type': 'application/json'
		};

		return $http.delete(constants.API_PATH + '/api/assets', {
			headers: headers,
			data: {
				entity: entity,
				asset_ids: asset_ids
			}
		});
	};

	this.import = function (url) {
		return $http.get(constants.API_PATH + '/api/assets/scrape', {
			params: { url: url }
		});
	};
}
