import template from './driver-panel.component.html';
import './driver-panel.component.css';

export const DriverPanelComponent = {
	template: template,
	bindings: {
		select: '<',
		isActive: '<',
		panelIndex: '<',
		panelHtml: '<'
	},
	controller: function ($sce) {
		var $ctrl = this;

		$ctrl.$onChanges = function (changes) {
			var html = changes.panelHtml;

			if (html && html.currentValue && html.previousValue != html.currentValue) {
				$ctrl.initPreview(html.currentValue);
			}
		};

		$ctrl.initPreview = function (html) {
			$ctrl.safe_html = $sce.trustAsHtml(html);
		};
	},
	controllerAs: '$ctrl'
};
