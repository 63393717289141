import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { IMLPageDialogRef } from './iml-page-dialog.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'iml-page-dialog',
  template: require('./iml-page-dialog.component.html'),
  styles: [require('./iml-page-dialog.component.a.css')],
  encapsulation: ViewEncapsulation.None
})
export class IMLPageDialogComponent implements OnInit, AfterViewInit {
  @Input() open: boolean;
  @Input() instant: boolean;
  @Input() openAnimation: string;

  public afterClosed = new Subject<void>();

  private initialized = false;

  private get isInstant() { return this.instant; }
  private get isOpen() { return this.initialized && this.open; }

  constructor(private dialogRef: IMLPageDialogRef) { }

  ngOnInit() {
    this.dialogRef.setRef(this);

    if (this.isInstant) {
      this.initialized = true;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initialized = true;
    }, 100);

    if (this.isInstant) {
      this.instant = false;
    }
  }

  close() {
    this.open = false;

    if (this.isInstant) {
      this.afterClosed.next();
    } else {
      setTimeout(() => this.afterClosed.next(), 200);
    }
  }
}
