import template from './editor.component.html';
import './editor.component.css';

import Sortable from 'sortablejs';

export const EditorComponent = {
	template: template,
	controller: function (NotifyService, DriverService, TemplateListService, AssetsService, EditorDriverDetailsService, TextEditorService,
		editorStateService, $timeout, $element, $mdDialog, $rootScope) {
		var $ctrl = this;

		$ctrl.colorPickerOptions = {
			materialPalette: false,
			hsl: false,
			openOnInput: true,
			history: false,
			clickOutsideToClose: true,
			disableClose: false
		};


		// this.sortableConf = {
		// 	animation: 350,
		// 	chosenClass: 'sortable-chosen',
		// 	handle: '.grab-handle',
		// 	forceFallback: true,

		// };

		$ctrl.showListDialog = function () {
			if ($ctrl.selected_driver) {
				$ctrl.hideCloseButton = false;
			} else {
				$ctrl.hideCloseButton = true;
			}

			$ctrl.showDriverList = true;
			$ctrl.sf = false;
			$ctrl.showAssetsExtractor = false;
			$ctrl.createDriverAfterExtraction = false;
		};

		$ctrl.hideListDialog = function () {
			$ctrl.showDriverList = false;
		};

		$ctrl.$onInit = function () {
			// first dialog load
			$ctrl.instantDialog = true;
			$timeout(function () {
				$ctrl.instantDialog = false;
			}, 1000);
			// -----------------

			$ctrl.editorState = editorStateService;
			$ctrl.editorState.subscribe(function (value) {
				$ctrl.selected_driver = value.driver;
				$ctrl.selectPanel(value.selectedPanelIndex);

				$ctrl.hasChanges = true;
			});

			$ctrl.sf = false;
			$ctrl.showDriverList = true;
			$ctrl.hideCloseButton = true;
			$ctrl.openExtractor = false;
			$ctrl.backgroundData = {};

			$ctrl.assets = {};
			$ctrl.hasChanges = false;
			$ctrl.editor_theme = 'default';
			$ctrl.properties_mode = true;
			$ctrl.isDeviceLandscape = false;




			TemplateListService.get().then(function (response) {
				var templates = response.data;

				$ctrl.templates = templates.main;
			}).catch(function (error) {
				NotifyService.notify(error.message, true);
			});

			AssetsService.list().then(function (response) {
				var assets = response.data.assets;

				$ctrl.assets.global = assets.global;
				$ctrl.assets.blacklist = assets.blacklist;
				$ctrl.assets.saved = assets.saved;

				$ctrl.assets = angular.copy($ctrl.assets);
			}).catch(function (error) {
				NotifyService.notify(error.message, true);
			});

			// Temporary solution
			$ctrl.locationListener = $rootScope.$on('$locationChangeStart', function (event) {
				var message = 'Leave site?\nChanges you made may not be saved.';

				if ($ctrl.hasChanges && window.confirm(message) === true) {
					event.preventDefault();
				}
			});

			window.addEventListener('beforeunload', $ctrl.beforeUnloadListener);
		};

		$ctrl.$onDestroy = function () {
			$ctrl.locationListener();
			window.removeEventListener('beforeunload', $ctrl.beforeUnloadListener);
		};

		// Listeners

		$ctrl.beforeUnloadListener = function (event) {
			if ($ctrl.hasChanges) {
				event.preventDefault();

				event.returnValue = true;
			}
		};

		//#region Preview

		$ctrl.setPreviewModel = function (modelName) {
			$ctrl.previewModel = modelName;
		};

		$ctrl.setModelSize = function (width, height) {
			$ctrl.modelSize = {
				width: width,
				height: height
			};

			// $timeout($ctrl.setPreviewSize);
		};

		$ctrl.setPreviewSize = function (previewSize) {
			$ctrl.previewSize = previewSize;

			if ($ctrl.previousPreviewSize === 'auto') {
				$(window).off('resize', $ctrl.resizeModelPreview);
			}

			if ($ctrl.previewSize === 'auto') {
				$ctrl.resizeModelPreview();
				$(window).on('resize', $ctrl.resizeModelPreview);
			} else {
				var scale = parseInt($ctrl.previewSize) / 100;
				var container = $element.find('.snap-phone-cont')[0];
				var phone = $element.find('.snap-phone')[0];

				$ctrl.setPreviewPhone(phone, container, scale);
			}

			$ctrl.previousPreviewSize = $ctrl.previewSize;
		};

		$ctrl.resizeModelPreview = function () {
			var container = $element.find('.snap-phone-cont')[0];
			var phone = $element.find('.snap-phone')[0];

			if (phone) {
				var scaleX = (container.offsetWidth - 50) / phone.offsetWidth;
				var scaleY = (container.offsetHeight - 100) / phone.offsetHeight;
				var scale = scaleX < scaleY ? scaleX : scaleY;

				$ctrl.setPreviewPhone(phone, container, scale);
			}
		};

		$ctrl.changeDeviceOrientation = function () {
			$ctrl.isDeviceLandscape = !$ctrl.isDeviceLandscape;
		};

		$ctrl.setPreviewPhone = function (phone, container, scale) {
			var translateX = '-50%';
			var translateY = '-50%';
			var scrollX = false;
			var scrollY = false;

			if (phone.offsetWidth * scale > container.offsetWidth) {
				$(phone).css('left', phone.offsetWidth * (scale - 1) / 2);
				translateX = '0';

				scrollX = true;
			} else {
				$(phone).css('left', '50%');
			}

			if (phone.offsetHeight * scale > container.offsetHeight) {
				$(phone).css('top', phone.offsetHeight * (scale - 1) / 2);
				translateY = '0';

				scrollY = true;
			} else {
				$(phone).css('top', '50%');
			}

			$(phone).css('transform', 'translate(' + translateX + ', ' + translateY + ') scale(' + scale + ')');
			if (scrollX) {
				$(container).scrollLeft((container.scrollWidth - container.offsetWidth) / 2);
			}

			if (scrollY) {
				$(container).scrollTop((container.scrollHeight - container.offsetHeight) / 2);
			}
		};

		//#endregion

		$ctrl.onPanelsSort = function (e) {
			$timeout(function () {
				$ctrl.hasChanges = true;
				$ctrl.selected_panel_index = e.newIndex;
				$ctrl.selected_driver.panels.splice(e.newIndex, 0, $ctrl.selected_driver.panels.splice(e.oldIndex, 1)[0]);

				$ctrl.editorState.push($ctrl.selected_driver, $ctrl.selected_panel_index);
			});
		};

		$ctrl.checkDriverOptimization = function (unoptimized) {
			$ctrl.isDriverOptimized = !unoptimized;
		};

		$ctrl.onAssetsAction = function (message, action) {
			NotifyService.notify(message, false, action);
		};

		$ctrl.onAssetsError = function (message, action) {
			NotifyService.notify(message, true, action);
		};

		$ctrl.toggleHtml = function (event) {
			var button = $(event.target);

			if (!button.hasClass('md-button')) {
				button = button.parent();
			}

			button.toggleClass('active');
			button.blur();

			if ($ctrl.html_mode) {
				$ctrl.properties_mode = true;
				$timeout(function () {
					$ctrl.html_mode = false;
					$ctrl.editor_theme = 'default';

					// $ctrl.setPreviewSize('auto');
				});
			} else {
				$ctrl.html_mode = true;
				$ctrl.editor_theme = 'dark';
				$timeout(function () {
					$ctrl.properties_mode = false;

					// $timeout($ctrl.setPreviewSize);
					$ctrl.setPreviewSize('auto');
				});
			}
		};

		$ctrl.openMenu = function ($mdMenu, event) {
			var elem = event.target;
			var rect = elem.getBoundingClientRect();

			$ctrl.offset_test = event.clientX - rect.left + ' ' + (event.clientY - rect.top);
			$timeout(function () {
				$mdMenu.open(event);
			});
		};

		$ctrl.updateProperties = function (properties) {
			$ctrl.changed_properties = properties;
		};

		$ctrl.updateBackground = function (backgroundData) {
			$ctrl.backgroundData = backgroundData;
		};

		$ctrl.updatePanelElements = function (elements) {
			if (elements) {
				if (elements.learnButton) {
					if (!$ctrl.selected_panel.elements) {
						$ctrl.selected_panel.elements = {};
					}

					$ctrl.selected_panel.elements.learnButton = elements.learnButton;
				} else if ($ctrl.selected_panel.elements) {
					delete $ctrl.selected_panel.elements.learnButton;
				}

				if (elements.arrows) {
					if (!$ctrl.selected_panel.elements) {
						$ctrl.selected_panel.elements = {};
					}

					$ctrl.selected_panel.elements.arrows = elements.arrows;
				} else if ($ctrl.selected_panel.elements) {
					delete $ctrl.selected_panel.elements.arrows;
				}
			} else if ($ctrl.selected_panel.elements) {
				delete $ctrl.selected_panel.elements;
			}

			$ctrl.hasChanges = true;
			if ($ctrl.selected_panel.elements) {
				$ctrl.selected_panel.elements = Object.assign({}, $ctrl.selected_panel.elements);
			}
		};

		$ctrl.updateFromEditor = function (code) {
			$ctrl.preview_html = code.html;
			$ctrl.selected_panel.html = code.html;
			$ctrl.selected_panel.styles = code.panelCss;
			$ctrl.selected_driver.styles = code.globalCss;

			$ctrl.hasChanges = true;

			$ctrl.setUsedAssetsUrls($ctrl.selected_driver.panels);
			$timeout(function () {
				TextEditorService.extractDriverFonts($('.nd-panel-container'));
			});
		};

		$ctrl.updateDriverDetails = function (driverDetails) {
			$ctrl.selected_driver.snap_id = driverDetails.path;
			$ctrl.selected_driver.navigationType = driverDetails.navigationType;
		};

		$ctrl.updatePanelHtml = function (html, uniqueAttr, isIllusive) {
			$ctrl.selected_panel.html = html;

			if (uniqueAttr) {
				$ctrl.selected_panel.uniqueAttr = uniqueAttr;
				// $ctrl.selectedPanelAttr = $ctrl.selected_panel.uniqueAttr;
			}

			if (!isIllusive) {
				$ctrl.hasChanges = true;
			}

			$ctrl.setUsedAssetsUrls($ctrl.selected_driver.panels);
		};

		$ctrl.selectElement = function (properties) {
			if ($ctrl.selected_panel) {
				properties.html = $ctrl.selected_panel.html;

				$timeout(function () {
					$ctrl.properties_data = properties;
				});
			}
		};

		$ctrl.preview = function (isAnalyticsDisabled) {
			if ($ctrl.hasChanges) {
				var changes_message = 'The Driver should be saved first.';

				var alert = $mdDialog.alert()
					.title('Not ready')
					.textContent(changes_message)
					.ariaLabel('Not ready for preview')
					.targetEvent(event)
					.clickOutsideToClose(true)
					.ok('Noted');

				$mdDialog.show(alert)
					.then(function () { })
					.catch(function () { });

			}

			const url = constants.SNAP_PROD_URL + $ctrl.selected_driver.snap_id;
			var win = window.open(isAnalyticsDisabled ? url : url + '?analyticsDisabled=true', '_blank');
			win.focus();
		};

		/* Panel logic */

		$ctrl.selectTemplate = function () {
			$mdDialog.show({
				contentElement: '#addPanelDialog',
				parent: angular.element(document.body),
				targetEvent: event,
				clickOutsideToClose: true
			});
		};

		$ctrl.addPanel = function (template) {
			var panel_index = $ctrl.selected_driver.panels.length;
			$mdDialog.hide();

			$ctrl.selected_driver.panels.push({
				cell: panel_index + 1,
				html: template.html,
				elements: {}
			});

			$ctrl.hasChanges = true;
			$ctrl.selectPanel(panel_index);

			$ctrl.editorState.push($ctrl.selected_driver, $ctrl.selected_panel_index);
			NotifyService.notify('The panel was successfuly added.');
		};

		$ctrl.closeDialog = function () {
			$mdDialog.hide();
		};

		$ctrl.selectPanel = function (index) {
			if (index < 0) {
				delete $ctrl.selected_panel;
			} else {
				$ctrl.selected_panel_index = index;
				$ctrl.selected_panel = $ctrl.selected_driver.panels[index];
				// $timeout(function () {

				// })
				$ctrl.preview_html = $ctrl.selected_panel.html;
				// $ctrl.selectedPanelAttr = $ctrl.selected_panel.uniqueAttr ? $ctrl.selected_panel.uniqueAttr : null;
				// $scope.$apply();
			}
		};

		$ctrl.deletePanel = function (event, index) {
			var remove = $mdDialog.confirm()
				.title('Delete')
				.textContent('Are you sure you want to delete this panel?')
				.ariaLabel('Delete panel')
				.targetEvent(event)
				.ok('Delete')
				.cancel('Cancel');

			$mdDialog.show(remove).then(function () {
				var panels = new Array($ctrl.selected_driver.panels.length - 1);

				for (var i = 0; i < index; i++) {
					panels[i] = $ctrl.selected_driver.panels[i];
				}

				for (i = index + 1; i < $ctrl.selected_driver.panels.length; i++) {
					panels[i - 1] = $ctrl.selected_driver.panels[i];
					panels[i - 1].cell = i;
				}

				$ctrl.selected_driver.panels = panels;
				if ($ctrl.selected_panel_index === index) {
					$ctrl.selectPanel(panels.length === 0 ? -1 : 0);
				} else if ($ctrl.selected_panel_index > index) {
					if (panels.length > 0) {
						$ctrl.selected_panel_index--;
					} else {
						$ctrl.selectPanel(-1);
					}
				}
				$ctrl.hasChanges = true;
				$ctrl.setUsedAssetsUrls(panels);

				$ctrl.editorState.push($ctrl.selected_driver, $ctrl.selected_panel_index);
				NotifyService.notify('The panel was successfuly deleted.');
			}).catch(function () { });
		};

		$ctrl.isPanelActive = function (index) {
			return $ctrl.selected_panel_index === index;
		};

		$ctrl.isFirstPanel = function () {
			return $ctrl.selected_panel_index <= 0;
		};

		$ctrl.isLastPanel = function () {
			return $ctrl.selected_panel_index >= $ctrl.selected_driver.panels.length - 1;
		};

		/* Driver logic */
		$ctrl.setUsedAssetsUrls = function (panels) {
			$ctrl.asset_urls = {};
			for (var i = 0; i < panels.length; i++) {
				var urls = $ctrl.getAssetUrls(panels[i].html);

				if (urls) {
					urls.forEach(function (url) {
						if ($ctrl.asset_urls[url]) {
							$ctrl.asset_urls[url].push(i + 1);
						} else {
							$ctrl.asset_urls[url] = [i + 1];
						}
					});
				}
			}
		};

		$ctrl.getAssetUrls = function (html) {
			return html.match(/https?:\/\/s3((?!&quot;)[^'") ])+/gi);
		};

		$ctrl.selectDriver = function (driver) {
			var data = driver;

			$ctrl.importedAssets = null;

			delete $ctrl.selected_panel;

			var panelElements = null;

			$ctrl.hasChanges = false;
			$ctrl.selected_driver = JSON.parse(JSON.stringify(driver));
			$ctrl.selected_driver.elements = {};
			$ctrl.selected_driver.panels.sort(function (a, b) {
				return a.cell - b.cell;
			});

			// SortableJS
			$timeout(function () {
				if ($ctrl.sortable) {
					$ctrl.sortable.destroy();
				}
				$ctrl.sortable = Sortable.create($element.find('#sortableCont')[0], {
					onSort: $ctrl.onPanelsSort
				});
			}, 500);

			var learnButton = data.elements && data.elements.learn_button ? data.elements.learn_button : null;
			if (learnButton) {
				for (var i = 0; i < $ctrl.selected_driver.panels.length; i++) {
					panelElements = $ctrl.selected_driver.panels[i].elements;
					for (var j = 0; j < learnButton.panels.length; j++) {
						if (learnButton.panels[j].cell == $ctrl.selected_driver.panels[i].cell) {
							if (!panelElements) {
								panelElements = {};
							}

							if (!panelElements.learnButton) {
								panelElements.learnButton = {};
							}

							if (typeof panelElements.learnButton.text !== 'string') {
								panelElements.learnButton.text = learnButton.text;
							}

							if (typeof panelElements.learnButton.url !== 'string') {
								panelElements.learnButton.url = learnButton.url;
							}

							if (typeof panelElements.learnButton.color !== 'string') {
								panelElements.learnButton.color = learnButton.panels[j].color;
							}
						}
					}

					$ctrl.selected_driver.panels[i].elements = panelElements;
				}
			}

			$ctrl.driver_name = driver.name;
			$ctrl.assets.driver = data.assets;
			$ctrl.assets = angular.copy($ctrl.assets);

			$ctrl.setUsedAssetsUrls(driver.panels);
			if (driver.panels && driver.panels.length > 0) {
				$ctrl.selectPanel(0);
				$mdDialog.hide();
			}

			$ctrl.editorState.init($ctrl.selected_driver, $ctrl.selected_panel_index);

			// Getting fonts on driver selection
			$timeout(function () {
				TextEditorService.extractDriverFonts($('.nd-panel-container'));
			});
		};


		$ctrl.removeSelected = function (driverId) {
			if ($ctrl.selected_driver && driverId == $ctrl.selected_driver.id) {
				delete $ctrl.selected_driver;
				delete $ctrl.driver_name;

				$ctrl.hasChanges = false;
				$ctrl.hideCloseButton = true;
				$ctrl.importedAssets = null;
			}
		};

		$ctrl.askForSave = function (event) {
			if ($ctrl.isDriverOptimized) {
				$ctrl.saveDriver();
			} else {
				var confirm = $mdDialog.confirm()
					.title('Save')
					.textContent('Unoptimized assets are being used in this Driver. Are you sure you want to save it?')
					.ariaLabel('Save driver')
					.targetEvent(event)
					.ok('Save')
					.cancel('Cancel');

				$mdDialog.show(confirm).then(function () {
					$ctrl.saveDriver();
				}).catch(function () { });
			}
		};

		$ctrl.getObjectAnimations = function () {
			$ctrl.animationNameList = [
				{
					name: 'swing',
					value: 'nd-animation_swing',
					classes: 'animated infinite swing'
				},
				{
					name: 'lightSpeedIn',
					value: 'nd-animation_lightSpeedIn',
					classes: 'animated infinite lightSpeedIn'
				},
				{
					name: 'fadeInDown',
					value: 'nd-animation_fadeInDown',
					classes: 'animated infinite fadeInDown'
				},
				{
					name: 'jello',
					value: 'nd-animation_jello',
					classes: 'animated infinite jello'
				},
				{
					name: 'bounce',
					value: 'nd-animation_bounce',
					classes: 'animated infinite bounce'
				},
				{
					name: 'tada',
					value: 'nd-animation_tada',
					classes: 'animated infinite tada'
				},
				{
					name: 'zoomIn',
					value: 'nd-animation_zoomIn',
					classes: 'animated infinite zoomIn'
				},
				{
					name: 'bounceInLeft',
					value: 'nd-animation_bounceInLeft',
					classes: 'animated infinite bounceInLeft'
				},
				{
					name: 'rollIn',
					value: 'nd-animation_rollIn',
					classes: 'animated infinite rollIn'
				},
				{
					name: 'flipInY',
					value: 'nd-animation_flipInY',
					classes: 'animated infinite flipInY'
				}


			];

			$ctrl.animationParametres = {};

			var i;
			for (i = 0; i < $ctrl.selected_driver.panels.length; i++) {
				var obj = {};
				var i2;
				for (i2 = 0; i2 < $ctrl.animationNameList.length; i2++) {
					if ($ctrl.selected_driver.panels[i].html.search($ctrl.animationNameList[i2].value) != -1) {
						var params = {};
						params['classes'] = $ctrl.animationNameList[i2].classes;
						params['infinite'] = false;
						obj[$ctrl.animationNameList[i2].value] = params;
						$ctrl.animationParametres[$ctrl.selected_driver.panels[i].cell] = obj;
					}
				}
			}

		};


		$ctrl.saveDriver = function () {
			$ctrl.isLoading = true;

			for (var i = 0; i < $ctrl.selected_driver.panels.length; i++) {
				$ctrl.selected_driver.panels[i].cell = i + 1;
			}

			$ctrl.getObjectAnimations();
			DriverService.save($ctrl.selected_driver).then(function () {
				$ctrl.isLoading = false;
				$ctrl.hasChanges = false;

				NotifyService.notify('The driver was successfuly saved.');
			}).catch(function () {
				$ctrl.isLoading = false;

				NotifyService.notify("Wasn't able to save the Driver.", true);
			});
		};

		$ctrl.askForCreation = function () {
			$('#snapModal').modal();
		};

		/* Assets extractor */

		$ctrl.startDriverCreation = function (name, openExtractor) {
			$('#snapModal').modal('hide');

			$ctrl.showDriverList = false;
			if (openExtractor) {
				$ctrl.createDriverName = name;
				$ctrl.createDriverAfterExtraction = true;

				$ctrl.openAssetsExtractor();
			} else {
				$ctrl.createDriver(name);
			}
		};

		$ctrl.openAssetsExtractor = function () {
			$ctrl.showAssetsExtractor = true;
		};

		$ctrl.showDriverHeatmapsDialog = function () {
			$ctrl.showDriverHeatmaps = true;
		};

		$ctrl.hideDriverHeatmapsDialog = function () {
			$ctrl.showDriverHeatmaps = false;
		};

		$ctrl.showDriversEditDialog = function () {
			$ctrl.sf = true;

		};
		$ctrl.hideDriverDetails = function () {
			$ctrl.sf = false;

		};

		$ctrl.hideAssetsExtractor = function () {
			$ctrl.showAssetsExtractor = false;

			if ($ctrl.createDriverAfterExtraction) {
				$ctrl.createDriverAfterExtraction = false;
				$ctrl.createDriver($ctrl.createdDriverName);
			}
		};

		$ctrl.updateImportedAssets = function (assets) {
			$ctrl.importedAssets = assets;
		};

		/*------------------*/

		$ctrl.createDriver = function (name) {
			$ctrl.isLoading = true;

			DriverService.create(name).then(function (response) {
				var driver = response.data;

				$ctrl.selected_driver = driver;
				$ctrl.driver_name = $ctrl.selected_driver.name;
				$ctrl.selectPanel($ctrl.selected_driver.panels.length > 0 ? 0 : -1);

				$ctrl.assets.driver = [];
				$ctrl.assets = angular.copy($ctrl.assets);

				$ctrl.isLoading = false;
				NotifyService.notify('"' + $ctrl.selected_driver.name + '" Driver was successfuly created.');
			}).catch(function () {
				$ctrl.isLoading = false;

				NotifyService.notify("Wasn't able to create the Driver.", true);
			});
		};

		$ctrl.askForEdit = function () {
			$('#editModal').modal();
		};

		// $ctrl.editDriver = function (name) {
		// 	$('#editModal').modal('hide');
		// 	$ctrl.isLoading = true;

		// 	DriverService.edit($ctrl.selected_driver.id, name).then(function () {
		// 		$ctrl.isLoading = false;

		// 		NotifyService.notify('"' + name + '" Driver was successfuly edited.');
		// 	}).catch(function () {
		// 		$ctrl.isLoading = false;

		// 		NotifyService.notify("Wasn't able to edit the Driver.", true);
		// 	});
		// };
	},
	controllerAs: '$ctrl'
};
