export function waitForImages(element) {
	var images = element.find('img');

	if (images.length > 0) {
		var promises = [];

		images.each(function () {
			var image = this;

			// Check whether it's loaded or requires waiting
			// if (image.offsetWidth === 0 || image.offsetHeight === 0) {
			var promise = new Promise(function (resolve) {
				$(image).on('load', function () {
					$(image).off('load');
					resolve();
				});

				setTimeout(resolve, 3000);
			});

			promises.push(promise);
			// }
		});

		return Promise.all(promises);
	} else {
		return Promise.resolve();
	}
}


export function getBackgroundImageUrl(element) {
	var imageUrl = element.css('background-image');

	if (imageUrl === 'none') {
		return '';
	} else {
		imageUrl = imageUrl.replace(/^\s+|\s+$/g, '');
		imageUrl = imageUrl.replace(/^url\(('|")?|('|")?\)$/gi, '');
	}

	return imageUrl;
}

export function getAllCSSStyleRules(cssRules) {
	var cssStyleRules = [];
	var childStyleRules = [];
	var currentRule = null;

	for (var i = 0; i < cssRules.length; i++) {
		currentRule = cssRules[i];

		if (currentRule.cssRules !== null && currentRule.cssRules instanceof CSSRuleList) {
			childStyleRules = getAllCSSStyleRules(currentRule.cssRules);

			for (var j = 0; j < childStyleRules.length; j++) {
				cssStyleRules.push(childStyleRules[j]);
			}
		} else if (currentRule instanceof CSSStyleRule) {
			cssStyleRules.push(currentRule);
		}
	}

	return cssStyleRules;
}
