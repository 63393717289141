import angular from 'angular';
import ngSanitize from 'angular-sanitize';

import { PanelsListComponent } from './panels-list.component';
import { DriverPanelComponent } from './driver-panel/driver-panel.component';

export const PanelsListModule = angular
	.module('CreateSnapApp.panelsList', [
		ngSanitize
	])
	.component('panelsList', PanelsListComponent)
	.component('driverPanel', DriverPanelComponent)
	.name;
