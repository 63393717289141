import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'elly-dialog-confirm',
  template: require('./dialog-confirm.component.html'),
  styles: [require('./dialog-confirm.component.a.css')]
})
export class DialogConfirmComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() { }
}
