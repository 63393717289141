import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { API_PATH } from 'src/js/constants';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private httpClient: HttpClient) { }

  storyOwners() {
    const url = `${API_PATH}/api/user/owners`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.httpClient.get<any>(url, httpOptions);
  }

  company() {
    const url = `${API_PATH}/api/user/company`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.httpClient.get<any>(url, httpOptions);
  }
}
