import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';

import { API_PATH } from 'src/js/constants';

export class DriverListResponse {
  userId: string;
  drivers: any[];
  pageSize: number;
  next: any;
}

@Injectable({
  providedIn: 'root'
})
export class DriverService {
  public created = new Subject<any>();

  constructor(private httpClient: HttpClient) { }

  list(userId: string, byCompany: boolean, isAutomated: boolean, nextPage: any) {
    const url = `${API_PATH}/api/driver/list`;
    let params = new HttpParams().set('user_id', userId).set('scope', byCompany ? 'COMPANY' : 'USER');

    if (isAutomated) {
      params = params.set('auto_generated', 'true');
    }

    if (typeof nextPage !== 'undefined' && nextPage !== null) {
      params = params.set('from', nextPage);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params
    };

    return this.httpClient.get<DriverListResponse>(url, httpOptions);
  }

  search(query: string, isAutomated: boolean, nextPage: string) {
    const url = `${API_PATH}/api/driver/search`;
    let params = new HttpParams().set('q', query).set('scope', 'COMPANY');

    if (isAutomated) {
      params = params.set('auto_generated', 'true');
    }

    if (typeof nextPage !== 'undefined' && nextPage !== null) {
      params = params.set('from', nextPage);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params
    };

    return this.httpClient.get<any>(url, httpOptions);
  }

  get(id: any) {
    const url = `${API_PATH}/api/driver/get`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: new HttpParams().set('id', id)
    };

    return this.httpClient.get<any>(url, httpOptions);
  }

  create(name: string) {
    const url = `${API_PATH}/api/driver/create`;
    const data = { name };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.httpClient.post<any>(url, data, httpOptions);
  }

  rename(driverId: any, name: string) {
    const url = `${API_PATH}/api/driver/rename`;
    const data = { id: driverId, name };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.httpClient.put<any>(url, data, httpOptions);
  }

  copy(driverId: any) {
    const url = `${API_PATH}/api/driver/copy`;
    const data = {
      id: driverId
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.httpClient.post<any>(url, data, httpOptions);
  }

  save(driver: any) {
    const url = `${API_PATH}/api/driver/save`;
    const { id, styles, panels } = driver;
    const data = { id, styles, panels };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.httpClient.put<any>(url, data, httpOptions);
  }

  delete(driverId: any) {
    const url = `${API_PATH}/api/driver/delete`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        id: driverId
      }
    };

    return this.httpClient.delete(url, { ...httpOptions, responseType: 'text' });
  }
}
