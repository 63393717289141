import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class EditorStateService {
    private subject: Subject<any> = new Subject<any>();
    private stateList: any = {};

    constructor() { }

    init(driver, selectedPanelIndex) {
        const initialState = { driver, selectedPanelIndex };

        this.stateList = {
            prev: null,
            next: null,
            data: JSON.parse(JSON.stringify(initialState)) // get a better deep copy method
        };
    }

    subscribe(cb) {
        this.subject.subscribe(cb);
    }

    undo() {
        if (this.stateList.prev) {
            this.stateList = this.stateList.prev;

            this.subject.next(this.stateList.data);
        }
    }

    redo() {
        if (this.stateList.next) {
            this.stateList = this.stateList.next;

            this.subject.next(this.stateList.data);
        }
    }

    push(driver, selectedPanelIndex) {
        const state = { driver, selectedPanelIndex };

        this.stateList.next = {
            prev: this.stateList,
            next: null,
            data: JSON.parse(JSON.stringify(state)) // get a better deep copy method
        };

        this.stateList = this.stateList.next;
    }
}
