import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { API_PATH } from 'src/js/constants';

@Injectable({
  providedIn: 'root'
})
export class BannerService {
  public created = new Subject<any>();

  constructor(private httpClient: HttpClient) { }

  list(userId: any, byCompany: boolean, nextPage: any) {
    const url = `${API_PATH}/api/banner/list`;
    let params = new HttpParams().set('user_id', userId).set('scope', byCompany ? 'COMPANY' : 'USER');

    if (typeof nextPage !== 'undefined' && nextPage !== null) {
      params = params.set('from', nextPage);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params
    };

    return this.httpClient.get<any>(url, httpOptions);
  }

  search(query: string, nextPage: string) {
    const url = `${API_PATH}/api/banner/search`;
    let params = new HttpParams().set('q', query).set('scope', 'COMPANY');

    if (typeof nextPage !== 'undefined' && nextPage !== null) {
      params = params.set('from', nextPage);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params
    };

    return this.httpClient.get<any>(url, httpOptions);
  }

  create(banner) {
    const url = `${API_PATH}/api/banner/create`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.httpClient.post<any>(url, banner, httpOptions)
      .pipe(
        tap(res => this.created.next(res.banner))
      );
  }

  edit(banner) {
    const url = `${API_PATH}/api/banner/edit`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.httpClient.put<any>(url, banner, httpOptions);
  }

  delete(bannerId: any) {
    const url = `${API_PATH}/api/banner/delete`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        bannerId
      }
    };

    return this.httpClient.delete<any>(url, httpOptions);
  }
}
