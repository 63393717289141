import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

import { DriverService } from 'src/app/core/driver.service';
import { AuthService } from 'src/app/services/auth.service';
import { BannerService } from 'src/app/core/banner.service';

import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'elly-dialog-create',
  template: require('./dialog-create.component.html'),
  styles: [require('./dialog-create.component.a.css')]
})
export class DialogCreateComponent implements OnInit {
  form: FormGroup;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<DialogCreateComponent>,
    private auth: AuthService, private driver: DriverService, private banner: BannerService,
    private notify: NotifyService) { }

  ngOnInit() {
    this.form = this.fb.group({
      type: ['banner', Validators.required],
      bannerType: ['CREATOPY', Validators.required],
      name: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(200)]],
      url: ['', [Validators.required, Validators.pattern(/[-_a-zA-Z0-9]+/)]],
      previewUrl: ['', [Validators.pattern(/[-_a-zA-Z0-9]+/)]] // Validators.required, 
    });
  }

  async submit(form) {
    if (form.valid) {
      this.dialogRef.close(form.value);
      this.notify.message(`Creating "${form.value.name}" Banner...`);

      try {
        await this.banner.create({
          name: form.value.name,
          hash: form.value.url,
          type: form.value.bannerType,
          previewUrl: form.value.bannerType === 'CREATOPY' ? null : `https://cdn.bannersnack.com/banners/${form.value.previewUrl}/images/jpg`,
          ownerId: null,
          updatedAt: Date.now()
        }).toPromise();
      } catch (error) {
        this.notify.error('Failed to create the Banner.');
      }
    }
  }
}
