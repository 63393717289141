import { Injectable } from '@angular/core';
import { IMLPageDialogComponent } from './iml-page-dialog.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IMLPageDialogRef {
  private ref: IMLPageDialogComponent;

  constructor() { }

  setRef(ref: IMLPageDialogComponent) {
    this.ref = ref;
  }

  afterClosed(): Observable<void> {
    return this.ref.afterClosed.asObservable();
  }

  close() {
    if (this.ref) {
      this.ref.close();
    }
  }
}
