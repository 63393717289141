import template from './panels-list.component.html';
import './panels-list.component.css';

export const PanelsListComponent = {
	template: template,
	bindings: {
		addPanel: '<',
		panelsAmount: '<'
	},
	transclude: true,
	controller: function ($element, $timeout) {
		var $ctrl = this;

		$ctrl.$onInit = function () {
			$ctrl.checkOverflow();
			$(window).on('resize', $ctrl.checkOverflow);
		};

		$ctrl.$onChanges = function (changes) {
			var amount = changes.panelsAmount;

			if (amount && amount.previousValue != amount.currentValue) {
				$timeout($ctrl.checkOverflow);
			}
		};

		$ctrl.$onDestroy = function () {
			$(window).off('resize', $ctrl.checkOverflow);
		};

		$ctrl.hasPanels = function () {
			return $ctrl.panelsAmount > 0;
		};

		$ctrl.checkOverflow = function () {
			var panels_cont = $element.find('.snap-panels-container')[0];
			$ctrl.hasOverflow = panels_cont.scrollHeight > panels_cont.clientHeight;

			if (panels_cont.scrollHeight > panels_cont.clientHeight) {
				$element.removeAttr('_no-scrollbar');
			} else {
				$element.attr('_no-scrollbar', '');
			}
		};
	},
	controllerAs: '$ctrl'
};
