import template from './panel-preview.component.html';
import './panel-preview.component.css';

import * as roosterjs from 'roosterjs';
// import { PluginEventType, Alignment } from 'roosterjs-editor-types';

import { waitForImages, getBackgroundImageUrl } from 'src/js/scripts';
import ToolbarPlugin from 'src/js/toolbar-plugin';
import Hashids from 'hashids';

export const PanelPreviewComponent = {
	template: template,
	bindings: {
		html: '<',
		width: '<',
		height: '<',
		elements: '<',
		changedProperties: '<',
		isFirstPanel: '<',
		isLastPanel: '<',
		isLandscape: '<',
		panelIndex: '<',
		uniquePanelAttr: '<',
		onSelect: '<',
		onHtmlChange: '<',
		onBackgroundUpdate: '<'
	},
	controller: function ($timeout, $sce, $element, $rootScope, $scope, DebouncerService, TextEditorService) {
		var $ctrl = this;

		$ctrl.classListAnimation = [
			'nd-animation_lightSpeedIn',
			'nd-animation_swing',
			'nd-animation_fadeInDown',
			'nd-animation_jello',
			'nd-animation_bounce',
			'nd-animation_tada',
			'nd-animation_zoomIn',
			'nd-animation_bounceInLeft',
			'nd-animation_rollIn',
			'nd-animation_flipInY'
		];

		$ctrl.$onInit = function () {
			if (!$ctrl.width || !$ctrl.height) {
				$ctrl.setSize(414, 628);
			}

			$ctrl.toolbarPlugin = new ToolbarPlugin(TextEditorService);
			TextEditorService.getEditorObservable().add($ctrl.updateHtml);
			TextEditorService.getToolbarObservable().add($ctrl.selectInlineElement);

			if ($ctrl.safe_html) {
				$timeout(function () {
					$ctrl.init();
				});
			}

			$ctrl.elementIndex = 0;
			$ctrl.selectedElement = null;
			$ctrl.selectedAttr = null;
			$ctrl.encoder = new Hashids('', 3);
		};

		$ctrl.$onChanges = function (changes) {
			var html = changes.html;
			var width = changes.width;
			var height = changes.height;
			var elements = changes.elements;
			var properties = changes.changedProperties;

			if (elements) {
				var currentElems = elements.currentValue;

				if (currentElems) {
					var learnButton = currentElems.learnButton ? currentElems.learnButton : null;

					if (learnButton) {
						var newColor = new tinycolor(learnButton.color ? learnButton.color : '#0D47A1');
						var L = newColor.getLuminance();
						var isDark = L < 0.5;

						$ctrl.learnButton = {
							text: learnButton.text,
							backgroundColor: new tinycolor(newColor).toRgbString(),
							color: isDark ? 'white' : 'black'
						};
					} else {
						$ctrl.learnButton = {};
					}

					$ctrl.arrows = currentElems.arrows ? currentElems.arrows : {};
				} else {
					$ctrl.learnButton = {};
					$ctrl.arrows = {};
				}
			}

			var new_width = width && width.currentValue ? width.currentValue : $ctrl.previewWidth;
			var new_height = height && height.currentValue ? height.currentValue : $ctrl.previewHeight;
			if (new_width !== $ctrl.previewWidth || new_height !== $ctrl.previewHeight) {
				$ctrl.setSize(new_width, new_height);
			}

			if (html && typeof html.currentValue === 'string') {
				if ($ctrl.editor) {
					$ctrl.editor.dispose();
					delete $ctrl.editor;
				}

				$ctrl.safe_html = $sce.trustAsHtml(html.currentValue);
				$timeout(function () {
					var panelCont = $element.children('.snap-html-preview').children('.BOXsnap');
					var hasUniqueAttr = false;

					if (panelCont.length === 0) {
						panelCont = $('<div class="BOXsnap snap-background"></div>');
						$element.children('.snap-html-preview').first().append(panelCont);
					}

					var attributes = panelCont[0].attributes;
					var uniquePanelAttr = $ctrl.uniquePanelAttr;

					for (var i = 0; i < attributes.length; i++) {
						if (/^_ndpanel-/g.test(attributes[i].name)) {
							hasUniqueAttr = true;
							uniquePanelAttr = attributes[i].name;
							break;
						}
					}

					if (!hasUniqueAttr) {
						if (!uniquePanelAttr) {
							uniquePanelAttr = $ctrl.generateUniqueAttr($ctrl.panelIndex);
						}

						panelCont.attr(uniquePanelAttr, '');
					}

					if ($ctrl.onHtmlChange) {
						$ctrl.onHtmlChange($element.children('.snap-html-preview').html(), uniquePanelAttr, true);
					}

					$element.children('.snap-html-preview').find('a, button, input').click(function (e) {
						e.preventDefault();
					});
				});

				if (!html.isFirstChange()) {
					$timeout(function () {
						$ctrl.init();
					});
				}
			}

			if (properties && properties.currentValue && !properties.isFirstChange()) {
				$ctrl.applyChanges(properties.currentValue);
			}
		};

		$ctrl.$onDestroy = function () {
			if ($ctrl.editor) {
				$ctrl.editor.dispose();
				delete $ctrl.editor;
			}

			TextEditorService.getEditorObservable().remove($ctrl.updateHtml);
			TextEditorService.getToolbarObservable().remove($ctrl.selectInlineElement);
		};

		$ctrl.generateUniqueAttr = function (panelIndex) {
			var uniqueId = $ctrl.encoder.encode([panelIndex, Math.floor(Math.random() * 20)]);
			var uniqueAttr = '_ndpanel-' + uniqueId;

			if ($('[' + uniqueAttr + ']').length === 0) {
				return uniqueAttr.toLowerCase();
			} else {
				return $ctrl.generateUniqueAttr(panelIndex + 1);
			}
		};

		$ctrl.selectInlineElement = function (editor, properties) {
			if (properties && properties.linkElement && $ctrl.selectedInlineElement !== properties.linkElement) {
				$ctrl.selectedInlineElement = properties.linkElement;
				$ctrl.sendSelectedElementProperties($ctrl.selectedInlineElement, false);
			} else if ($ctrl.selectedInlineElement) {
				$ctrl.selectedInlineElement = null;
				$ctrl.sendSelectedElementProperties($ctrl.selectedElement.template, false);
			}
		};

		$ctrl.setSize = function (width, height) {
			$element.css('width', width + 'px');
			$element.css('height', height + 'px');

			// Resetting element sizes
			$ctrl.setElementsSize();

			// Learn Button
			$timeout(function () {
				var learnButton = $element.find('.nd-learn-btn').first();
				if (learnButton.width() !== width) {
					learnButton.css('transform', 'scale(' + width / learnButton.width() + ')');
				}
			});
		};

		$ctrl.updateHtml = function () {
			if (!$ctrl.onHtmlChange) {
				return;
			}

			var template = $element.children('.snap-html-preview');
			if ($ctrl.editor) {
				if ($ctrl.selectedElement.template.tagName !== 'DIV') {
					var html = template.html();
					var parserHtml = '<div></div>' + html; // in order to prevent pushing styles/scripts to the head
					var doc = $ctrl.parser.parseFromString(parserHtml, 'text/html');

					// Removes fake div
					doc.body.removeChild(doc.body.childNodes[0]);
					$(doc.body).find('[_nd-editor-wrapper]').each(function () {
						roosterjs.unwrap(this);
					});

					$ctrl.onHtmlChange(doc.body.innerHTML);
				} else {
					$($ctrl.editor.core.contentDiv).css('user-select', '');
					$($ctrl.editor.core.contentDiv).removeAttr('contenteditable');

					$ctrl.onHtmlChange(template.html());
					$($ctrl.editor.core.contentDiv).css('user-select', 'text');
					$($ctrl.editor.core.contentDiv).attr('contenteditable', 'true');
				}
			} else {
				$ctrl.onHtmlChange(template.html());
			}
		};

		$ctrl.applyChanges = function (properties) {
			var backgroundImageUrl = null;
			var backgroundColor = null;
			var selectedElement = $ctrl.selectedElement.template;

			if ($ctrl.selectedInlineElement) {
				selectedElement = $ctrl.selectedInlineElement;
			}

			if (selectedElement) {
				var tag = selectedElement.tagName;
				var element = $(selectedElement);

				if (/img/i.test(tag)) {
					var imageUrl = properties.imageUrl;

					element.attr('src', typeof imageUrl === 'string' && imageUrl.length > 0 ? imageUrl : '');
					element.on('load', function () {
						element.off('load');

						$ctrl.setElementsSize();
						$scope.$apply();
					});
				} else {
					var text = properties.text;

					backgroundColor = properties.backgroundColor;
					backgroundImageUrl = properties.backgroundImageUrl;
					if (typeof backgroundColor === 'string' && backgroundColor.length > 0) {
						element.css('background-color', backgroundColor);
					} else {
						element.css('background-color', '');
					}

					if (typeof backgroundImageUrl === 'string' && backgroundImageUrl.length > 0) {
						element.css('background-image', "url('" + backgroundImageUrl + "')");
					} else {
						element.css('background-image', '');
					}

					// link
					if (/a/i.test(tag) && typeof properties.href === 'string') {
						element[0].href = properties.href;
					}

					if (typeof text === 'string' && text.length > 0) {
						// element.html(text);

						// Setting up the reselection attribute
						$ctrl.selectedAttr = '_nd-selection-' + Date.now();
						element.attr($ctrl.selectedAttr, 'active');
						$ctrl.init();
					} else {
						$ctrl.setElementsSize();
					}
				}

				// Animation
				if (properties.animation) {
					var classList = element[0].classList;
					for (var i = 0; i < classList.length; i++) {

						for (var i2 = 0; i2 < $ctrl.classListAnimation.length; i2++) {
							if ($ctrl.classListAnimation[i2] === classList[i]) {
								element.removeClass(classList[i]);
							}
						}

					}

					element.addClass(properties.animation);
				}
			} else {
				var backgroundElement = $element.find('.snap-background');

				backgroundColor = properties.backgroundColor;
				backgroundImageUrl = properties.backgroundImageUrl;
				if (typeof backgroundColor === 'string' && backgroundColor.length > 0) {
					backgroundElement.css('background-color', backgroundColor);
				} else {
					backgroundElement.css('background-color', '');
				}

				if (typeof backgroundImageUrl === 'string' && backgroundImageUrl.length > 0) {
					backgroundElement.css('background-image', "url('" + backgroundImageUrl + "')");
				} else {
					backgroundElement.css('background-image', '');
				}
			}

			$ctrl.updateHtml();
		};

		$ctrl.getUniqueIndex = function () {
			var index = $ctrl.elementIndex;

			$ctrl.elementIndex++;

			return index;
		};

		$ctrl.init = function () {
			var template = $element.find('.snap-html-preview');
			var overlayCont = $element.children('.nd-overlay-cont');
			var templateRect = template[0].getBoundingClientRect();
			var templateCont = {
				rect: templateRect,
				scale: {
					x: templateRect.width / template[0].offsetWidth,
					y: templateRect.height / template[0].offsetHeight
				}
			};

			$ctrl.clearOverlay();
			var templateTree = $ctrl.setElementsTree(template, null);

			$ctrl.templateElements = templateTree[0].items;
			for (var i = 0; i < $ctrl.templateElements.length; i++) {
				$ctrl.templateElements[i].parent = null;
				$ctrl.setOverlayTree($ctrl.templateElements[i], overlayCont, templateCont, []);
			}

			// Reselect
			if ($ctrl.selectedElement) {
				var selectedOverlay = $ctrl.selectedElement.overlay;

				$ctrl.selectedElement = null;
				$ctrl.selectElement(selectedOverlay);
			}

			$timeout(function () {
				waitForImages(template).then(function () {
					$ctrl.setElementsSize();
					$scope.$apply();
				});
			});
		};

		$ctrl.clearOverlay = function () {
			var overlayCont = $element.children('.nd-overlay-cont');

			$ctrl.elementIndex = 0;
			$ctrl.selectedElement = null;
			$ctrl.templateElements = [];

			overlayCont.html('');
			$ctrl.sendSelectedElementProperties($element.find('.snap-background')[0], true);
		};

		// Set size

		$ctrl.setElementsSize = function () {
			if (Array.isArray($ctrl.templateElements) && $ctrl.templateElements.length > 0) {
				var template = $element.find('.snap-html-preview');
				var templateRect = template[0].getBoundingClientRect();
				var container = {
					rect: templateRect,
					scale: {
						x: templateRect.width / template[0].offsetWidth,
						y: templateRect.height / template[0].offsetHeight
					}
				};

				for (var i = 0; i < $ctrl.templateElements.length; i++) {
					$ctrl.setOverlayTreeSize($ctrl.templateElements[i], container);
				}
			}
		};

		$ctrl.setOverlayTreeSize = function (element, container) {
			$ctrl.setElementSize(element, container);

			for (var i = 0; i < element.items.length; i++) {
				$ctrl.setOverlayTreeSize(element.items[i], container);
			}
		};

		$ctrl.setElementSize = function (element, container) {
			var elementRect = element.template.getBoundingClientRect();

			element.overlay.css('left', '' + ((elementRect.x - container.rect.x) / container.scale.x + 1) + 'px');
			element.overlay.css('top', '' + ((elementRect.y - container.rect.y) / container.scale.y + 1) + 'px');
			element.overlay.css('width', '' + (elementRect.width / container.scale.x - 2) + 'px');
			element.overlay.css('height', '' + (elementRect.height / container.scale.y - 2) + 'px');
		};

		$ctrl.setElementsTree = function (templateElement, parent) {
			var tag = templateElement[0].tagName;
			if (/script|link|style/i.test(tag)) {
				return [];
			}

			var templateElements = [];
			var element = {
				template: templateElement[0],
				index: $ctrl.getUniqueIndex(),
				items: [],
				parent: parent
			};

			var isBackgroundElement = $(element.template).hasClass('snap-background');
			// Stops child elements mapping if it's a text element
			if (isBackgroundElement || !$ctrl.isTextElement($(element.template))) {
				$(element.template).children().each(function () {
					var elements = $ctrl.setElementsTree($(this), element);
					templateElements = templateElements.concat(elements);
				});
			}

			if (!isBackgroundElement) {
				element.items = templateElements;
				templateElements = [element];

				// For reselection
				if ($ctrl.selectedAttr !== null && $(element.template).attr($ctrl.selectedAttr) === 'active') {
					$ctrl.selectedElement = element;

					$(element.template).removeAttr($ctrl.selectedAttr);
					$ctrl.selectedAttr = null;
				}
			} else {
				for (var i = 0; i < templateElements.length; i++) {
					templateElements[i].parent = parent;
				}
			}

			return templateElements;
		};

		// Create overlay tree

		$ctrl.setOverlayTree = function (element, overlayCont, templateCont, parentIndexes) {
			var elementIndexes = parentIndexes.concat([element.index]);

			for (var i = 0; i < element.items.length; i++) {
				$ctrl.setOverlayTree(element.items[i], overlayCont, templateCont, elementIndexes);
			}

			element.overlay = $('<div style="position: absolute; outline: z-index: 1;"></div>');
			element.overlay.attr('data-path', '[' + elementIndexes.join(',') + ']');
			element.overlay.appendTo(overlayCont);

			element.overlay.click(function (event) {
				event.preventDefault();
				event.stopPropagation();

				var selectedElement = $ctrl.selectedElement;
				var requireSelection = true;

				if (selectedElement) {
					var selectedPath = selectedElement.overlay.data('path');
					var currentPath = $(this).data('path');

					if (selectedPath.join('') === currentPath.join('')) {
						requireSelection = false;

						if (!selectedElement.isTextElement) {
							$ctrl.deselectElement(true);
						}
					} else {
						if (selectedElement.isTextElement) {
							selectedElement.overlay.off('mouseover', $ctrl.onMouseOver);
							$(selectedElement.template).off('mouseleave', $ctrl.onMouseLeave);

							$ctrl.disposeEditor(selectedElement);
						}

						$ctrl.deselectElement(false);
					}
				}

				if (requireSelection) {
					$ctrl.selectElement($(this));

					selectedElement = $ctrl.selectedElement;
					if ($ctrl.isTextElement($(selectedElement.template))) {
						selectedElement.overlay.on('mouseover', $ctrl.onMouseOver);
						$(selectedElement.template).on('mouseleave', $ctrl.onMouseLeave);
						$ctrl.onMouseOver();

						$ctrl.initEditor(selectedElement);
						selectedElement.isTextElement = true;

					}
				}
			});

			$ctrl.setElementSize(element, templateCont);
		};

		$ctrl.initEditor = function (selectedElement) {
			var html = selectedElement.template.innerHTML;
			var editorWrapper = selectedElement.template;

			if (selectedElement.template.tagName !== 'DIV') {
				selectedElement.template.innerHTML = '<div _nd-editor-wrapper style="display: inline-block !important; cursor: text !important; outline: none !important; width: 100% !important;"></div>';
				editorWrapper = selectedElement.template.children[0];
			}

			$ctrl.editor = roosterjs.createEditor(editorWrapper, [$ctrl.toolbarPlugin]);
			$ctrl.editor.core.defaultFormat.fontFamily = 'Roboto, sans-serif';
			$ctrl.editor.core.defaultFormat.fontSize = '14px';
			editorWrapper.innerHTML = html;
		};

		$ctrl.disposeEditor = function (selectedElement) {
			$ctrl.editor.dispose();
			delete $ctrl.editor;

			if (selectedElement.template.tagName !== 'DIV') {
				roosterjs.unwrap(selectedElement.template.children[0]);
			}
		};

		$ctrl.isTextElement = function (element) {
			var isTextElement = false;

			element.children().each(function () {
				if (/^(h\d|p|span|b|strong|i|em|table)$/i.test(this.tagName)) {
					isTextElement = true;
				}
			});

			if (!isTextElement) {
				var elementTextNodes = element.contents().filter(function () {
					return this.nodeType === 3 && this.nodeValue.trim().length > 0;
				});

				if (elementTextNodes.length > 0) {
					isTextElement = true;
				}
			}

			return isTextElement;
		};

		$ctrl.onMouseOver = function () {
			var overlayCont = $element.children('.nd-overlay-cont');

			overlayCont.addClass('inactive');
			// $element.children('.snap-html-preview').attr('contenteditable', 'true');
		};

		$ctrl.onMouseLeave = function () {
			var overlayCont = $element.children('.nd-overlay-cont');

			overlayCont.removeClass('inactive');
			// $element.children('.snap-html-preview').attr('contenteditable', 'false');
		};

		// Deselect

		$ctrl.deselectElement = function (selectBackground) {
			var elementPath = $ctrl.selectedElement.overlay.data('path');

			for (var i = 0; i < $ctrl.templateElements.length; i++) {
				if ($ctrl.templateElements[i].index === elementPath[0]) {
					$ctrl.deselectElementTree($ctrl.templateElements[i]);
					break;
				}
			}

			$ctrl.selectedElement.overlay.removeClass('active');
			$ctrl.selectedElement.overlay.blur();
			$ctrl.selectedElement = null;
			$ctrl.selectedInlineElement = null;

			if (selectBackground) {
				$ctrl.sendSelectedElementProperties($element.find('.snap-background')[0], true);
			}
		};

		$ctrl.deselectElementTree = function (element) {
			element.overlay.css('z-index', 1);
			element.overlay.removeClass('selected');

			for (var i = 0; i < element.items.length; i++) {
				$ctrl.deselectElementTree(element.items[i]);
			}
		};

		// Select

		$ctrl.selectElement = function (overlay) {
			var elementPath = overlay.data('path');
			var currentElement = null;

			for (var i = 0; i < $ctrl.templateElements.length; i++) {
				if ($ctrl.templateElements[i].index === elementPath[0]) {
					currentElement = $ctrl.templateElements[i];
					$ctrl.selectElementTree(currentElement, elementPath, 1, 10, true);

					break;
				}
			}

			$ctrl.selectElementTree(currentElement, elementPath, 1, 10, true);
			for (i = 1; i < elementPath.length; i++) {
				currentElement = currentElement.items.find(function (e) {
					return e.index === elementPath[i];
				});
			}

			$ctrl.selectedElement = currentElement;
			$ctrl.selectedElement.overlay.addClass('active');
			$ctrl.sendSelectedElementProperties($ctrl.selectedElement.template, false);
		};

		$ctrl.selectElementTree = function (element, elementPath, pathIndex, zIndex, uplift) {
			element.overlay.addClass('selected');

			if (uplift) {
				element.overlay.css('z-index', zIndex);
			}

			var nestedUplift = pathIndex <= elementPath.length;
			for (var i = 0; i < element.items.length; i++) {
				$ctrl.selectElementTree(element.items[i], elementPath, pathIndex + 1, zIndex + 1, nestedUplift);
			}
		};

		// Send updates

		$ctrl.sendSelectedElementProperties = function (templateElement, isBackground) {
			if ($ctrl.onSelect) {
				var element = $(templateElement);
				var tag = templateElement.tagName;
				var selectedData = {};

				if (isBackground) {
					selectedData = {
						backgroundColor: element.css('background-color'),
						imageUrl: getBackgroundImageUrl(element),
						type: 'background'
					};
				} else if (/img/i.test(tag)) {
					selectedData = {
						type: 'image',
						imageUrl: templateElement.src
					};
				// } else if (/video/i.test('video')) {
				} else if (/a/i.test(tag)) {
					selectedData = {
						backgroundColor: element.css('background-color'),
						imageUrl: getBackgroundImageUrl(element),
						href: templateElement.href,
						type: 'link'
					};
				} else {
					selectedData = {
						backgroundColor: element.css('background-color'),
						imageUrl: getBackgroundImageUrl(element),
						type: 'element',
						hasText: $ctrl.isTextElement(element)
					};
				}

				// Animation
				if ($(templateElement).hasClass('nd-animation')) {
					$rootScope.selectedRoot = $(templateElement);
					var classList = templateElement.classList;
					var animation = null;

					for (var i = 0; i < classList.length; i++) {
						for (var i2 = 0; i2 < $ctrl.classListAnimation.length; i2++) {
							if ($ctrl.classListAnimation[i2] === classList[i]) {
								animation = classList[i];
							}
						}
					}

					selectedData.textGradient1 = element.css('background').split(/([rgb|rgba|+[(]+[\d]+[,]+[ \d]+[, \d]+[ \d]+[)])/)[3],
					selectedData.textGradient2 = element.css('background').split(/([rgb|rgba|+[(]+[\d]+[,]+[ \d]+[, \d]+[ \d]+[)])/)[5],
					selectedData.animationShow = true,
					selectedData.animation = animation;
				}

				$ctrl.onSelect(selectedData);
			}
		};

		// $ctrl.init = function () {
		// 	// var template = $element.find('.snap-html-preview');
		// 	// var templateRect = template[0].getBoundingClientRect();
		// 	// var templateScaleX = templateRect.width / template[0].offsetWidth;
		// 	// var templateScaleY = templateRect.height / template[0].offsetHeight;

		// 	// $ctrl.template_elements = template.find(':not(.snap-background)'); // '.snap-element');
		// 	// $ctrl.canvas.clear();
		// 	// // Elements
		// 	// $ctrl.template_elements.each(function (index) {
		// 	// 	var transform = this.style.transform;
		// 	// 	var rotate = transform.match(/rotate(\S+)/gi);
		// 	// 	var initialAngle = 0;

		// 	// 	if (rotate) {
		// 	// 		initialAngle = parseFloat(rotate[0].replace(/^rotate\(|deg\)$/gi, ''));
		// 	// 		$(this).css('transform', transform.replace(/rotate\(\S+\)/gi, 'rotate(0)'));
		// 	// 	}

		// 	// 	var rect = this.getBoundingClientRect();
		// 	// 	var props = {
		// 	// 		left: (rect.left - templateRect.left + rect.width / 2) / templateScaleX,
		// 	// 		top: (rect.top - templateRect.top + rect.height / 2) / templateScaleY,
		// 	// 		width: rect.width / templateScaleX,
		// 	// 		height: rect.height / templateScaleY,
		// 	// 		auto: {
		// 	// 			left: 0,
		// 	// 			top: 0
		// 	// 		},
		// 	// 		angle: initialAngle,
		// 	// 		fill: 'transparent',
		// 	// 		originX: 'center',
		// 	// 		originY: 'center',
		// 	// 		name: 'element',
		// 	// 		index: index,
		// 	// 		// Border size
		// 	// 		borderScaleFactor: 5
		// 	// 	};

		// 	// 	props.parent = {
		// 	// 		left: props.left - this.offsetLeft,
		// 	// 		top: props.top - this.offsetTop
		// 	// 	};

		// 	// 	// Percentage Ratio
		// 	// 	var left = this.style.left;
		// 	// 	var top = this.style.top;
		// 	// 	var width = this.style.width;
		// 	// 	var height = this.style.height;

		// 	// 	props.percentRatio = {
		// 	// 		left: left.search(/%/g) > -1 ? this.offsetLeft / parseFloat(left.replace(/%/g, '')) : -1,
		// 	// 		top: top.search(/%/g) > -1 ? this.offsetTop / parseFloat(top.replace(/%/g, '')) : -1,
		// 	// 		width: -1,
		// 	// 		height: -1
		// 	// 	};

		// 	// 	if (width.search(/auto/gi) > -1) {
		// 	// 		props.autoWidth = true;
		// 	// 	} else if (width.search(/%/g) > -1) {
		// 	// 		props.percentRatio.width = this.offsetWidth / parseFloat(width.replace(/%/g, ''));
		// 	// 	}

		// 	// 	if (height.search(/auto/gi) > -1) {
		// 	// 		props.autoHeight = true;
		// 	// 	} else if (height.search(/%/g) > -1) {
		// 	// 		props.percentRatio.height = this.offsetHeight / parseFloat(height.replace(/%/g, ''));
		// 	// 	}

		// 	// 	// Translate
		// 	// 	// var transform = this.style.transform;
		// 	// 	var translates = transform.match(/translate\(\S+,\s+\S+\)/gi);
		// 	// 	var translateStr = translates ? translates[translates.length - 1] : null;

		// 	// 	props.translate = {
		// 	// 		x: 0,
		// 	// 		y: 0
		// 	// 	};

		// 	// 	if (translateStr) {
		// 	// 		translates = translateStr.match(/-?[0-9]+%?/g);

		// 	// 		props.translate.x = parseFloat(translates[0].replace(/%/g, '')) / 100.0;
		// 	// 		props.translate.y = parseFloat(translates[1].replace(/%/g, '')) / 100.0;
		// 	// 	}

		// 	// 	// Setting fabric element
		// 	// 	var fabricElement = new fabric.Rect(props);

		// 	// 	fabricElement.hasControls = false;
		// 	// 	fabricElement.lockMovementX = true;
		// 	// 	fabricElement.lockMovementY = true;

		// 	// 	if (rotate) {
		// 	// 		$(this).css('transform', transform.replace(/rotate\(\S+\)/gi, 'rotate(' + initialAngle + 'deg)'));
		// 	// 	}

		// 	// 	$ctrl.canvas.add(fabricElement);
		// 	// });
		// };
	},
	controllerAs: '$ctrl'
};
