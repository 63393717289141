export function AssetsManagerService($http, $q, $timeout) {
	var that = this;

	this.upload = function (asset, notifyProgress) {
		var cancel = $q.defer();
		var upload_promises = [];
		var options = {
			headers: {
				'Accept': 'application/json',
				'Cache-Control': 'no-cache',
				'x-amz-acl': 'public-read',
				'Content-Type': asset.file.type
			},
			transformRequest: angular.identity,
			timeout: cancel.promise
		};

		if (asset.preview_signed_url && asset.preview_file) {
			var preview_options = {
				headers: {
					'Accept': 'application/json',
					'Cache-Control': 'no-cache',
					'x-amz-acl': 'public-read',
					'Content-Type': asset.preview_file.type
				},
				transformRequest: angular.identity,
				timeout: cancel.promise
			};

			var upload_preview_promise = $http.put(asset.preview_signed_url, asset.preview_file, preview_options);
			upload_promises.push(upload_preview_promise);
		}

		if (notifyProgress) {
			options.uploadEventHandlers = {
				progress: function (e) {
					if (e.lengthComputable) {
						notifyProgress(e);
					}
				}
			};
		}

		var upload_promise = $http.put(asset.signed_url, asset.file, options);
		upload_promises.push(upload_promise);

		return {
			promise: $q.all(upload_promises),
			cancel: function () { cancel.resolve(); }
		};
	};

	this.download = function (url) {
		var asset_url = url.replace(/^https:/gi, 'http:');

		return $http.get(asset_url, { responseType: 'blob' });
	};

	//#region Support Logic

	this.setCompressor = function (compressor) {
		this.compressor = compressor;
	};

	this.getImageInfo = function (file, url) {
		var deferred = $q.defer();
		var image = new Image();

		image.onload = function () {
			that.generatePreview(file, image.width, image.height).then(function (compressed) {
				deferred.resolve({
					preview_file: compressed,
					width: image.width,
					height: image.height
				});
			});
		};
		image.src = url;

		return deferred.promise;
	};

	this.getImageSize = function (url) {
		var deferred = $q.defer();
		var image = new Image();

		image.onload = function () {
			deferred.resolve({
				width: image.width,
				height: image.height
			});
		};
		image.src = url;

		return deferred.promise;
	};

	this.getVideoInfo = function (url, type) {
		var deferred = $q.defer();
		var preview_cont = $('<div style="position: fixed; transform: translateX(-100%);"></div>');
		var video = $('<video></video>');
		var source = $('<source>');
		var canvas = $('<canvas></canvas>');

		preview_cont.appendTo(document.body);
		preview_cont.append(video);
		preview_cont.append(canvas);
		video.append(source);

		source.attr('type', type);
		source.attr('src', url);

		video.on('loadeddata', function () {
			var width = video[0].videoWidth;
			var height = video[0].videoHeight;

			canvas.attr('width', width);
			canvas.attr('height', height);

			var ctx = canvas[0].getContext('2d');
			ctx.drawImage(video[0], 0, 0, width, height);
			canvas[0].toBlob(function (blob) {
				preview_cont.remove();

				that.generatePreview(blob, width, height).then(function (compressed) {
					deferred.resolve({
						preview_file: compressed,
						width: width,
						height: height
					});
				});
			});
		});

		return deferred.promise;
	};

	this.generatePreview = function (file, width, height) {
		var deferred = $q.defer();
		var preview_size = 200; // 200 px
		var max_size = 400; // 400 px
		var max_file_size = 50 * 1024; // 50 kb

		if (width > preview_size || height > preview_size || file.size > max_file_size) {
			var min = width < height ? width : height;
			var ratio = min / preview_size;

			var compress_width = width;

			if (ratio > 1) {
				compress_width = Math.round(compress_width / ratio);
			}

			ratio = ratio < 1 ? 1 : ratio;
			this.compressor.compress(file, {
				quality: file.size > max_file_size ? 0.8 : 1,
				width: compress_width,
				maxWidth: max_size,
				maxHeight: max_size
			}).then(function (result) {
				$timeout(function () {
					deferred.resolve(result);
				});
			}).catch(function () {
				$timeout(function () {
					deferred.resolve(file);
				});
			});
		} else {
			deferred.resolve(file);
		}

		return deferred.promise;
	};

	//#endregion
}
