import { Pipe, PipeTransform, ModuleWithComponentFactories } from '@angular/core';

import * as moment from 'moment';

@Pipe({
  name: 'extendedDate'
})
export class ExtendedDatePipe implements PipeTransform {
  transform(date: any): string {
    return moment(date).fromNow();
  }
}
