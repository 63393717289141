export function EditorDriverDetailsService($http) {
	this.get = function (driverId) {
		return $http.get(constants.API_PATH + '/api/driver/' + driverId + '/details');
	};

	this.getDriverOwners = function () {
		return $http.get(constants.API_PATH + '/api/user/owners');
	};

	this.put = function (driverId, data) {
		var headers = {
			'Content-Type': 'application/json'
		};

		return $http.put(constants.API_PATH + '/api/driver/' + driverId + '/details', data, { headers: headers });
	};
}
