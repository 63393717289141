import template from './editor-properties.component.html';
import './editor-properties.component.css';

export const EditorPropertiesComponent = {
	template: template,
	bindings: {
		initialData: '<',
		onUpdate: '<'
	},
	controller: function () {
		var $ctrl = this;

		$ctrl.$onInit = function () {
			if (!$ctrl.initialData) {
				$ctrl.init({ type: 'none' });
			}

			$ctrl.colorPickerOptions = {
				materialPalette: false,
				hsl: false,
				openOnInput: true,
				history: false
			};
		};

		$ctrl.$onChanges = function (changes) {
			var initialData = changes.initialData;

			if (initialData && initialData.currentValue) {
				$ctrl.init(initialData.currentValue);
			}
		};

		$ctrl.$onDestroy = function () {
			if ($ctrl.editor) {
				$ctrl.destroyEditor($ctrl.editor);
			}
		};

		$ctrl.init = function (data) {
			$ctrl.elementType = data.type;

			switch ($ctrl.elementType) {
			case 'background':
				$ctrl.backgroundColor = data.backgroundColor;
				$ctrl.backgroundImageUrl = data.imageUrl;

				break;
			case 'element':
				$ctrl.hasText = data.hasText;
			case 'link':
				$ctrl.backgroundColor = data.backgroundColor;
				$ctrl.backgroundImageUrl = data.imageUrl;

				if ($ctrl.elementType === 'link') {
					$ctrl.elementHref = data.href;
				}

				break;
			case 'image':
				$ctrl.imageUrl = data.imageUrl;

				break;
			}
		};

		$ctrl.update = function () {
			if ($ctrl.onUpdate && $ctrl.elementType) {
				var properties = {};

				switch ($ctrl.elementType) {
				case 'background':
					properties.backgroundColor = $ctrl.backgroundColor;
					properties.backgroundImageUrl = $ctrl.backgroundImageUrl;

					break;
				case 'element':
				case 'link':
					properties.backgroundColor = $ctrl.backgroundColor;
					properties.backgroundImageUrl = $ctrl.backgroundImageUrl;

					if ($ctrl.elementType === 'link') {
						properties.href = $ctrl.elementHref;
					}

					break;
				case 'image':
					properties.imageUrl = $ctrl.imageUrl;

					break;
				}

				$ctrl.onUpdate(properties);
			}
		};

		// $ctrl.init = function (data) {
		// 	var type = data.type;
		//  var animationShow = data.animationShow;
		// 	var setActiveTab = true;

		// 	$ctrl.text = data.text;
		// 	$ctrl.videoUrl = data.videoUrl ? data.videoUrl : '';
		// 	$ctrl.backgroundColor = data.backgroundColor ? data.backgroundColor : '#FFFFFF';
		// 	$ctrl.imageUrl = data.imageUrl ? data.imageUrl : '';

		// 	$ctrl.panelBackgroundColor = data.panelBackgroundColor ? data.panelBackgroundColor : '#FFFFFF';
		// 	$ctrl.panelBackgroundImageUrl = data.panelBackgroundImageUrl ? data.panelBackgroundImageUrl : '';

		// 	$ctrl.elementType = type;
	
		// 	// $ctrl.elementAnimation = data.animation;
		// 	switch (type) {
		// 		case 'element':
		// 			$ctrl.panelType = 'text';

		// 			if ($ctrl.editor) {
		// 				$ctrl.destroyEditor($ctrl.editor);

		// 				delete $ctrl.editor;
		// 			}

		// 			$timeout(function () {
		// 				$ctrl.editor = $element.find('.snap-editor-property');
		// 				$ctrl.initTextEditor($ctrl.editor, $ctrl.text);
		// 			});
		// 			break;
		// 		case 'image':
		// 			$ctrl.panelType = 'image';
		// 			break;
		// 		case 'video':
		// 			$ctrl.panelType = 'video';
		// 			break;
		// 		default:
		// 			setActiveTab = false;
		// 			$ctrl.panelType = 'panel_image';
		// 			break;
		// 	}

		// 	if (setActiveTab) {
		// 		$timeout(function () {
		// 			var tabs_cont = $element.children('.snap-tabs-cont');

		// 			tabs_cont.find('.flat-butt').removeClass('active1');
		// 			tabs_cont.find('.snap-second-row .flat-butt').first().addClass('active1');
		// 		});
		// 	}
		// };


		// $ctrl.selectPanel = function ($event, type) {
		// 	var active_tab = $event.target;

		// 	$element.find('.snap-tabs-cont .flat-butt').removeClass('active1');
		// 	$(active_tab).addClass('active1');

		// 	if ($ctrl.panelType !== type) {
		// 		if ($ctrl.editor) {
		// 			$ctrl.destroyEditor($ctrl.editor);

		// 			delete $ctrl.editor;
		// 		}

		// 		$ctrl.panelType = type;
		// 		$timeout(function () {
		// 			$ctrl.editor = $element.find('.snap-editor-property');

		// 			if (type === 'text') {
		// 				$ctrl.initTextEditor($ctrl.editor, $ctrl.text);
		// 			}
		// 		});
		// 	}
		// };

		// $ctrl.updateProperties = function () {
		// 	var data = {};

		// 	switch ($ctrl.panelType) {
		// 		case 'panel_color':
		// 			data.panelBackgroundColor = $ctrl.panelBackgroundColor;
		// 			break;
		// 		case 'panel_image':
		// 			data.panelBackgroundImageUrl = $ctrl.panelBackgroundImageUrl;
		// 			break;
		// 		case 'color':
		// 			data.backgroundColor = $ctrl.backgroundColor;
		// 			break;
		// 		case 'text':
		// 			data.text = $ctrl.editor.summernote('code');
		// 			data.animation = $ctrl.elementAnimation;
		// 			data.textGradient1 = $ctrl.gradientColor1;
		// 			data.textGradient2 = $ctrl.gradientColor2;
		// 			break;
		// 		case 'video':
		// 			data.videoUrl = $ctrl.videoUrl;
		// 			break;
		// 		case 'image':
		// 			data.imageUrl = $ctrl.imageUrl;
		// 			break;
		// 	}

		// 	if ($ctrl.onUpdate) {
		// 		$ctrl.onUpdate(data);
		// 	}
		// };

	},
	controllerAs: '$ctrl'
};
