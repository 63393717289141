export function NotifyService($http, $timeout, $mdToast) {
	var that = this;

	this.setParent = function (parent) {
		this.parent = parent;
	};

	this.message = function (message, action) {
		this.notify(message, false, action);
	};

	this.error = function (message, action) {
		this.notify(message, true, action);
	};

	this.notify = function (message, isError, action) {
		var toast_message = message;

		if (isError) {
			toast_message = message ? message : 'Something went wrong.';
		}

		var toast = $mdToast.simple()
			.textContent(toast_message)
			.hideDelay(3000);

		if (this.parent) {
			$(that.parent).css('display', 'block');
			toast.parent(this.parent);
		}

		if (action && action.name) {
			toast
				.action(action.name)
				.highlightAction(true)
				.highlightClass(isError ? 'md-warn' : 'md-accent');
		}

		$timeout(function () {
			$mdToast.show(toast).then(function (response) {
				$(that.parent).css('display', 'none');

				if (response === 'ok' && action && action.proceed) {
					action.proceed();
				}
			}).catch(function () { });
		});
	};
}
