import template from './phone-preview.component.html';
import './phone-preview.component.css';

export const PhonePreviewComponent = {
	template: template,
	bindings: {
		html: '<',
		elements: '<',
		modelName: '<',
		changedProperties: '<',
		isFirstPanel: '<',
		isLastPanel: '<',
		isLandscape: '<',
		panelIndex: '<',
		uniqueAttr: '<',
		onSelect: '<',
		onHtmlChange: '<',
		onModelChange: '<',
		onBackgroundUpdate: '<'
	},
	controller: function () {
		var $ctrl = this;

		$ctrl.$onInit = function () {
			$ctrl.models = {
				'iphone_5-se': {
					phone: {
						width: 384,
						height: 802.7,
						url: 'iphone_5-se_320x480.png'
					},
					screen: {
						left: 33.4,
						top: 183,
						width: 320,
						height: 460
					}
				},
				'iphone_6-8': {
					phone: {
						width: 436.2,
						height: 885.6,
						url: 'iphone_6-8_375x559.png'
					},
					screen: {
						left: 30.6,
						top: 177.6,
						width: 375,
						height: 559
					}
				},
				'iphone_6-8plus': {
					phone: {
						width: 479,
						height: 960,
						url: 'iphone_6-8plus_414x628.png'
					},
					screen: {
						left: 33,
						top: 178.5,
						width: 414,
						height: 628
					}
				},
				'iphone_X': {
					phone: {
						width: 432,
						height: 866.4,
						url: 'iphone_X_375x633.png'
					},
					screen: {
						left: 28.8,
						top: 121.8,
						width: 375,
						height: 633
					}
				},
				'samsung_galaxy_s6-s7edge': {
					phone: {
						width: 383,
						height: 785.4,
						url: 'galaxy_s6-s7edge_360x526.png'
					},
					screen: {
						left: 11.7,
						top: 145,
						width: 360,
						height: 526
					}
				},
				'samsung_galaxy_s8-s9+': {
					phone: {
						width: 382.9,
						height: 822.2,
						url: 'galaxy_s8-s9+_360x568.png'
					},
					screen: {
						left: 11.4,
						top: 113.6,
						width: 360,
						height: 568
					}
				}
			};

			$ctrl.modelName = $ctrl.modelName && $ctrl.models[$ctrl.modelName] ? $ctrl.modelName : 'iphone_6-8';

			// $ctrl.isLandscape = true;
			$ctrl.model = $ctrl.composePhoneModel($ctrl.models[$ctrl.modelName], $ctrl.isLandscape);

			if ($ctrl.onModelChange) {
				$ctrl.onModelChange($ctrl.model.screenSize.width, $ctrl.model.screenSize.height);
			}
		};

		$ctrl.$onChanges = function (changes) {
			var model_name = changes.modelName;
			var isLandscape = changes.isLandscape;

			if (model_name && model_name.currentValue && !model_name.isFirstChange()) {
				if ($ctrl.models[model_name.currentValue]) {
					$ctrl.model = $ctrl.composePhoneModel($ctrl.models[model_name.currentValue], $ctrl.isLandscape);
				}

				if ($ctrl.onModelChange) {
					$ctrl.onModelChange($ctrl.model.screenSize.width, $ctrl.model.screenSize.height);
				}
			} else if (isLandscape && !isLandscape.isFirstChange()) {
				if ($ctrl.models[$ctrl.modelName]) {
					$ctrl.model = $ctrl.composePhoneModel($ctrl.models[$ctrl.modelName], isLandscape.currentValue);
				}

				if ($ctrl.onModelChange) {
					$ctrl.onModelChange($ctrl.model.screenSize.width, $ctrl.model.screenSize.height);
				}
			}
		};

		$ctrl.composePhoneModel = function (model, isLandscape) {
			var phoneModel = {};

			if (isLandscape) {
				phoneModel = {
					phoneUrl: model.phone.url,
					phoneWidth: model.phone.height + 'px',
					phoneHeight: model.phone.width + 'px',
					screenPosition: {
						left: model.screen.top + 'px',
						bottom: model.screen.left + 'px'
					},
					screenSize: {
						width: model.screen.height,
						height: model.screen.width
					}
				};

				phoneModel.modelWidth = phoneModel.phoneHeight;
				phoneModel.modelHeight = phoneModel.phoneWidth;
			} else {
				phoneModel = {
					phoneUrl: model.phone.url,
					phoneWidth: model.phone.width + 'px',
					phoneHeight: model.phone.height + 'px',
					screenPosition: {
						left: model.screen.left + 'px',
						top: model.screen.top + 'px'
					},
					screenSize: {
						width: model.screen.width,
						height: model.screen.height
					}
				};

				phoneModel.modelWidth = phoneModel.phoneWidth;
				phoneModel.modelHeight = phoneModel.phoneHeight;
			}

			return phoneModel;
		};
	},
	controllerAs: '$ctrl'
};
