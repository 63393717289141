module.exports = {
	// API_PATH: 'http://ec2-34-209-6-244.us-west-2.compute.amazonaws.com:8091',
	// AUTH_PATH: 'http://ec2-35-155-197-135.us-west-2.compute.amazonaws.com:8091',
	API_PATH: process.env.API_PATH,
	AUTH_PATH: process.env.AUTH_PATH,
	SNAP_PROD_URL: process.env.SNAP_PROD_URL,
	DRIVER_PROD_URL: process.env.DRIVER_PROD_URL,
	BANNER_PROD_URL: process.env.BANNER_PROD_URL,
	BANNER_CREATOPY_PROD_URL: process.env.BANNER_CREATOPY_PROD_URL,
};
