import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CodeEditorService {

  constructor() { }

  addPrefixToCSS(styleElement: HTMLStyleElement, prefix: string, rootSelectors: string[]): string {
    const cssRules = this.getCSSStyleRules((styleElement.sheet as CSSStyleSheet).cssRules);
    let cssString = styleElement.textContent;

    const cssRootSelector = rootSelectors.map(s => this.stringToRegExp(s)).join('|');
    const prefixRegExp = new RegExp(`^(${cssRootSelector})?${this.stringToRegExp(prefix)}(\\s|$)`, 'i');
    const rootRegExp = new RegExp(`^(${cssRootSelector})$`, 'i');

    for (let i = 0; i < cssRules.length; i++) {
      const selectors = cssRules[i].selectorText.split(', ');

      for (let j = 0; j < selectors.length; j++) {
        const selectorAnchors = selectors[j].split(' ');

        if (selectorAnchors.length === 0 || prefixRegExp.test(selectors[j])) {
          continue;
        }

        const anchorRegExpCore = selectorAnchors.map(s => this.stringToRegExp(s)).join('(\\s+)?');
        const anchorRegExp = new RegExp(`(^(\\s+)?)${anchorRegExpCore}((\\s+)?(,|\\{))`, 'gim');
        const matchRegExp = new RegExp(anchorRegExpCore, 'i');

        if (rootRegExp.test(selectorAnchors[0])) {
          const replaceRegExp = new RegExp(`^${this.stringToRegExp(selectorAnchors[0])}\\s?`, 'i');

          cssString = cssString.replace(anchorRegExp, fullMatch => {
            const pureSelector = selectors[j].replace(replaceRegExp, '');

            return fullMatch.replace(matchRegExp, `${selectorAnchors[0]}${prefix} ${pureSelector}`);
          });
        } else {
          cssString = cssString.replace(anchorRegExp, fullMatch => {
            return fullMatch.replace(matchRegExp, `${prefix} ${selectors[j]}`);
          });
        }
      }
    }

    return cssString;
  }

  getCSSStyleRules(cssRules: CSSRuleList): Array<CSSStyleRule> {
    let cssStyleRules = [];

    for (let i = 0; i < cssRules.length; i++) {
      const currentRule = cssRules[i] as any;

      //FIREFOX       
      if (currentRule.cssRules === undefined) {
        if (currentRule.cssRules !== null && currentRule.cssRules instanceof CSSRuleList) {
          const childStyleRules = this.getCSSStyleRules(currentRule.cssRules);
          cssStyleRules = cssStyleRules.concat(childStyleRules);
        }
        else if (currentRule instanceof CSSStyleRule) {
          cssStyleRules.push(currentRule);
        }
        return cssStyleRules;
      }       
      //CHROME       
      if (currentRule.cssRules.length !== 0 && currentRule.cssRules instanceof CSSRuleList) {
        const childStyleRules = this.getCSSStyleRules(currentRule.cssRules);
        cssStyleRules = cssStyleRules.concat(childStyleRules);
      }
      else if (currentRule instanceof CSSStyleRule) {
        cssStyleRules.push(currentRule);
      }

    }

    return cssStyleRules;
  }

  stringToRegExp(str: string): string {
    const symbols = ['\\', '/', '.', '(', ')', '[', ']', '^', '*', ':'];
    let regExpStr = str;

    for (let i = 0; i < symbols.length; i++) {
      regExpStr = regExpStr.replace(symbols[i], `\\${symbols[i]}`);
    }

    return regExpStr;
  }
}