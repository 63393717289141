import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';

import { IMLPageDialogRef } from 'src/app/shared/iml-page-dialog/iml-page-dialog.service';
import { EditorDriverHeatmapsService } from 'src/app/services/editor-driver-heatmaps.service';
import { NotifyService } from 'src/app/services/notify.service';

import { first, timestamp } from 'rxjs/operators';
import $, { data } from "jquery";

const html2canvas = require('html2canvas');
import { slice } from 'angular-route';

@Component({
	selector: 'editor-driver-heatmaps',
	template: require('./editor-driver-heatmaps.component.html'),
	styles: [require('./editor-driver-heatmaps.component.a.css')],
	encapsulation: ViewEncapsulation.None
})

export class EditorDriverHeatmapsComponent implements OnInit, OnDestroy {
	@ViewChild('virtualViewport', { static: true }) virtualViewport: ElementRef;

	collectionTimeout = null;
	isLoading = true;
	noHeatmaps = true;
	driverHeatmaps = [];
	isCentered = true;
	refreshTimestamp = null;
	waitingForJob = false;
	show = true;

	imageSrc = '';
	driverWidth = '375';
	driverHeight = '559';

	deviceHeatmapsWidth: string = '';
	deviceHeatmapsHeight: string = '';
	devicePreviewWidth: string = '';
	devicePreviewHeight: string = '';

	@Input() driverId: number;
	@Output() afterClosed = new EventEmitter<void>();

	constructor(
		private dialogRef: IMLPageDialogRef,
		private el: ElementRef,
		public notify: NotifyService,
		public editorHeatmaps: EditorDriverHeatmapsService,
		public dialog: MatDialog
	) { }

	ngOnInit() {
		const element = this.el.nativeElement;
		this.getHeatmaps();
		setTimeout(() => {
			element.children[0].classList.remove("nd-list-hidden");
		}, 500);
	}

	ngOnDestroy() {
		if (this.collectionTimeout) {
			clearTimeout(this.collectionTimeout);
		}
	}

	/**
	 * get Heatmaps
	 */
	getHeatmaps() {
		this.isLoading = true;
		this.editorHeatmaps.get(this.driverId)
			.then((response) => {
				this.setHeatmaps(response.data.heatmaps);
				this.isLoading = false;
			})
			.catch(() => {
				this.notify.message('Something went wrong.', true);
			});
	};

	setHeatmaps = function (heatmapsUrls) {
		let noHeatmapsAmount = 0;
		let url = null;
		this.driverHeatmaps = [];
		for (let i = 0; i < heatmapsUrls.length; i++) {
			url = heatmapsUrls[i];
			if (typeof url === 'undefined' || url === null) {
				noHeatmapsAmount++;
				url = null;
			} else if (!/^https?:\/\//i.test(url)) {
				url = 'https://' + url;
			}
			this.driverHeatmaps.push(url);
		}
		if (noHeatmapsAmount >= this.driverHeatmaps.length) {
			this.noHeatmaps = true;
		} else {
			this.noHeatmaps = false;
		}
		this.refreshTimestamp = Date.now();
		if (this.driverHeatmaps.length < 4) {
			this.isCentered = true;
		} else {
			this.isCentered = false;
		}
	};

	copyToClipboard = function (url) {
		let input = $('<input style="position: fixed; transform: translateX(-100%)">');
		input.attr('value', url);
		document.body.appendChild(input[0]);
		input.select();
		let success = document.execCommand('copy');
		if (success) {
			this.notify.message('Heatmap link was copied to clipboard.');
		} else {
			this.notify.message('Failed to copy link to clipboard.', true);
		}
		input.remove();
	};

	/**
	 * show list of drivers
	 */
	showList = function () {
		// this.show = true;
	}

	/**
	 * hide list of drivers
	 */
	hideList = function () {
		// this.show = false;
	}

	/**
	 * send data to redis queue on taskType Preview
	 * @param driverId 
	 * @param width 
	 * @param height 
	 */
	sendPreview = function (driverId, width, height) {
		let dataPreview = document.getElementsByClassName('selector-preview');
		dataPreview[0].setAttribute("style", "display: block;");

		if (width && height != '') {

			dataPreview[0].setAttribute("style", "display: none;");

			this.editorHeatmaps.requestJobPreview(driverId, width, height)
				.then((response) => {
					this.devicePreviewWidth = '';
					this.devicePreviewHeight = '';
					this.collectionTimeout = setTimeout(() => {
						this.collectJobPreviewResults(response.data.jobId);
					}, 3000);
					this.notify.message('Generation successfully requested. Waiting for results.');
				})
				.then(() => {
					this.driverWidth = width;
					this.driverHeight = height;
				})
				.catch(() => {
					this.waitingForJob = false;
					this.notify.message("Wasn't able to request heatmap generation.", true);
				});

		} else {
			this.notify.message('Please type width and height')
		}
	}

	collectJobPreviewResults = function (jobId) {
		this.editorHeatmaps.collectJobPreview(jobId)
			.then((response) => {
				if (response.data.status === 'COMPLETED' && response.data.preview) {
					this.setHeatmaps(response.data.preview);
					this.waitingForJob = false;
					this.notify.message('Preview generation results successfully collected.');
				} else {
					this.collectionTimeout = setTimeout(() => {
						this.collectJobPreviewResults(jobId);
					}, 5000);
				}
			})
			.catch(() => {
				this.waitingForJob = false;
				this.notify.message("Wasn't able to collect heatmap generation results.", true);
			});
	};

	/**
	 * send data to redis queue on taskType Heatmaps
	 * @param driverId 
	 * @param width 
	 * @param height 
	 */
	sendHeatmaps = function (driverId, width, height) {
		let dataHeatmaps = document.getElementsByClassName('selector-heatmaps');
		dataHeatmaps[0].setAttribute("style", "display: block;");

		if (width && height != '') {

			dataHeatmaps[0].setAttribute("style", "display: none;");

			this.editorHeatmaps.requestJob(driverId, width, height)
				.then((response) => {
					this.deviceHeatmapsWidth = '';
					this.deviceHeatmapsHeight = '';
					this.collectionTimeout = setTimeout(() => {
						this.collectJobResults(response.data.jobId);
					}, 3000);
					this.notify.message('Generation successfully requested. Waiting for results.');
				})
				.then(() => {
					this.driverWidth = width;
					this.driverHeight = height;
				})
				.catch(() => {
					this.waitingForJob = false;
					this.notify.message("Wasn't able to request heatmap generation.", true);
				});

		} else {
			this.notify.message('Please type width and height')
		}
	}





	//--------------------------------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------------------------------

	/**
	 * make screenshot of Driver
	 * @param bool 
	 */
	makeScreenshot = function () {
		html2canvas(document.querySelector("#driver"), { allowTaint: true, useCORS: true })
			.then(canvas => {
				if (document.getElementsByTagName('canvas')[0] === undefined) {

					const dialog = document.getElementById('dialog') as HTMLDialogElement;
					dialog.show();

					if (this.driverWidth < this.driverHeight) {
						canvas.style.marginTop = "5%";
						canvas.style.marginLeft = "1%";
					} else {
						canvas.style.marginTop = "5%";
					}

					canvas.style.cursor = "pointer";

					document.body.appendChild(canvas);

					const footer = document.getElementById('dialogFooter');
					footer.parentNode.insertBefore(canvas, footer);
				}
			})
	}

	/**
	 * Download screesnshot
	 */
	Download = function (src) {
		let filename = src + '?' + this.refreshTimestamp;
		const canvas = document.getElementsByTagName("canvas")[0];
		let data = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
		let link = document.createElement('a');
		link.setAttribute('href', data);
		link.setAttribute('download', btoa(filename) + '.png');
		link.click();
	}

	/**
	 * Close dialog with canvas
	 */
	CloseDialog = function () {
		const canvas = document.getElementsByTagName('canvas')[0];
		const footer = document.getElementById('dialogFooter');
		footer.parentNode.removeChild(canvas);

		const dialog = document.getElementById('dialog') as HTMLDialogElement;
		dialog.close();
	}

	/**
	 * close heatmaps width\height inputs
	 */
	closeHeatmaps = function () {
		let dataHeatmaps = document.getElementsByClassName('selector-heatmaps');
		dataHeatmaps[0].setAttribute("style", "display: none;");
	}

	/**
	 * close preview width\height inputs
	 */
	closePreview = function () {
		let dataPreview = document.getElementsByClassName('selector-preview');
		dataPreview[0].setAttribute("style", "display: none;");
	}

	/**
	 * change driver image 
	 * @param data 
	 */
	onClick(data) {
		this.imageSrc = data;
	}

	setZoom = function () {
		const e = document.getElementById("selectZoom") as HTMLSelectElement;
		const value: any = e.options[e.selectedIndex].value;

		if (value != 'auto') {
			let min = Math.min(375, 559);
			let result = Math.max(min * (parseInt(value) / 100));

			let w = (result / min) * 375;
			let h = (result / min) * 559;

			this.driverWidth = w.toString().split('.')[0];
			this.driverHeight = h.toString().split('.')[0];
		} else {
			this.driverWidth = '375';
			this.driverHeight = '559';
		}

	}

	/**
	 * rotate driver image
	 */
	rotateImg = function () {
		let img = document.getElementById('driver');

		this.driverWidth = img.clientHeight;
		this.driverHeight = img.clientWidth;

	}

	getDialogWidth = function () {
		return parseInt(this.driverWidth) + 20 + 'px';
	}
	getDialogHeight = function () {
		if (parseInt(this.driverHeight) < 730) {
			return parseInt(this.driverHeight) + 20 + 'px';
		} else {
			return '750px';
		}
	}

	//--------------------------------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------------------------------





	/**
	 * Get url for list of driver images 
	 * @param url 
	 */
	getHeatmapUrl = function (url) {
		if (url) {
			return 'url(' + url + '?' + this.refreshTimestamp + ')';
		}
		return '';
	};

	hasNoHeatmap = function (url) {
		return url === null;
	};

	requestGeneration = function (driverId) {
		this.waitingForJob = true;
		this.editorHeatmaps.requestJob(driverId)
			.then((response) => {
				this.collectionTimeout = setTimeout(() => {
					this.collectJobResults(response.data.jobId);
				}, 3000);
				this.notify.message('Generation successfully requested. Waiting for results.');
			})
			.catch(() => {
				this.waitingForJob = false;
				this.notify.message("Wasn't able to request heatmap generation.", true);
			});
	};

	collectJobResults = function (jobId) {
		this.editorHeatmaps.collectJob(jobId)
			.then((response) => {
				if (response.data.status === 'COMPLETED' && response.data.heatmaps) {
					this.setHeatmaps(response.data.heatmaps);
					this.waitingForJob = false;
					this.notify.message('Heatmap generation results successfully collected.');
				} else {
					this.collectionTimeout = setTimeout(() => {
						this.collectJobResults(jobId);
					}, 5000);
				}
			})
			.catch(() => {
				this.waitingForJob = false;
				this.notify.message("Wasn't able to collect heatmap generation results.", true);
			});
	};

	/**
	 * Close Driver Heatmaps dialog
	 */
	closeDialog() {
		this.el.nativeElement.children[0].classList.add("nd-list-hidden");
		this.dialogRef.afterClosed()
			.pipe(
				first()
			)
			.subscribe(() => {
				this.afterClosed.emit();
			});
		this.dialogRef.close();
	};

}
