export function FileSizeFilter() {
	var that = this;

	this.formatBytes = function (raw_bytes) {
		var chunk = raw_bytes % 1000;
		var chunks = [];

		while (chunk > 0) {
			raw_bytes = Math.floor(raw_bytes / 1000);
			chunks.unshift(chunk);
			chunk = raw_bytes % 1000;
		}

		return chunks.join(',') + ' bytes';
	};

	return function (size, no_bytes) {
		var kb = 1024;
		var mb = kb * 1024;
		var bytes_size = '';

		if (size > mb) {
			var mb_size = Math.round(size / mb) + ' MB';

			if (!no_bytes) {
				bytes_size = that.formatBytes(size);
				mb_size += ' (' + bytes_size + ')';
			}

			return mb_size;
		} else if (size > kb) {
			var kb_size = Math.round(size / kb) + ' KB';

			if (!no_bytes) {
				bytes_size = that.formatBytes(size);
				kb_size += ' (' + bytes_size + ')';
			}

			return kb_size;
		} else {
			if (size == 0) {
				bytes_size = '0 bytes';
			} else {
				bytes_size = that.formatBytes(size);
			}

			return bytes_size;
		}
	};
}
