import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LazyService {
  private loadPromise: Promise<typeof import('monaco-editor')>;

  constructor() {
    this.loadPromise = new Promise(resolve => {
      // Dynamic import
      import('monaco-editor').then((monaco) => {
        resolve(monaco);
      });
    });
  }

  waitForEditor() {
    return this.loadPromise;
  }
}
