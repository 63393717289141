import template from './editor-driver-details.component.html';
import './editor-driver-details.component.css';

var EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

export const EditorDriverDetailsComponent = {
	template: template,
	bindings: {
		onAfterClose: '<',
		driverDetails: '<',
		driverId: '<',
		onUpdateDetails: '<'
	},
	controller: function ($element, $timeout, EditorDriverDetailsService, NotifyService) {
		var $ctrl = this;

		$ctrl.colorPickerOptions = {
			materialPalette: false,
			openOnInput: true,
			history: false,
			clickOutsideToClose: true,
			disableClose: false
		};

		this.$onInit = function () {
			$ctrl.driverEmails = [];

			$ctrl.isLoading = true;
			EditorDriverDetailsService.getDriverOwners().then(function (response) {
				$ctrl.driverOwners = response.data.users;

				return EditorDriverDetailsService.get($ctrl.driverId);
			}).then(function (response) {
				$ctrl.DriverProperties = response.data;
				$ctrl.DriverProperties.ownerId = response.data.owner ? response.data.owner.id : '';
				$ctrl.DriverProperties.iconUrl = typeof response.data.iconUrl === 'string' ? response.data.iconUrl : '';

				$ctrl.isLoading = false;
				$ctrl.setEmails($ctrl.DriverProperties.emails);
			}).catch(function (error) {
				console.log('ERROR', error);
			});

			$timeout(function () {
				$element.children('.nd-driver-properties-cont').removeClass('nd-properties-hidden');
			}, 500);
		};

		$ctrl.setEmails = function (emails) {
			$ctrl.driverEmails = [];
			$ctrl.emailsIdList = {};

			for (var i = 0; i < emails.length; i++) {
				$ctrl.driverEmails.push(emails[i].value);
				$ctrl.emailsIdList[emails[i].value] = emails[i].id;
			}
		};

		$ctrl.validateEmail = function (chip, emails) {
			var email = chip.replace(/\s/, '');

			if (email.length < 3 || !EMAIL_REGEXP.test(email)) {
				NotifyService.notify('Failed to add "' + chip + '" as it\'s not a proper email.', true);

				return null;
			}

			if (emails && emails.length > 0) {
				for (var i = 0; i < emails.length; i++) {
					if (emails[0].toLowerCase() === email) {
						NotifyService.notify('Failed to add "' + chip + '" as it\'s already in the list.', true);

						return null;
					}
				}
			}

			return email;
		};

		$ctrl.onEmailRemoved = function () {
			if (!$ctrl.driverEmails) {
				$ctrl.driverEmails = [];
			}
		};

		$ctrl.validateThemeColor = function (form) {
			if ($ctrl.DriverProperties.meta.themeColor == "#000000" || $ctrl.DriverProperties.meta.themeColor == "#ffffff") {

				form.themeColorField.$setValidity('themeColorValidation', false);

			} else {
				form.themeColorField.$setValidity('themeColorValidation', true);
			}
		};

		$ctrl.validatePath = function (form) {
			if ($ctrl.DriverPropertiesError == "path_duplicate") {
				form.pathField.$setValidity('pathValidation', false);
			} else {
				form.pathField.$setValidity('pathValidation', true);
			}
		};

		this.closeDialog = function () {
			$element.children('.nd-driver-properties-cont').addClass('nd-properties-hidden');
			$timeout(function () {
				if ($ctrl.onAfterClose) {
					$ctrl.onAfterClose();
				}
			}, 200);
		};

		$ctrl.save = function (form) {
			var emails = [];
			var email = null;

			for (var i = 0; i < $ctrl.driverEmails.length; i++) {
				email = {
					value: $ctrl.driverEmails[i]
				};

				if ($ctrl.emailsIdList[email.value]) {
					email.id = $ctrl.emailsIdList[email.value];
				}

				emails.push(email);
			}

			// Submitting the form
			form.$submitted = true;

			$ctrl.isLoading = true;
			$ctrl.DriverProperties.emails = emails;
			EditorDriverDetailsService.put($ctrl.driverId, $ctrl.DriverProperties).then(function (response) {
				var emails = response.data.emails;

				$ctrl.DriverProperties.emails = emails;
				$ctrl.onUpdateDetails($ctrl.DriverProperties);

				$ctrl.DriverPropertiesError = false;
				$ctrl.validatePath(form);

				$ctrl.isLoading = false;
				NotifyService.notify('"' + $ctrl.DriverProperties.name + '" Driver was successfuly edited.');
			}).catch(function (error) {
				$ctrl.DriverPropertiesError = error.data.key;
				$ctrl.validatePath(form);

				$ctrl.isLoading = false;
				NotifyService.notify(error.data.message, true);
			});
		};

		$ctrl.cancel = function () {
			$ctrl.closeDialog();
		};


	},
	controllerAs: '$ctrl'
};
