import constants from "../../js/constants";

export function EditorDriverHeatmapsService($http) {
	this.get = function (driverId) {
		return $http.get(constants.API_PATH + '/api/heatmaps/' + driverId);
	};

	this.requestJob = function (driverId, width, height) {
		var headers = {
			'Content-Type': 'application/json'
		};

		var data = {
			driverId: driverId,
			deviceWidth: width,
			deviceHeight: height
		};

		return $http.post(constants.API_PATH + '/api/jobs/heatmaps', data, { headers: headers });
	};

	this.collectJob = function (jobId) {
		var params = {
			jobId: jobId
		};

		return $http.get(constants.API_PATH + '/api/jobs/heatmaps', {
			params: params
		});
	};

	this.requestJobPreview = function (driverId, width, height) {
		var headers = {
			'Content-Type': 'application/json'
		};

		var data = {
			driverId: driverId,
			deviceWidth: width,
			deviceHeight: height
		};

		return $http.post(constants.API_PATH + '/api/jobs/preview', data, { headers: headers });
	};

	this.collectJobPreview = function (jobId) {
		var params = {
			jobId: jobId
		};

		return $http.get(constants.API_PATH + '/api/jobs/preview', {
			params: params
		});
	};
}
