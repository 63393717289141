import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter, OnDestroy, Input, Renderer2 } from '@angular/core';
import { trigger, transition, query, style, stagger, animate } from '@angular/animations';
import { Validators, FormControl } from '@angular/forms';
import { first, debounceTime, filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material';

import { IMLPageDialogRef } from 'src/app/shared/iml-page-dialog/iml-page-dialog.service';
import { AuthService } from 'src/app/services/auth.service';
import { NotifyService } from 'src/app/services/notify.service';
import { StorageService } from 'src/app/core/storage.service';
import { ClipboardService } from 'src/app/core/clipboard.service';

import { UserService } from 'src/app/core/auth/user.service';
import { DriverService } from 'src/app/core/driver.service';
import { BannerService } from 'src/app/core/banner.service';

import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { DialogPromptComponent } from '../dialog-prompt/dialog-prompt.component';
import { DialogEditBannerComponent } from '../dialog-edit-banner/dialog-edit-banner.component';

import { DRIVER_PROD_URL, BANNER_PROD_URL, BANNER_CREATOPY_PROD_URL } from 'src/js/constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'page-driver-list',
  template: require('./page-driver-list.component.html'),
  styles: [require('./page-driver-list.component.a.css')],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [
        query(':enter', [
          style({
            transform: 'translate3d(0, 0, 0) scale(0.2)',
            opacity: 0
          }),
          stagger(50, [
            animate('0.2s cubic-bezier(.25, .8, .25, 1)', style({
              transform: 'translate3d(0, 0, 0) scale(1)',
              opacity: 1
            }))
          ])
        ], { optional: true }),
        query('.deleted:leave', [
          style({
            transform: 'translate3d(0, 0, 0) scale(1)',
            opacity: 1
          }),
          stagger(100, [
            animate('0.2s cubic-bezier(.25, .8, .25, 1)', style({
              transform: 'translate3d(0, 0, 0) scale(0.4)',
              opacity: 0
            })),
          ])
        ], { optional: true })
      ])
    ])
  ]
})
export class PageDriverListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('storiesList', { static: true }) storiesList: ElementRef;

  private isSearching = false;
  private search = new FormControl('');

  private initialStoryType = 'driver';
  private storyType = 'driver';
  private userId: string;
  private userState: string;
  private currentUser: any;
  private companyUsers = [];

  private stories = [];
  private limitReached = false;
  private nextPage: string;

  private isLoading = false;
  private isLoadingProgress = false;
  private selectedId = null;
  private scrollPosition = 0;
  private renderAnimations = true;

  private bannerCreated: Subscription;
  private driverCreated: Subscription;
  private searchChanged: Subscription;

  @Input() showCloseButton: boolean;

  @Output() afterClosed = new EventEmitter<void>();
  @Output() driverSelected = new EventEmitter<any>();
  @Output() driverDeleted = new EventEmitter<any>();

  private isAutomated = false;

  private get isBannerList() { return this.storyType === 'banner'; }
  private get isDriverList() { return this.storyType === 'driver'; }
  private get isCompanyState() { return this.userState === 'company'; }
  private get storiesAmount() { return this.renderAnimations ? this.stories.length : 0; }

  constructor(
    private dialogRef: IMLPageDialogRef,
    private auth: AuthService,
    private user: UserService,
    private banner: BannerService,
    private driver: DriverService,
    private storage: StorageService,
    private clipboard: ClipboardService,
    private notify: NotifyService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    const user = this.auth.getUser();

    this.currentUser = user;
    this.userState = user.id;
    // this.users = [user];
    this.userId = user.id;

    // Memory Snapshot -----
    const snapshot = JSON.parse(this.storage.session.getItem('pdl:snapshot'));

    this.storage.session.removeItem('pdl:snapshot');
    if (snapshot && Array.isArray(snapshot.stories) && snapshot.stories.length > 0) {
      this.currentUser = snapshot.currentUser;
      this.companyUsers = snapshot.companyUsers;

      this.storyType = snapshot.storyType;
      this.userState = snapshot.userState;
      this.isSearching = snapshot.isSearching;
      this.isAutomated = snapshot.isAutomated;
      this.selectedId = snapshot.selectedId;
      this.renderAnimations = false;

      // Initial Story Type
      this.initialStoryType = this.isAutomated && this.storyType === 'driver' ? 'auto-driver' : this.storyType;

      if (!this.isSearching && !this.isCompanyState) {
        this.userId = this.userState;
      }

      if (this.isSearching) {
        this.search.setValue(snapshot.searchValue, {
          emitEvent: false
        });
      }

      this.stories = snapshot.stories;
      this.nextPage = snapshot.nextPage;
      this.limitReached = snapshot.limitReached;

      this.scrollPosition = snapshot.scrollPosition;
    } else {
      this.setListState();
    }
    // ----------------------

    this.getUsers().then(result => {
      const { currentUser, companyUsers } = result;

      this.companyUsers = companyUsers;
      this.currentUser = currentUser;
    });

    this.bannerCreated = this.banner.created.subscribe(created => {
      if (this.isBannerList && created.user.id === this.currentUser.id) {
        this.stories.unshift(created);
      }

      this.notify.message(`"${created.name}" Banner successfully created.`);
    });

    this.driverCreated = this.driver.created.subscribe(created => {
      if (this.isDriverList && created.user.id === this.currentUser.id) {
        this.stories.unshift(created);
      }

      this.notify.message(`"${created.name}" Banner successfully created.`);
    });

    this.searchChanged = this.search.valueChanges
      .pipe(
        debounceTime(500)
      )
      .subscribe(value => {
        if (value.length > 0) {
          this.isSearching = true;
          this.userState = 'search';
        } else {
          this.isSearching = false;
          this.userState = 'company';
        }

        this.setListState();
      });
  }

  ngAfterViewInit() {
    this.storiesList.nativeElement.scrollTop = this.scrollPosition;
    this.renderAnimations = true;
  }

  ngOnDestroy() {
    this.bannerCreated.unsubscribe();
    this.driverCreated.unsubscribe();
    this.searchChanged.unsubscribe();

    const snapshot = {
      userState: this.userState,
      storyType: this.storyType,
      stories: this.stories,
      nextPage: this.nextPage,
      limitReached: this.limitReached,
      scrollPosition: this.scrollPosition,
      selectedId: this.selectedId,
      isAutomated: this.isAutomated,
      isSearching: this.isSearching,
      searchValue: this.search.value,
      currentUser: this.currentUser,
      companyUsers: this.companyUsers
    };

    this.storage.session.setItem('pdl:snapshot', JSON.stringify(snapshot));
  }

  close() {
    this.dialogRef.afterClosed()
      .pipe(
        first()
      )
      .subscribe(() => {
        this.afterClosed.emit();
      });

    this.dialogRef.close();
  }

  setStoryType(event) {
    if (event.value === 'auto-driver') {
      this.storyType = 'driver';
      this.isAutomated = true;
    } else {
      this.storyType = event.value;
      this.isAutomated = false;
    }

    this.setListState();
  }

  async openDriver(driver: any) {
    driver.inProgress = true;
    this.selectedId = driver.id;

    try {
      const { driver: loadedDriver } = await this.driver.get(driver.id).toPromise();

      driver.inProgress = false;
      // Timeout in order to unload main thread
      setTimeout(() => {
        loadedDriver.snap_id = driver.snap_id; // TODO: fix /api/driver/get to return snap_id

        this.driverSelected.emit(loadedDriver);
        this.selectedId = parseInt(loadedDriver.id, 10); // TODO: fix /api/driver/get to return id as number
        this.close();
      }, 100);
    } catch (error) {
      driver.inProgress = false;
      this.selectedId = null;

      this.notify.error('Failed to load the Driver.');
    }
  }

  copyToClipboard(value) {
    const success = this.clipboard.copy(value);

    if (success) {
      this.notify.message('Link was copied to clipboard.');
    } else {
      this.notify.error('Failed to copy link to clipboard.');
    }
  }

  async copyDriver(driver) {
    this.notify.message(`Copying "${driver.name}" Driver...`);

    try {
      const { driver: newDriver } = await this.driver.copy(driver.id).toPromise();

      if (this.isDriverList && this.userId === this.currentUser.id) {
        newDriver.preview_url = newDriver.preview_url ? newDriver.preview_url : driver.preview_url;
        this.stories.unshift(this.formatDriver(newDriver));
      }

      this.notify.message(`"${driver.name}" Driver was successfuly copied.`);
    } catch (error) {
      this.notify.error(`Failed to copy "${driver.name}" Driver.`);
    }
  }

  async renameDriver(driver) {
    const oldName = driver.name;
    const dialogRef = this.dialog.open(DialogPromptComponent, {
      width: '350px',
      data: {
        title: 'Rename Driver',
        input: {
          name: 'Driver Name',
          value: driver.name,
          validators: [Validators.required, Validators.minLength(3), Validators.maxLength(50)]
        },
        okButton: 'RENAME'
      }
    });

    const newName = await dialogRef.afterClosed().toPromise();
    if (typeof newName !== 'string' || newName.length === 0) {
      return;
    }

    driver.name = newName;
    try {
      const { driver: updated } = await this.driver.rename(driver.id, newName).toPromise();

      driver.name = updated.name;
      this.notify.message(`"${driver.name}" Driver was successfuly renamed.`);
    } catch (error) {
      driver.name = oldName;
      this.notify.error(`Failed to rename "${driver.name}" Driver.`);
    }
  }

  async deleteDriver(driver) {
    const userId = this.userId;
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      autoFocus: false,
      data: {
        title: 'Delete driver?',
        supportingText: `The <strong>"${driver.name}"</strong> Driver<br> will be permanently deleted.`,
        okButton: 'DELETE'
      }
    });

    const canDelete = await dialogRef.afterClosed().toPromise();
    if (!canDelete) {
      return;
    }

    try {
      driver.deleted = true;
      await this.driver.delete(driver.id).toPromise();

      if (this.isDriverList && userId === this.userId) {
        const index = this.stories.findIndex(item => item.id === driver.id);

        if (index > -1) {
          this.stories.splice(index, 1);
        }
      }

      // Timeout in order to unload main thread
      setTimeout(() => {
        this.driverDeleted.emit(driver.id);
      }, 100);

      this.notify.message(`"${driver.name}" Driver was successfuly deleted.`);
    } catch (error) {
      this.notify.error(`Failed to delete "${driver.name}" Driver.`);
    }
  }

  checkScroll(event) {
    const { scrollTop, scrollHeight, offsetHeight } = event.srcElement;

    this.scrollPosition = scrollTop;
    if (!this.isLoading) {
      const scrollValue = (scrollHeight - scrollTop) / offsetHeight;

      if (scrollValue < 1.2) {
        this.loadPage();
      } else if (scrollValue < 2) {
        this.isLoadingProgress = true;
      } else {
        this.isLoadingProgress = false;
      }
    }
  }

  // Banners ---------------

  openBanner(bannerUrl) {
    window.open(bannerUrl, '_blank');
  }

  async editBanner(banner) {
    const dialogRef = this.dialog.open(DialogEditBannerComponent, {
      maxWidth: '600px',
      data: banner
    });

    try {
      const data = await dialogRef.afterClosed().toPromise();

      if (!data) {
        return;
      }

      data.id = banner.id;
      data.type = banner.type;

      if (banner.type === 'CREATOPY') {
        data.previewUrl = banner.previewUrl;
      }

      const { banner: edited } = await this.banner.edit(data).toPromise();
      const { name, hash, previewUrl, owner } = edited;

      banner.name = name;
      banner.hash = hash;
      banner.previewUrl = previewUrl;
      banner.owner = owner;

      this.notify.message(`"${banner.name}" Driver was successfuly edited.`);
    } catch (error) {
      this.notify.error(`Failed to edit "${banner.name}" Banner.`);
    }
  }

  async deleteBanner(banner) {
    const userId = this.userId;
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      autoFocus: false,
      data: {
        title: 'Delete banner?',
        supportingText: `The <strong>"${banner.name}"</strong> Banner<br> will be permanently deleted.`,
        okButton: 'DELETE'
      }
    });

    const canDelete = await dialogRef.afterClosed().toPromise();
    if (!canDelete) {
      return;
    }

    banner.deleted = true;
    try {
      const { banner: deleted } = await this.banner.delete(banner.id).toPromise();

      if (this.isBannerList && userId === this.userId) {
        const index = this.stories.findIndex(item => item.id === deleted.id);

        if (index > -1) {
          this.stories.splice(index, 1);
        }
      }

      this.notify.message(`"${banner.name}" Banner was successfuly deleted.`);
    } catch (error) {
      this.notify.error(`Failed to delete "${banner.name}" Banner.`);
    }
  }

  // -----------------------

  setListState() {
    if (!this.isSearching && !this.isCompanyState) {
      this.userId = this.userState;
    }

    this.stories = [];
    this.nextPage = null;
    this.limitReached = false;

    this.loadPage();
  }

  async getUsers() {
    try {
      const { users } = await this.user.company().toPromise();
      const user = this.auth.getUser();
      const companyUsers = [];
      let currentUser = null;

      for (const rawUser of users) {
        if (user.id === rawUser.id) {
          currentUser = rawUser;
        } else {
          companyUsers.push(rawUser);
        }
      }

      return Promise.resolve({ currentUser, companyUsers });
    } catch (error) {
      this.notify.error('Failed to load company users.');
    }
  }

  async loadPage() {
    if (this.limitReached) {
      return;
    }

    this.isLoading = true;
    try {
      let response = null;

      if (this.isSearching) {
        const currentQuery = this.search.value;

        if (!this.isSearching || currentQuery !== this.search.value) {
          return;
        }

        response = await this.searchStories(currentQuery, this.nextPage);
      } else {
        const userId = this.userId;
        const byCompany = this.isCompanyState;

        if (byCompany && !this.isCompanyState || userId !== this.userId) {
          return;
        }

        response = await this.getStories(userId, byCompany, this.nextPage);
      }

      // Processing stories
      const { stories, nextPage } = response;

      this.isLoading = false;
      this.stories.push(...stories);
      if (nextPage) {
        this.nextPage = nextPage;
      } else {
        this.limitReached = true;
      }

    } catch (error) {
      this.isLoading = false;
      this.notify.error(`Failed to load ${this.isBannerList ? 'Banners' : 'Drivers'}`);
    }
  }

  async searchStories(currentQuery, nextPage) {
    if (this.isDriverList) {
      const { drivers, next } = await this.driver.search(currentQuery, this.isAutomated, nextPage).toPromise();

      return {
        stories: drivers.map(driver => this.formatDriver(driver)),
        nextPage: next
      };
    } else {
      const { banners, next } = await this.banner.search(currentQuery, nextPage).toPromise();

      return {
        stories: banners.map(banner => this.formatBanner(banner)),
        nextPage: next
      };
    }
  }

  async getStories(userId, byCompany, nextPage) {
    if (this.isDriverList) {
      const { drivers, next } = await this.driver.list(userId, byCompany, this.isAutomated, nextPage).toPromise();

      return {
        stories: drivers.map(driver => this.formatDriver(driver)),
        nextPage: next
      };
    } else {
      const { banners, next } = await this.banner.list(userId, byCompany, nextPage).toPromise();

      return {
        stories: banners.map(banner => this.formatBanner(banner)),
        nextPage: next
      };
    }
  }

  formatDriver(driver) {
    driver.url = `${DRIVER_PROD_URL}/${driver.snap_id}`;

    return driver;
  }

  formatBanner(banner) {
    banner.url = `${banner.type === 'CREATOPY' ? BANNER_CREATOPY_PROD_URL : BANNER_PROD_URL}/${banner.hash}`;

    return banner;
  }
}
