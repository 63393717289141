
export enum EditorType {
  HTML = 'HTML',
  GLOBAL_CSS = 'GLOBAL_CSS',
  PANEL_CSS = 'PANEL_CSS'
}

export class Code {
  public html = '';
  public globalCss = '';
  public panelCss = '';

  constructor() { }
}

export class EditorModel {
  public model: import('monaco-editor').editor.ITextModel;
  public state: import('monaco-editor').editor.ICodeEditorViewState;

  constructor() { }
}

export class EditorModelList {
  public html = new EditorModel();
  public globalCss = new EditorModel();
  public panelCss = new EditorModel();

  getModel(type: EditorType): EditorModel {
    switch (type) {
      case EditorType.HTML:
        return this.html;
      case EditorType.GLOBAL_CSS:
        return this.globalCss;
      default:
        return this.panelCss;
    }
  }

  setModelState(type: EditorType, state: import('monaco-editor').editor.ICodeEditorViewState) {
    switch (type) {
      case EditorType.HTML:
        this.html.state = state;
        break;
      case EditorType.GLOBAL_CSS:
        this.html.state = state;
        break;
      default:
        this.html.state = state;
    }
  }
}
