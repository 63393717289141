// dependencies
import 'popper.js/dist/esm/popper.min';
import 'bootstrap/dist/js/bootstrap.min';

import * as angular from 'angular';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { setAngularLib } from '@angular/upgrade/static';
import { AppModule } from './app/app.module';

setAngularLib(angular);
platformBrowserDynamic().bootstrapModule(AppModule);
