import template from './editor-text-toolbar.component.html';
import './editor-text-toolbar.component.css';

import * as roosterjs from 'roosterjs';
// import * as kek from 'roosterjs-editor-api';
import execCommand from 'roosterjs-editor-api/lib/utils/execCommand';
import applyInlineStyle from 'roosterjs-editor-api/lib/utils/applyInlineStyle';
import { Alignment, Indentation } from 'roosterjs-editor-types';

export const EditorTextToolbarComponent = {
	template: template,
	bindings: {
		modelName: '<',
		modelSize: '<',
		previewSize: '<',
		onPreviewModelChanged: '<',
		onPreviewSizeChanged: '<',
		onDeviceOrientationChanged: '<'
	},
	controller: function ($element, $timeout, TextEditorService, $mdDialog) {
		var $ctrl = this;

		$ctrl.$onInit = function () {
			$ctrl.Alignment = Alignment;
			TextEditorService.getToolbarObservable().add($ctrl.updateToolbar);
			TextEditorService.getFontsObservable().add($ctrl.updateFonts);

			$ctrl.initToolbar();
			$ctrl.colorPickerOptions = {
				materialPalette: false,
				hsl: false,
				openOnInput: true,
				history: false
			};

			$ctrl.isEditorDisabled = true;

			// Fonts
			$ctrl.driverFonts = [];
			$ctrl.availableFonts = [
				'Roboto',
				'Nunito Sans Light'
			];

			$ctrl.availableFontsList = {
				'Roboto': { name: 'Roboto', value: 'Roboto, sans-serif' },
				'NunitoSans-Light': { name: 'Nunito Sans Light', value: '"NunitoSans-Light"' }
			};

			// Font Sizes
			$ctrl.fontSizes = [
				'8px',
				'10px',
				'12px',
				'14px',
				'16px',
				'18px',
				'24px',
				'36px',
				'48px',
				'60px',
				'72px',
				'90px',
			];

			// Line spacing
			$ctrl.lineSpacings = ['1.0', '1.5', '2.0', '2.5', '3.0'];
			$ctrl.isSpaceBeforeAdded;
			$ctrl.isSpaceAfterAdded;
		};

		$ctrl.$onChanges = function (changes) {
			var modelSize = changes.modelSize;

			if (modelSize && modelSize.currentValue && !modelSize.isFirstChange()) {
				$ctrl.modelWidth = modelSize.currentValue.width;
				$ctrl.modelHeight = modelSize.currentValue.height;
			}
		};

		$ctrl.$onDestroy = function () {
			TextEditorService.getToolbarObservable().remove($ctrl.updateToolbar);
			TextEditorService.getFontsObservable().remove($ctrl.updateFonts);
		};

		/* Preview */

		$ctrl.changePreviewModel = function () {
			if ($ctrl.onPreviewModelChanged) {
				$ctrl.onPreviewModelChanged($ctrl.modelName);
			}
		};

		$ctrl.changePreviewSize = function () {
			if ($ctrl.onPreviewSizeChanged) {
				$ctrl.onPreviewSizeChanged($ctrl.previewSize);
			}
		};

		$ctrl.changeDeviceOrientation = function () {
			if ($ctrl.onDeviceOrientationChanged) {
				$ctrl.onDeviceOrientationChanged();
			}
		};

		/* Editor Toolbar */

		$ctrl.initToolbar = function () {
			$ctrl.toolbar = {
				fontName: {
					description: 'Font Name',
					value: '',
					changed: false,
					onFocus: function () {
						$ctrl.toolbar.fontName.changed = false;
					},
					onBlur: function () {
						$ctrl.editor.focus();

						if ($ctrl.toolbar.fontName.changed) {
							roosterjs.setFontName($ctrl.editor, $ctrl.toolbar.fontName.value);
							$ctrl.toolbar.fontName.changed = false;
						}
					},
					format: function () {
						$ctrl.toolbar.fontName.changed = true;
					}
				},
				fontSize: {
					description: 'Font Size',
					value: '',
					format: function () {
						var value = $ctrl.getUnitValue($ctrl.toolbar.fontSize.value);

						$ctrl.editor.focus();
						if (value.length > 0) {
							roosterjs.setFontSize($ctrl.editor, value);
						}
					}
				},
				clearFormat: {
					description: 'Clear Format',
					format: function () {
						roosterjs.clearFormat($ctrl.editor);
					}
				},
				bold: {
					description: 'Bold',
					checked: false,
					format: function () {
						roosterjs.toggleBold($ctrl.editor);
					}
				},
				italic: {
					description: 'Italic',
					checked: false,
					format: function () {
						roosterjs.toggleItalic($ctrl.editor);
					}
				},
				underline: {
					description: 'Underline',
					checked: false,
					format: function () {
						roosterjs.toggleUnderline($ctrl.editor);
					}
				},
				strikeThrough: {
					description: 'Strikethrough',
					checked: false,
					format: function () {
						roosterjs.toggleStrikethrough($ctrl.editor);
					}
				},
				textColor: {
					description: 'Text Color',
					value: '',
					onFocus: function () {
						$element.find('.nd-picker--text-color .md-color-picker-preview').click();

						$timeout(function () {
							$('md-dialog.md-color-picker-dialog').parent().click($ctrl.focusOnEditor);
							$('md-dialog.md-color-picker-dialog md-actions button').first().click($ctrl.focusOnEditor);
						});
					},
					format: function () {
						$ctrl.editor.focus();
						roosterjs.setTextColor($ctrl.editor, $ctrl.toolbar.textColor.value);
					}
				},
				highlightColor: {
					description: 'Highlight Color',
					value: '',
					onFocus: function () {
						$element.find('.nd-picker--highlight-color .md-color-picker-preview').click();

						$timeout(function () {
							$('md-dialog.md-color-picker-dialog').parent().click($ctrl.focusOnEditor);
							$('md-dialog.md-color-picker-dialog md-actions button').first().click($ctrl.focusOnEditor);
						});
					},
					format: function () {
						$ctrl.editor.focus();
						roosterjs.setBackgroundColor($ctrl.editor, $ctrl.toolbar.highlightColor.value);
					}
				},
				bulletedList: {
					description: 'Bulleted List',
					checked: false,
					format: function () {
						roosterjs.toggleBullet($ctrl.editor);
					}
				},
				numberedList: {
					description: 'Numbered List',
					checked: false,
					format: function () {
						roosterjs.toggleNumbering($ctrl.editor);
					}
				},
				decreaseIndent: {
					description: 'Decrease Indent',
					checked: false,
					format: function () {
						roosterjs.setIndentation($ctrl.editor, Indentation.Decrease);
					}
				},
				increaseIndent: {
					description: 'Increase Indent',
					checked: false,
					format: function () {
						roosterjs.setIndentation($ctrl.editor, Indentation.Increase);
					}
				},
				alignLeft: {
					description: 'Align Left',
					checked: false,
					format: function () {
						roosterjs.setAlignment($ctrl.editor, Alignment.Left);
					}
				},
				alignCenter: {
					description: 'Align Center',
					checked: false,
					format: function () {
						roosterjs.setAlignment($ctrl.editor, Alignment.Center);
					}
				},
				alignRight: {
					description: 'Align Right',
					checked: false,
					format: function () {
						roosterjs.setAlignment($ctrl.editor, Alignment.Right);
					}
				},
				alignJustify: {
					description: 'Justify',
					checked: false,
					format: function () {
						$ctrl.justifyText($ctrl.editor);
					}
				},
				lineSpacing: {
					description: 'Line spacing',
					format: function (event, lineHeight) {
						applyInlineStyle($ctrl.editor, function (element) {
							element.parentNode.style.lineHeight = lineHeight;
						});

						$ctrl.updateWithUndo(event, $ctrl.editor);
					},
					getCurrentSpacing: function () {
						if ($ctrl.editor) {
							var range = $ctrl.editor.getSelectionRange();

							if (range) {
								// Get properties of selected text.
								var formatState = roosterjs.getFormatState($ctrl.editor);
								var selectionProperties = TextEditorService.composeProperties($ctrl.editor, formatState, range);

								// Convert value with 'px' to number. Example: '21px' => 21.
								var currentLineHeight = Number(selectionProperties.lineHeight.slice(0, -2));
								var currentFontSize = Number(selectionProperties.fontSize.slice(0, -2));

								// Convert line height to number value by dividing lineHeight in 'px' to fontSize in 'px'.
								return String((currentLineHeight / currentFontSize).toFixed(1));
							}
						}
						return;
					},
					_isPaddingExist: function (element, paddingType) {
						var currentPadding = element.style[paddingType];
						return currentPadding === '0px' || currentPadding === '';
					},
					beforeButtonText: function () {
						var parent = TextEditorService.getParentTextNode($ctrl.editor);

						if (parent) {
							$ctrl.isSpaceBeforeAdded = !this._isPaddingExist(parent, 'paddingTop');
							return $ctrl.isSpaceBeforeAdded ? 'Remove space before paragraph' : 'Add space before paragraph';
						}
						return 'Toggle space before paragraph';
					},
					afterButtonText: function () {
						var parent = TextEditorService.getParentTextNode($ctrl.editor);

						if (parent) {
							$ctrl.isSpaceAfterAdded = !this._isPaddingExist(parent, 'paddingBottom');
							return $ctrl.isSpaceAfterAdded ? 'Remove space after paragraph' : 'Add space after paragraph';
						}
						return 'Toggle space after paragraph';
					},
					before: function (event) {
						applyInlineStyle($ctrl.editor, function (element) {
							element.parentNode.style.paddingTop = $ctrl.isSpaceBeforeAdded ? '0px' : '10px';
						});

						$ctrl.updateWithUndo(event, $ctrl.editor);
					},
					after: function (event) {
						applyInlineStyle($ctrl.editor, function (element) {
							element.parentNode.style.paddingBottom = $ctrl.isSpaceAfterAdded ? '0px' : '10px';
						});

						$ctrl.updateWithUndo(event, $ctrl.editor);
					},
					showCustomDialog: function (event) {
						var confirm = $mdDialog.prompt()
							.title('Custom spacing')
							.placeholder('1.0')
							.ariaLabel('Line spacing')
							.initialValue(this.getCurrentSpacing())
							.targetEvent(event)
							.required(true)
							.ok('Apply')
							.cancel('Cancel');

						$mdDialog.show(confirm).then(function (lineHeight) {
							applyInlineStyle($ctrl.editor, function (element) {
								element.parentNode.style.lineHeight = lineHeight;
							});

							$ctrl.updateWithUndo(event, $ctrl.editor);
						}, function () { });
					},
				},
			};
		};

		$ctrl.updateToolbar = function (editor, selectionProperties, isEnabled) {
			$ctrl.editor = editor;

			if (selectionProperties === null) {
				$ctrl.isEditorDisabled = !isEnabled;
				return;
			}

			$timeout(function () {
				$ctrl.toolbar.fontName.value = selectionProperties.fontName;
				$ctrl.toolbar.fontSize.value = selectionProperties.fontSize;

				$ctrl.toolbar.bold.checked = selectionProperties.isBold;
				$ctrl.toolbar.italic.checked = selectionProperties.isItalic;
				$ctrl.toolbar.underline.checked = selectionProperties.isUnderline;
				$ctrl.toolbar.strikeThrough.checked = selectionProperties.isStrikeThrough;

				$ctrl.toolbar.textColor.value = selectionProperties.textColor;
				$ctrl.toolbar.highlightColor.value = selectionProperties.backgroundColor;

				$ctrl.toolbar.bulletedList.checked = selectionProperties.isBullet;
				$ctrl.toolbar.numberedList.checked = selectionProperties.isNumbering;

				$ctrl.toolbar.alignLeft.checked = false;
				$ctrl.toolbar.alignCenter.checked = false;
				$ctrl.toolbar.alignRight.checked = false;
				$ctrl.toolbar.alignJustify.checked = false;
				switch (selectionProperties.textAlign) {
				case 'left':
					$ctrl.toolbar.alignLeft.checked = true;
					break;
				case 'center':
					$ctrl.toolbar.alignCenter.checked = true;
					break;
				case 'right':
					$ctrl.toolbar.alignRight.checked = true;
					break;
				case 'justify':
					$ctrl.toolbar.alignJustify.checked = true;
					break;
				}
			});
		};

		$ctrl.updateFonts = function (fonts) {
			$ctrl.availableFonts = [];

			var key = null;
			var driverKeys = Object.keys(fonts);
			var availableKeys = Object.keys($ctrl.availableFontsList);

			for (var i = 0; i < driverKeys.length; i++) {
				key = driverKeys[i];

				if (!$ctrl.availableFontsList[key]) {
					$ctrl.availableFonts.push({
						name: key,
						value: fonts[key]
					});
				}
			}

			for (i = 0; i < availableKeys.length; i++) {
				key = availableKeys[i];

				$ctrl.availableFonts.push($ctrl.availableFontsList[key]);
			}
		};

		$ctrl.getUnitValue = function (value) {
			var units = TextEditorService.getUnits();
			var formattedValue = value.replace(/\s+/, '').replace(',', '.').toLowerCase();
			var unit = '';

			var matchedUnits = formattedValue.match(/\D+$/i);
			if (matchedUnits === null || matchedUnits.length === 0) {
				unit = 'px';
			} else {
				var valueUnit = matchedUnits[0];

				for (var i = 0; i < units.length; i++) {
					if (valueUnit === units[i]) {
						unit = valueUnit;
						break;
					}
				}
			}

			var valueNumber = parseFloat(formattedValue.replace(/\D+$/i, ''));
			if (unit.length > 0 && !isNaN(valueNumber) && isFinite(valueNumber)) {
				return '' + valueNumber + unit;
			}

			return '';
		};

		$ctrl.justifyText = function (editor) {
			var command = 'justifyFull';
			var align = 'justify';

			editor.addUndoSnapshot(function () {
				execCommand(editor, command);
				editor.queryElements('[align]', 1 /* OnSelection */, function (node) {
					return node.style.textAlign = align;
				});
			}, 'Format' /* Format */);
		};

		$ctrl.focusOnEditor = function (event) {
			if (event.target !== event.currentTarget) {
				return;
			}

			$ctrl.editor.focus();
		};

		// Save changes to html editor with opportunity to use Undo.
		$ctrl.updateWithUndo = function (event, editor) {
			editor.addUndoSnapshot(function () {
				TextEditorService.update(event, editor);
			}, 'Format');
		};
	},
	controllerAs: '$ctrl'
};
