import { Component, OnInit, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'iml-tube-loader',
  template: require('./tube-loader.component.html'),
  styles: [require('./tube-loader.component.a.css')]
})
export class TubeLoaderComponent implements OnInit {
  @HostBinding('style.width.px') get width() { return this.size; }
  @HostBinding('style.height.px') get height() { return this.size; }

  @Input() size = 50;

  constructor() { }

  ngOnInit() { }
}
