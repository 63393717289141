import { Component, OnInit, Input, Output, EventEmitter, ViewChild,
  ElementRef, AfterViewInit, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';

import { CodeEditorService } from './code-editor.service';
import { Code, EditorModelList, EditorType } from './code-editor.model';
import { LazyService } from 'src/app/core/lazy.service';

@Component({
  selector: 'code-editor',
  template: require('./code-editor.component.html'),
  styles: [require('./code-editor.component.a.css')],
})
export class CodeEditorComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  private editor: import('monaco-editor').editor.IStandaloneCodeEditor;
  private monacoModule: typeof import('monaco-editor');
  private domParser: DOMParser;
  private editorValue = new Code();
  private editorModels = new EditorModelList();
  private editorType = EditorType.HTML;

  @Input() html: string;
  @Input() globalCss: string;
  @Input() panelCss: string;
  @Input() uniquePanelAttr: string;

  @Output() codeUpdate = new EventEmitter<Code>();
  @ViewChild('editorCont', { static: true }) editorCont: ElementRef;

  constructor(private code: CodeEditorService, private lazy: LazyService) { }

  ngOnInit() {
    this.domParser = new DOMParser();
  }

  ngOnChanges(changes: SimpleChanges) {
    const html = changes.html;
    const globalCss = changes.globalCss;
    const panelCss = changes.panelCss;

    if (html && html.currentValue !== this.editorValue.html) {
      this.editorValue.html = html.currentValue ? html.currentValue : '';

      if (this.editorModels.html.model) {
        this.editorModels.html.model.setValue(this.editorValue.html);
        this.editorModels.html.state = null;
      }
    }

    if (globalCss && globalCss.currentValue !== this.editorValue.globalCss) {
      this.editorValue.globalCss = globalCss.currentValue ? globalCss.currentValue : '';

      if (this.editorModels.globalCss.model) {
        this.editorModels.globalCss.model.setValue(this.editorValue.globalCss);
        this.editorModels.globalCss.state = null;
      }
    }

    if (panelCss && panelCss.currentValue !== this.editorValue.panelCss) {
      this.editorValue.panelCss = panelCss.currentValue ? panelCss.currentValue : '';

      if (this.editorModels.panelCss.model) {
        this.editorModels.panelCss.model.setValue(this.editorValue.panelCss);
        this.editorModels.panelCss.state = null;
      }
    }
  }

  ngAfterViewInit() {
    this.lazy.waitForEditor().then(monaco => {
      this.monacoModule = monaco;

      this.editorModels.html.model = monaco.editor.createModel(this.editorValue.html, 'html');
      this.editorModels.globalCss.model = monaco.editor.createModel(this.editorValue.globalCss, 'css');
      this.editorModels.panelCss.model = monaco.editor.createModel(this.editorValue.panelCss, 'css');

      this.editor = monaco.editor.create(this.editorCont.nativeElement, {
        model: this.editorModels.html.model,
        theme: 'vs-dark',
        automaticLayout: true
      });
    });
  }

  ngOnDestroy() {
    this.editor.dispose();

    this.editorModels.html.model.dispose();
    this.editorModels.globalCss.model.dispose();
    this.editorModels.panelCss.model.dispose();

    delete this.domParser;
  }

  changeEditorType(type: EditorType) {
    if (!this.editor || type === this.editorType) {
      return;
    }

    const { model, state } = this.editorModels.getModel(type);

    this.editorModels.setModelState(this.editorType, this.editor.saveViewState());
    this.editorType = type;
    this.editor.setModel(model);

    if (state) {
      this.editor.restoreViewState(state);
    }
  }

  updateCode(value: string, type: EditorType) {
    const rootSelectors = ['.nd-desktop', '.nd-tablet', '.nd-landscape'];

    if (type === EditorType.HTML) {
      this.updateHtml(value, rootSelectors);
    } else {
      this.updateCss(value, type === EditorType.PANEL_CSS, rootSelectors);
    }

    this.codeUpdate.emit(this.editorValue);
  }

  updateHtml(html: string, rootSelectors: string[]) {
    const parserHtml = `<div></div>${html}`; // in order to prevent pushing styles/scripts to the head
    const doc = this.domParser.parseFromString(parserHtml, 'text/html');

    // Removes fake div
    doc.body.removeChild(doc.body.childNodes[0]);

    const styleElements = doc.querySelectorAll('style');
    if (styleElements.length > 0) {
      for (let i = 0; i < styleElements.length; i++) {
        styleElements[i].innerHTML = this.code.addPrefixToCSS(styleElements[i], '.nd-panel-container', rootSelectors);
      }

      this.updateEditorValue(doc.body.innerHTML);
    }

    this.editorValue.html = doc.body.innerHTML;
  }

  updateCss(css: string, isLocal: boolean, rootSelectors: string[]) {
    const parserCss = `<style>${css}</style>`;
    const doc = this.domParser.parseFromString(parserCss, 'text/html');
    const cssPrefix = isLocal ? `.nd-panel-container [${this.uniquePanelAttr}]` : '.nd-panel-container';

    const styleElement = doc.querySelector('style');

    if (styleElement) {
      const prefixedCss = this.code.addPrefixToCSS(styleElement, cssPrefix, rootSelectors);

      this.updateEditorValue(prefixedCss);
      this.editorValue[isLocal ? 'panelCss' : 'globalCss'] = prefixedCss;
    }
  }

  updateEditorValue(newValue: string): void {
    const leftScrollPosition = this.editor.getScrollLeft();
    const topScrollPosition = this.editor.getScrollTop();

    // Cleans the Editor
    this.editor.executeEdits('styleIsolator', [
      {
        identifier: 'delete',
        range: new this.monacoModule.Range(1, 1, 10000, 1),
        text: '',
        forceMoveMarkers: true
      } as import('monaco-editor').editor.IIdentifiedSingleEditOperation
    ]);

    // Sets the updated HTML
    this.editor.executeEdits('styleIsolator', [
      {
        identifier: 'insert',
        range: new this.monacoModule.Range(1, 1, 1, 1),
        text: newValue,
        forceMoveMarkers: true
      } as import('monaco-editor').editor.IIdentifiedSingleEditOperation
    ]);

    // Sets the scroll position
    this.editor.setScrollPosition({
      scrollLeft: leftScrollPosition,
      scrollTop: topScrollPosition
    });
  }
}
