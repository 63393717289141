import template from './top-navbar.component.html';
import './top-navbar.component.css';

export const TopNavbarComponent = {
	template: template,
	bindings: {
		showDriverList: '<',
		create: '<',
		driverName: '<',
		hasWarning: '<',
		hasChanges: '<'
	},
	transclude: true,
	controller: function (AuthService, editorDialogsService) {
		var $ctrl = this;

		$ctrl.$onInit = function () {
			$ctrl.user = AuthService.getUser();
		};

		$ctrl.openCreateDialog = function () {
			editorDialogsService.openDialog();
		};

		$ctrl.signout = function () {
			AuthService.signout().then(function () {
				AuthService.go('login');
			});
		};

		$ctrl.hasDriverName = function () {
			return typeof $ctrl.driverName === 'string' && $ctrl.driverName.length > 0;
		};
	},
	controllerAs: '$ctrl'
};
