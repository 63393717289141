import template from './extractor-image.component.html';
import './extractor-image.component.css';

export const ExtractorImageComponent = {
	template: template,
	bindings: {
		url: '<',
		type: '<',
		progress: '<',
		progressValue: '<',
		unoptimized: '<',
		width: '<',
		height: '<'
	},
	transclude: true,
	controller: function ($element) {
		var $ctrl = this;

		$ctrl.$onInit = function () {
			// init
		};

		$ctrl.$onChanges = function (changes) {
			var url = changes.url;
			var width = changes.width;
			var height = changes.height;

			if (url && url.currentValue) {
				$ctrl.preview_url = "url('" + url.currentValue + "'), url(assets/images/transparent.png)";
			}

			if (width || height) {
				$ctrl.setPreviewSize();
			}
		};

		$ctrl.$onDestroy = function () {
			// destroy
		};

		$ctrl.setPreviewSize = function () {
			if ($ctrl.width && $ctrl.height) {
				var container = $element.find('.snap-asset-cont');
				var container_rect = container[0].getBoundingClientRect();

				if ($ctrl.width <= container_rect.width && $ctrl.height <= container_rect.height) {
					$ctrl.preview_size = 'auto';
				} else {
					var ratio = $ctrl.width / $ctrl.height;
					var isContain = ratio > 16 / 9 || ratio < 0.3;

					$ctrl.preview_size = (isContain ? 'contain' : 'cover') + ', 16px 16px';
				}
			} else {
				$ctrl.preview_size = 'cover, 16px 16px';
			}
		};
	},
	controllerAs: '$ctrl'
};
