import { PluginEventType } from 'roosterjs-editor-types';

export default function ToolbarPlugin(editorService) {
	var that = this;

	// Init
	this.editorService = editorService;

	this.getName = function () {
		return 'Toolbar';
	};

	this.initialize = function (editor) {
		that.editor = editor;
	};

	this.dispose = function () {
		that.editor = null;
	};

	this.getEditor = function () {
		return that.editor;
	};

	this.setEditorService = function (editorService) {
		that.editorService = editorService;
	};

	this.onPluginEvent = function (event) {
		if (that.editorService) {
			switch (event.eventType) {
			case PluginEventType.KeyUp:
			case PluginEventType.MouseUp:
			case PluginEventType.ContentChanged:
				that.editorService.update(event, that.editor);
				break;
			case PluginEventType.EditorReady:
				that.editorService.enableToolbar(that.editor);
				break;
			case PluginEventType.BeforeDispose:
				that.editorService.disableToolbar();
				break;
			}
		}
	};
}
