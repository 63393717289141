import angular from 'angular';

import { AssetsManagerComponent } from './assets-manager.component';
import { DriverAssetComponent } from './driver-asset/driver-asset.component';
import { AssetsManagerService } from './assets-manager.service';

export const AssetsManagerModule = angular
	.module('CreateSnapApp.editor.assetsManager', [])
	.component('assetsManager', AssetsManagerComponent)
	.component('driverAsset', DriverAssetComponent)
	.service('AssetsManagerService', AssetsManagerService)
	.name;
