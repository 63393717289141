import query from 'querystring';
import shajs from 'sha.js';

const CLIENT_ID = 'de917983df3457709b4cae883dac64d73a869b69e961a81de4f414e9a90bb292366131cc325cdd2ced16b5169e33dc383d1861ed04ec21f72b525e2d62ea5392';

export function AuthService($q, $http, $location) {
	var that = this;

	this.states = {};
	//#region Token Management logic

	this.setToken = function (token) {
		this.token = Object.assign({}, token);
		this.token.expiresAt = new Date(Date.now() + token.expires_in * 1000);

		this.saveToken();
	};

	this.saveToken = function () {
		this.token.usedAt = new Date();

		var json = JSON.stringify(this.token);

		sessionStorage.setItem('elly-token', json);
	};

	this.getToken = function () {
		// if (this.token) {
		// 	return this.token;
		// }

		var json = sessionStorage.getItem('elly-token');
		if (json !== null && typeof json !== 'undefined') {
			this.token = JSON.parse(json);
			this.token.usedAt = new Date(this.token.usedAt);
			this.token.expiresAt = new Date(this.token.expiresAt);
		}

		return this.token;
	};

	this.removeToken = function () {
		sessionStorage.removeItem('elly-token');

		delete this.token;
		delete this.user;

		return $q.resolve();
	};

	this.setCodeVerifier = function (codeVerifier, state) {
		sessionStorage.setItem('login-code-verifier-' + state, codeVerifier);
	};

	this.getCodeVerifier = function (state) {
		return sessionStorage.getItem('login-code-verifier-' + state);
	};

	this.authorize = function () {
		var codeVerifier = Date.now().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
		var codeChallenge = shajs('sha256').update(codeVerifier).digest('HEX');
		var challengeMethod = 'S256';
		var redirectUri = window.location.href;
		var state = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

		this.setCodeVerifier(codeVerifier, state);

		var data = {
			client_id: CLIENT_ID,
			code_challenge: codeChallenge,
			challenge_method: challengeMethod,
			redirect_uri: redirectUri,
			state: state
		};

		window.location.assign(constants.AUTH_PATH + '/oauth/authorize?' + query.stringify(data));
	};

	this.requestToken = function (code, codeVerifier) {
		var headers = {
			'Content-Type': 'application/json'
		};

		var data = {
			client_id: CLIENT_ID,
			code_verifier: codeVerifier,
			code: code
		};

		return $http.post(constants.AUTH_PATH + '/oauth/token', data, { headers: headers });
	};

	this.revokeToken = function () {
		var access_token = this.getAccessToken();

		if (access_token && !this.hasTokenExpired()) {
			var headers = {
				'Content-Type': 'application/json'
			};

			return $http.post(constants.AUTH_PATH + '/oauth/token/revoke', {}, { headers: headers })
				.then(function () {
					return that.removeToken();
				})
				.catch(function () {
					return that.removeToken();
				});
		} else {
			return this.removeToken();
		}
	};

	//#endregion

	this.setState = function (state, login) {
		this.states[login ? 'login' : 'redirect'] = state;
	};

	this.go = function (state) {
		if (state === 'editor') {
			$location.search('');
		}

		$location.path(state);
	};

	this.login = function (username, password) {
		return this.requestToken(username, password)
			.then(function (response) {
				that.token = response.data.token;

				var user = that.token.user;
				if (user) {
					user.logo_url = user.logo_url ? user.logo_url : '/assets/images/nd_no_logo.png';
					user.logo_url = "url('" + user.logo_url + "')";
				}
				that.setToken(that.token);

				return that.token;
			});
	};

	this.signout = function () {
		return this.revokeToken();
	};

	this.isLoggedIn = function () {
		return !this.hasTokenExpired();
	};

	this.setUser = function (user) {
		var json = JSON.stringify(user);
		this.token = user;

		sessionStorage.setItem('elly-user', json);
	};

	this.requestUserInfo = function () {
		return $http.get(constants.AUTH_PATH + '/api/userinfo');
	};

	this.getUser = function () {
		var json = sessionStorage.getItem('elly-user');

		if (json !== null && typeof json !== 'undefined') {
			this.user = JSON.parse(json);
		}

		return this.user;
	};

	this.getCompanyUsers = function () {
		return $http.get(constants.API_PATH + '/api/company/users');
	};

	this.getAccessToken = function () {
		var token = this.getToken();
		var accessToken = null;

		if (token) {
			accessToken = token.access_token;
		}

		return accessToken;
	};

	this.hasTokenExpired = function () {
		var token = this.getToken();

		if (token) {
			return token.expiresAt.getTime() < Date.now();
		} else {
			return true;
		}
	};
}
