import template from './editor-driver-list.component.html';
import './editor-driver-list.component.css';

export const EditorDriverListComponent = {
	template: template,
	bindings: {
		hideCloseButton: '<',
		onSelect: '<',
		onAfterDelete: '<',
		onAfterClose: '<'
	},
	controller: function ($mdDialog, $element, $timeout, NotifyService, DriverService, AuthService) {
		var $ctrl = this;

		this.$onInit = function () {
			$ctrl.user = AuthService.getUser();

			$ctrl.driverList = {};
			$ctrl.allDrivers = false;
			$ctrl.currentUserIndex = 0;
			$ctrl.userList = {};
			$ctrl.userList[$ctrl.user.id] = $ctrl.user;
			$ctrl.copyingDrivers = [];
			$ctrl.prodUrl = constants.SNAP_PROD_URL;

			$ctrl.getUsers().then(function () {
				// Some logic here after
			});

			// Debounce for the query
			$ctrl.isSearching = false;
			$ctrl.searchOptions = {
				debounce: 1000
			};

			$timeout(function () {
				$element.children('.nd-driver-list-cont').removeClass('nd-list-hidden');
			}, 500);
		};

		this.searchDrivers = function () {
			$ctrl.isSearching = true;
			$ctrl.currentUserId = 'search';
			$ctrl.driversState = 'search';
			$ctrl.previewUsers = [
				{
					id: 'search',
					firstName: 'Search',
					lastName: 'Results'
				}
			];

			$ctrl.allDrivers = false;
			$ctrl.limitReached = false;
			$ctrl.driverList = {};
			$ctrl.driverList[$ctrl.currentUserId] = {
				drivers: []
			};

			if ($ctrl.searchQuery.length > 0) {
				$ctrl.loadDriversPage();
			} else {
				$ctrl.limitReached = true;
			}
		};

		this.selectDriver = function (driver) {
			if ($ctrl.onSelect) {
				$ctrl.onSelect(driver);
				$ctrl.closeDialog();
			}
		};

		this.deleteDriver = function (event, driver, userId) {
			var remove = $mdDialog.confirm()
				.title('Delete')
				.textContent('Are you sure you want to delete "' + driver.name + '" Driver?')
				.ariaLabel('Delete driver')
				.targetEvent(event)
				.ok('Delete')
				.cancel('Cancel');

			$mdDialog.show(remove).then(function () {
				driver.inProgress = true;
				DriverService.delete(driver.id).then(function (response) {
					if (response.status === 204) {
						throw new Error("Wasn't able to delete the Driver.");
					}

					var index = -1;
					var drivers = $ctrl.driverList[userId].drivers;
					for (var i = 0; i < drivers.length; i++) {
						if (driver.id === drivers[i].id) {
							index = i;
							break;
						}
					}

					if (index > -1) {
						$ctrl.driverList[userId].drivers.splice(index, 1);
					}

					driver.inProgress = false;
					if ($ctrl.onAfterDelete) {
						$ctrl.onAfterDelete(driver);
					}

					NotifyService.notify('"' + driver.name + '" Driver was successfuly deleted.');
				}).catch(function () {
					driver.inProgress = false;

					NotifyService.notify("Wasn't able to delete the Driver.", true);
				});
			}).catch(function () { });
		};

		this.setDriversState = function () {
			$ctrl.driverList = {};
			$ctrl.limitReached = false;

			if ($ctrl.isSearching) {
				$ctrl.isSearching = false;
				$ctrl.searchQuery = '';
			}

			if ($ctrl.driversState === 'company') {
				$ctrl.allDrivers = true;
				$ctrl.currentUserIndex = 0;

				$ctrl.currentUserId = $ctrl.users[$ctrl.currentUserIndex].id;
				$ctrl.previewUsers = [$ctrl.users[$ctrl.currentUserIndex]];
			} else {
				$ctrl.allDrivers = false;
				$ctrl.currentUserId = $ctrl.driversState;

				var user = $ctrl.userList[$ctrl.currentUserId];
				$ctrl.previewUsers = [user];
			}

			var currentUserId = $ctrl.currentUserId;
			if (currentUserId == $ctrl.user.id) {
				$ctrl.driverList[currentUserId] = {
					drivers: [],
					next: null
				};

				for (var i = 0; i < $ctrl.copyingDrivers.length; i++) {
					$ctrl.driverList[currentUserId].drivers.unshift($ctrl.copyingDrivers[i]);
				}
			}

			$ctrl.loadDriversPage();
		};

		this.hasNoPreview = function (driver) {
			return typeof driver.preview_url !== 'string' || driver.preview_url.length === 0;
		};

		this.getPreviewUrl = function (preview_url) {
			if (!$ctrl.hasNoPreview({ preview_url: preview_url })) {
				return "url('https://" + preview_url + "')";
			} else {
				return 'none';
			}
		};

		this.getUsers = function () {
			return AuthService.getCompanyUsers().then(function (response) {
				var users = response.data.users;

				$ctrl.users = [];
				$ctrl.otherUsers = [];
				for (var i = 0; i < users.length; i++) {
					if (users[i].id === $ctrl.user.id) {
						$ctrl.user = users[i];
						$ctrl.users.unshift(users[i]);
					} else {
						$ctrl.otherUsers.push(users[i]);
						$ctrl.users.push(users[i]);
					}

					$ctrl.userList[users[i].id] = users[i];
				}
			});
		};

		this.copyDriver = function (driver) {
			var currentUserId = $ctrl.currentUserId;
			var driverCopy = Object.assign({}, driver);

			driverCopy.updatedAt = new Date();
			driverCopy.inProgress = true;
			if (currentUserId == $ctrl.user.id && $ctrl.driverList[currentUserId]) {
				$ctrl.driverList[currentUserId].drivers.unshift(driverCopy);
			}

			$ctrl.copyingDrivers.push(driverCopy);
			NotifyService.notify('Copying "' + driverCopy.name + '" Driver...');

			DriverService.copy(driver.id)
				.then(function (response) {
					var oldName = driverCopy.name;
					var newDriver = response.data.driver;
					var driverIndex = $ctrl.findDriverIndex($ctrl.copyingDrivers, driverCopy.id);

					if (driverIndex > -1) {
						$ctrl.copyingDrivers.splice(driverIndex, 1);
					}

					if ($ctrl.currentUserId == $ctrl.user.id) {
						driverCopy.id = newDriver.id;
						driverCopy.name = newDriver.name;
						driverCopy.snap_id = newDriver.snap_id;
						driverCopy.updatedAt = newDriver.updatedAt;
						driverCopy.inProgress = false;
					}

					NotifyService.notify('"' + oldName + '" Driver was successfuly copied.');
				})
				.catch(function () {
					var oldName = driverCopy.name;
					var driverIndex = -1;

					if ($ctrl.currentUserId == $ctrl.user.id && $ctrl.driverList[currentUserId]) {
						driverIndex = $ctrl.findDriverIndex($ctrl.driverList[currentUserId].drivers, driverCopy.id);

						if (driverIndex > -1) {
							$ctrl.driverList[$ctrl.currentUserId].drivers.splice(driverIndex, 1);
						}
					}

					driverIndex = $ctrl.findDriverIndex($ctrl.copyingDrivers, driverCopy.id);
					if (driverIndex > -1) {
						$ctrl.copyingDrivers.splice(driverIndex, 1);
					}

					NotifyService.notify('Wasn\'t able to copy "' + oldName + '" Driver.', true);
				});
		};

		this.findDriverIndex = function (drivers, driverId) {
			var index = -1;

			for (var i = 0; i < drivers.length; i++) {
				if (driverId === drivers[i].id) {
					index = i;
					break;
				}
			}

			return index;
		};

		this.loadDriversPage = function () {
			if (!$ctrl.driverList[$ctrl.currentUserId]) {
				$ctrl.driverList[$ctrl.currentUserId] = {
					drivers: [],
					next: null
				};
			}

			// Next Page
			var currentUserId = $ctrl.currentUserId;
			var next = $ctrl.driverList[currentUserId].next;

			if ($ctrl.isSearching) {
				var currentQuery = $ctrl.searchQuery;

				if (currentQuery.length > 0) {
					$ctrl.loadingInProgress = true;
					$ctrl.driverList[currentUserId].inProgress = true;

					DriverService.search($ctrl.searchQuery, next).then(function (response) {
						var drivers = response.data.drivers;
						var nextPage = response.data.next;

						if (currentQuery !== $ctrl.searchQuery || currentUserId !== $ctrl.currentUserId) {
							return;
						}

						$ctrl.loadingInProgress = false;
						$ctrl.driverList[currentUserId].inProgress = false;
						for (var i = 0; i < drivers.length; i++) {
							$ctrl.driverList[currentUserId].drivers.push(drivers[i]);
						}

						if (nextPage) {
							$ctrl.driverList[currentUserId].next = nextPage;
						} else {
							delete $ctrl.driverList[currentUserId].next;

							$ctrl.limitReached = true;
						}
					}).catch(function (error) {
						$ctrl.loadingInProgress = false;
						$ctrl.driverList[currentUserId].inProgress = false;

						NotifyService.notify(error.message, true);
					});
				}
			} else {
				$ctrl.loadingInProgress = true;
				$ctrl.driverList[$ctrl.currentUserId].inProgress = true;

				DriverService.list($ctrl.currentUserId, next).then(function (response) {
					var drivers = response.data.drivers;
					var nextPage = response.data.next;

					if (currentUserId !== $ctrl.currentUserId) {
						return;
					}

					$ctrl.loadingInProgress = false;
					$ctrl.driverList[currentUserId].inProgress = false;
					for (var i = 0; i < drivers.length; i++) {
						$ctrl.driverList[currentUserId].drivers.push(drivers[i]);
					}

					if (nextPage) {
						$ctrl.driverList[currentUserId].next = nextPage;
					} else {
						delete $ctrl.driverList[currentUserId].next;

						if ($ctrl.allDrivers && $ctrl.currentUserIndex < $ctrl.users.length - 1) {
							$ctrl.currentUserIndex++;

							$ctrl.currentUserId = $ctrl.users[$ctrl.currentUserIndex].id;
							$ctrl.previewUsers.push($ctrl.users[$ctrl.currentUserIndex]);
						} else {
							$ctrl.limitReached = true;
						}
					}
				}).catch(function (error) {
					$ctrl.loadingInProgress = false;
					$ctrl.driverList[currentUserId].inProgress = false;

					NotifyService.notify(error.message, true);
				});
			}
		};

		this.closeDialog = function () {
			$element.children('.nd-driver-list-cont').addClass('nd-list-hidden');

			$timeout(function () {
				if ($ctrl.onAfterClose) {
					$ctrl.onAfterClose();
				}
			}, 200);
		};
	},
	controllerAs: '$ctrl'
};
