import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, empty, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from 'src/app/services/auth.service';
import { API_PATH, AUTH_PATH } from 'src/js/constants';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.auth.getAccessToken();

    const authRequired = req.url.search(API_PATH) > -1
      || req.url.search(AUTH_PATH + '/api/userinfo') > -1
      || req.url.search(AUTH_PATH + '/oauth/token/revoke') > -1;

    if (authRequired) {
      if (accessToken && !this.auth.hasTokenExpired()) {
        const authReq = req.clone({
          setHeaders: {
            'Authorization': `Bearer ${accessToken}`
          }
        });

        return next.handle(authReq).pipe(
          catchError(err => {
            if (err instanceof HttpErrorResponse && err.status === 401) {
              return this.handleUnauthorized();
            } else {
              return throwError(err);
            }
          })
        );
      } else {
        return this.handleUnauthorized();
      }
    } else {
      return next.handle(req);
    }
  }

  handleUnauthorized() {
    this.auth.signout()
      .then(() => this.auth.go('login'))
      .catch(() => {});

    return empty();
  }
}
