export function DebouncerService($q, $timeout) {
	this.create = function () {
		var debounceTimeout = null;

		return {
			debounce: function (debounceTime) {
				var isImmediate = debounceTime === null || typeof debounceTime === 'undefined' || debounceTime === 0;
				var deferred = $q.defer();

				$timeout.cancel(debounceTimeout);

				if (isImmediate) {
					deferred.resolve();
				} else {
					debounceTimeout = $timeout(deferred.resolve, debounceTime);
				}

				return deferred.promise;
			}
		};
	};
}
